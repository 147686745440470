import React, { createRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const CropImage = ({ beforeCrop, handleCloseModal, setState, imageUploader }) => {
    const cropperRef = createRef();
    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            const canvasData = cropperRef.current?.cropper.getCroppedCanvas().toDataURL("image/jpeg", 1.0);
            const byteString = atob(canvasData.split(',')[1]);
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([ab], { type: 'image/jpeg' });
            const file = new File([blob], `cropped-image-${imageUploader.length + 1}.jpeg`, { type: 'image/jpeg' });
            setState((preState) => ({
                ...preState,
                imageUploader: [...preState.imageUploader, file],
            }));
        }
        handleCloseModal();
    };

    return (

        <div
            className="w-full h-full z-[2] absolute top-0 left-0 flex justify-center items-center "
            style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
        >
            <div className="flex flex-col  w-[70%] h-[70%] justify-center items-center rounded">
                <div className="relative w-[70%] h-[70%] rounded px-8 p-10 min-h-[200px]">
                    <Cropper
                        style={{ height: 400, maxWidth: "100%" }}
                        ref={cropperRef}
                        dragMode={"move"}
                        src={beforeCrop}
                        restore={false}
                        guides={false}
                        center={true}
                        highlight={false}
                        cropBoxMovable={true}
                        cropBoxResizable={true}
                        toggleDragModeOnDblclick={false}
                        aspectRatio={4 / 2}
                        autoCropArea={0.65}
                        zoomable={true}
                    />
                </div>

                <div className="py-2 flex gap-3 justify-end mt-10">
                    <button
                        className="border-[1px] border-[#ff0000] px-4 py-1 text-[#ff0000]bg-[#ff0000] text-white"
                        onClick={handleCloseModal}
                    >
                        Cancel
                    </button>
                    <button
                        className="border-[1px] border-[#42C3AA] px-4 py-1 bg-[#42C3AA] text-white"
                        onClick={getCropData}>Crop Image</button>
                </div>
            </div>
        </div>
    );
};

export default CropImage;
