import { apiProductlist } from "api/event";
import { getCookie, kickUser } from "helper/cookie";
import React, { useEffect, useState } from "react";
import { Header } from "./header";
import Skeleton from "./skeleton";
import { useNavigate } from "react-router-dom";
import SubscriptionCard from "./subscriptionCard";
import ProductForm from "./productForm";
import Empty from "./empty";
// import Pagination from './pagination';

function AdminProducts({ handleSearchshow }) {
  const [show] = useState(false);
  // const [pagination, setPagination] = useState({ per_page: 16, page: 1 });
  const [loading, setLoading] = useState(true);
  const [Subscribe, setSubscribe] = useState([]);
  const [visible, setVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  let navigate = useNavigate();
  const [Admin, setAdmin] = useState({
    name: "",
    count_limit: "",
    participant_limit: "",
    media: true,
    duration: "",
    price: "",
    enable_teams: true,
    allow_team_create: true,
    id: "",
  });

  useEffect(() => {
    const roles = JSON.parse(window.localStorage.getItem("roles"));
    handleSearchshow(show);
    setLoading(false);
    setEditVisible(roles?.["admins/v1/subscriptions"]?.includes("update"));
    fetchProduct();
  }, []);

  const fetchProduct = () => {
    apiProductlist(getCookie("auth_token"))
      .then(({ data }) => {
        setSubscribe(data);
      })
      .catch((err) => {
        if (err[0] === 403 || err[0] === 401) {
          navigate("/admin/login");
          kickUser();
        }
      });
  };

  const handleProduct = (value) => {
    setVisible(true);
    setAdmin((prevState) => ({
      ...prevState,
      id: value?.id,
      name: value?.name,
      price: value?.price,
      count_limit: value?.count_limit,
      participant_limit: value?.participant_limit,
      media: value?.media,
      enable_teams: value?.enable_teams,
      duration: value?.duration,
      allow_team_create: value?.allow_team_create,
    }));
  };
  const handleExit = () => {
    setVisible(false);
  };

  // const handlePagination = (page) => {
  //   setPagination((preState) => ({
  //     ...preState,
  //     page: page,
  //   }));
  // };

  const handleSubscriptionchange = (e) => {
    const { name, value } = e.target;
    setAdmin((prevState) => ({ ...prevState, [name]: value }));
  };
  if (loading) {
    const row = [];
    for (let i = 0; i < 8; i++) {
      row.push(
        <div key={i} className="flex-[0_0_25%]">
          <Skeleton />
        </div>
      );
    }
    return <div className="flex flex-wrap py-10">{row}</div>;
  }

  const productProps = {
    title: "Product Edit",
    handleExit,
    handleSubscriptionchange,
    Admin,
    fetchProduct,
  };
  return (
    <div>
      <Header title={"Products"} />
      <div className="flex flex-wrap gap-7 justify-start relative">
        {Subscribe.length != 0 ? (
          Subscribe.map((value, index) => (
            <div key={index}>
              <SubscriptionCard
                onclick={() => handleProduct(value)}
                name={value?.name}
                price={value?.price}
                count_limit={value?.count_limit}
                participant_limit={value?.participant_limit}
                duration={value?.duration}
                media={value?.media}
                enable_teams={value?.enable_teams}
                allow_team_create={value?.allow_team_create}
                editVisible={editVisible}
              />
            </div>
          ))
        ) : (
          <Empty no_data />
        )}
      </div>
      {visible && <ProductForm {...productProps} />}
      {/* {total_entries != 0 && (
          <Pagination
            per_page={pagination?.per_page}
            total_entries={total_entries}
            handlePagination={handlePagination}
          />
        )} */}
    </div>
  );
}
export default AdminProducts;
