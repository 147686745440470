import React from "react";
import male from "assets/media/male.png";

export const Headers = [
  {
    participants: "Participants",
    phonenumber: "Phone Number",
    email: "Email",
    Joindate: "Join Date",
    Stepscount: "Steps Count",
    lastsync: "Last Sync",
    teamname: "Team Name",
    adminName: "Name",
    adminEmail: "Administrator Email",
    adminStatus: "Status",
    adminDate: "Login Date",
    adminRole: "Role",
    companyName: "Company Name",
    companyEmail: "Administrator Email",
    companyProduct: "Subscription",
    companyEdit: "Edit",
    companyStatus: "Status",
    status: "Status",
    manage: "Manage",
    content: [
      {
        img: male,
        name: "John wick",
        number: "9878776655",
        date: "22/07/2022",
        count: "1000 steps",
        syncdate: "27/07/2022",
        team: "Wick",
      },
      {
        img: male,
        name: "John doe",
        number: "9878776655",
        date: "20/07/2022",
        count: "1100 steps",
        syncdate: "25/07/2022",
        team: "doe",
      },
      {
        img: male,
        name: "John Dice",
        number: "9878799655",
        date: "12/07/2022",
        count: "1000 steps",
        syncdate: "20/07/2022",
        team: "Dice",
      },
      {
        img: male,
        name: "John shelter",
        number: "9878675655",
        date: "20/07/2022",
        count: "1090 steps",
        syncdate: "25/07/2022",
        team: "Shelter",
      },
      {
        img: male,
        name: "Thomas shelby",
        number: "9878006655",
        date: "20/07/2022",
        count: "1200 steps",
        syncdate: "22/07/2022",
        team: "Shelby",
      },
    ],
  },
];

export const Button = ({ btn, btnName, onclick, view }) => {
  return (
    <div>
      <button
        className={
          !btn
            ? `border  border-[#42C3AA] border-solid text-[#42C3AA]
                hover:bg-[#42C3AA] hover:text-white transition duration-200  
                 rounded-lg text-md cursor-pointer p-2 `
            : `bg-[#42C3AA] font-mono text-slate-50  pl-2 pr-2 ${
                view ? "p-1 w-32 text-md" : "p-1 text-sm"
              }  rounded-md cursor-pointer`
        }
        onClick={onclick}
      >
        {btn}
        {btnName}{" "}
      </button>
    </div>
  );
};

export const Sports = [
  {
    participants: "Teams",
    number: "No.of.Participants",
    status: "Status",
    participantCount: "Participants Count",
    manage: "Manage/Edit",
    contents: [
      {
        img: male,
        Participantname: "Jack",
        participantnumber: 15,
        view: <Button btn={"view"} />,
      },
      {
        img: male,
        Participantname: "John",
        participantnumber: 25,
        view: <Button btn={"view"} />,
      },
      {
        img: male,
        Participantname: "Jackline",
        participantnumber: 85,
        view: <Button btn={"view"} />,
      },
      {
        img: male,
        Participantname: "Zach galifan",
        participantnumber: 19,
        view: <Button btn={"view"} />,
      },
      {
        img: male,
        Participantname: "will smith",
        participantnumber: 95,
        view: <Button btn={"view"} />,
      },
      {
        img: male,
        Participantname: "walter",
        participantnumber: 15,
        view: <Button btn={"view"} />,
      },
    ],
  },
];

export const teamView = [
  {
    img: male,
    teamName: "john",
    participantName: ["john", "doe", "jack", "sparrow", "lincohn", "Abraham"],
  },
];
