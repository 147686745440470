import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "component/card";
import { Header } from "component/header";
import Pagination from "component/pagination";
import { apiEventList } from "api/event";
import { getCookie, kickUser, setCookie } from "helper/cookie";
import Skeleton from "component/skeleton";
import Empty from "component/empty";

function EventList({ handleSearchshow, searchName }) {
  const [data, setData] = useState([]);
  const [show] = useState(true);
  const [pagination, setPagination] = useState({
    per_page: 16,
    page: 1,
    query: "",
  });
  const [loading, setLoading] = useState(true);
  const [total_entries, setTotal] = useState("");
  let navigate = useNavigate();
  useEffect(() => {
    handleSearchshow(show);
    apiEventList(getCookie("auth_token"), pagination)
      .then(({ total_entries, data }) => {
        setData(data);
        setLoading(false);
        pagination.query === "" && setTotal(total_entries);
      })
      .catch((err) => {
        if (err[0] === 403 || err[0] === 401) {
          kickUser();
          navigate("/organizers/login");
        }
      });
  }, [pagination]);

  useEffect(() => {
    handleSearch({ target: { value: searchName } });
    return () => {
      handleSearch({ target: { value: "" } });
    };
  }, [searchName]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setPagination((preState) => ({ ...preState, query: value }));
  };

  const handleNav = (url, id, data, participants, title) => {
    setCookie("eventName", title);
    navigate(
      url === "new"
        ? `/organizers/events/new`
        : url === "event"
        ? `/organizers/events/${id}/controls`
        : `/organizers/events/teams/${id}`,
      {
        state: {
          value: data,
          max_participant: participants,
          Loading: loading,
          title: title,
        },
      }
    );
  };
  if (loading) {
    const row = [];
    for (let i = 0; i < 8; i++) {
      row.push(
        <div key={i} className="flex-[0_0_25%]">
          <Skeleton edit={true} />
        </div>
      );
    }
    return <div className="flex flex-wrap py-10">{row}</div>;
  }

  const headerProps = {
    title: "List of Events",
    eventCreate: true,
    handleNav,
    total_entries,
  };

  const handlePagination = (page) => {
    setPagination((preState) => ({
      ...preState,
      page: page,
    }));
  };
  return (
    <div>
      <Header {...headerProps} />
      <div className="flex flex-col justify-between min-h-[75vh]">
        <div className="flex flex-wrap gap-10 py-5">
          {data.length !== 0 ? (
            data.map((value, index) => {
              return (
                <div key={index}>
                  <Card {...value} handleNav={handleNav} />
                </div>
              );
            })
          ) : (
            <Empty no_data />
          )}
        </div>
        <div className="flex justify-end">
          {total_entries != 0 && (
            <Pagination
              per_page={pagination?.per_page}
              total_entries={total_entries}
              handlePagination={handlePagination}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default EventList;
