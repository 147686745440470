import { welcome } from "helper";
import React, { useEffect, useState } from "react";
import { FaPowerOff, FaSearch } from "react-icons/fa";
import { getCookie, kickUser } from "helper/cookie";
import { filteredMenu } from "./menu";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { CgProfile } from "react-icons/cg";

function MainLayout(props) {
  const [cookie, setCookies] = useState();
  const [menuLevel, setMenuLevel] = useState(1);
  const [showSearch, setShowSearch] = useState(false);
  const [eventName, setEventName] = useState(false);
  const [SearchValue, setSearchValue] = useState("");
  const [filterMenu, setFilterMenu] = useState([]);
  const navigate = useNavigate();
  let pathUrl = window.location.pathname;
  const [eventId, setEventid] = useState("");
  console.log(eventId);
  const { children } = props;
  const param = useParams();

  useEffect(() => {
    setCookies(getCookie("name"));
    const filtered = async () => {
      const menu = await filteredMenu();
      const path = window.location.pathname;
      let admin = menu?.map((route) => {
        return {
          ...route,
          url: route?.url.replace(":id", param?.id),
        };
      });
      admin = admin?.map((route) => {
        return {
          ...route,
          url: route?.url.replace(":eventid", param?.eventid),
        };
      });
      setFilterMenu(admin);
      const current_level = admin.filter((value) => value.url === path)?.[0]
        ?.level
        ? admin.filter((value) => value.url === path)?.[0]?.level
        : admin.filter((value) => value.url === path)?.[0]?.menu_level;
      setMenuLevel(current_level || 1);
    };
    filtered();
  }, [window.location.pathname]);

  const handleChange = (callBack) => {
    handleChildsearch = callBack;
  };
  const handleSearchvalue = (e) => {
    setSearchValue(e.target.value);
  };

  const textColor =
    filterMenu[0]?.url.split("/")[1] === "admin" ? "#fff" : "#00CBB5";
  const logoColor =
    filterMenu[0]?.url.split("/")[1] === "admin" ? "#A020F0" : "#fff";
  const menuColor =
    filterMenu[0]?.url.split("/")[1] === "admin" ? "#000" : "#fff";
  const activeMenuColor =
    filterMenu[0]?.url.split("/")[1] === "admin" ? "#A020F0" : "#00CBB5";
  // const contentColor =
  //   filterMenu[0]?.url.split("/")[1] === "admin" ? "#f4f2f7" : "#fff";
  const activeBgColor =
    filterMenu[0]?.url.split("/")[1] === "admin" ? "bg-[#EFEBF6]" : "bg-[#fff]";

  const handleSearchshow = (show, eventId, eventName) => {
    setShowSearch(show);
    setEventid(eventId);
    setEventName(eventName);
  };

  const greeting = welcome();

  const childrenWithProps = {
    ...children,
    props: { handleChange, handleSearchshow, searchName: SearchValue },
  };
  const handleBack = () => {
    setMenuLevel(1);
    navigate({
      pathname: "/admin/companies",
    });
  };
  return (
    <div>
      <div className="bg-[#f4f2f7] h-screen font-bold font-['Nunito_Sans']">
        <div className="flex">
          <div className="w-[16%] sm:h-[90vh] 2xl:h-[92vh]">
            <div
              className={`h-[60px]
              m-1 rounded flex justify-center items-center shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]`}
              style={{ backgroundColor: textColor }}
            >
              <h1 className="uppercase text-2xl" style={{ color: logoColor }}>
                Jicando
              </h1>
            </div>
            <div
              className={`h-full m-1 rounded`}
              style={{ backgroundColor: textColor }}
            >
              <div className="py-6 flex flex-col gap-1">
                {menuLevel === 2 && (
                  <div className="px-3 pb-5">
                    <button
                      onClick={handleBack}
                      className="border  border-[#A020F0] border-solid text-lg text-[#A020F0] font-medium flex justify-center items-center bg-transparent w-full rounded-lg"
                    >
                      <span className="px-1">
                        <IoMdArrowRoundBack className="h-10 w-6" />
                      </span>
                      Back
                    </button>
                  </div>
                )}
                {filterMenu &&
                  filterMenu.map((value, index) => {
                    let Icon = value?.icon;
                    if (value?.level === menuLevel) {
                      return (
                        <div key={index}>
                          <NavLink to={value?.url}>
                            <div
                              className={`${
                                (pathUrl === value?.url ||
                                  pathUrl.split("/")[2] === value.match_url ||
                                  pathUrl.split("/")[4] === value.match_url) &&
                                `rounded-[10px] ${activeBgColor}`
                              } flex items-center gap-4 mx-[5px] py-2 px-4`}
                              style={{ color: menuColor }}
                            >
                              <Icon
                                color={
                                  pathUrl === value?.url ||
                                  pathUrl.split("/")[2] === value.match_url ||
                                  pathUrl.split("/")[4] === value.match_url
                                    ? activeMenuColor
                                    : menuColor
                                }
                                className="text-[25px]"
                              />
                              <p
                                style={{
                                  color:
                                    (pathUrl === value?.url ||
                                      pathUrl.split("/")[2] ===
                                        value.match_url ||
                                      pathUrl.split("/")[4] ===
                                        value.match_url) &&
                                    activeMenuColor,
                                }}
                                className="text-center text-lg font-medium"
                              >
                                {value?.name}
                              </p>
                            </div>
                          </NavLink>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="h-[60px] bg-white m-1 rounded flex items-center">
              <div className="flex-[2_0_0%] flex items-center m-6 rounded border-r-2 gap-3">
                {showSearch && (
                  <>
                    <FaSearch />
                    <input
                      placeholder="Search..."
                      className="outline-none w-full h-full"
                      onChange={handleSearchvalue}
                    />
                  </>
                )}
                {eventName && (
                  <div className="text-[23px]">
                    {/* <span>Event Name :</span> */}
                    {getCookie("eventName")}
                  </div>
                )}
              </div>
              <div className="flex items-center gap-4 px-4">
                <h1>
                  {greeting + " "} {cookie != "null" ? cookie : " "}
                </h1>
                <FaPowerOff
                  className="text-rose-500 cursor-pointer"
                  onClick={() => kickUser()}
                />
              </div>
              <div className="flex items-center gap-4 px-4">
                <h1>Profile</h1>
                <CgProfile
                  className={`${
                    filterMenu[0]?.url.split("/")[1] === "organizers"
                      ? "text-[#00CBB5]"
                      : "text-[#A020F0]"
                  }  font-bold cursor-pointer text-lg`}
                  onClick={() => {
                    filterMenu[0]?.url.split("/")[1] === "organizers"
                      ? navigate("/organizers/profile")
                      : navigate("/admin/profile");
                  }}
                />
              </div>
            </div>
            <div
              className={`sm:h-[90vh] 2xl:h-[92vh]  m-1 rounded p-8 overflow-scroll`}
            >
              {childrenWithProps}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MainLayout;
