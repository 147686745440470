import makeRequest from "./index";

export const apiEventList = (token, pagination) => {
  return makeRequest({
    uri: `/organizers/v1/events?page=${pagination.page}&per_page=${pagination.per_page}&query=${pagination.query}`,
    authorization: token,
  });
};

export const apiEventCreate = (token, requestPara) => {
  return makeRequest({
    uri: "/organizers/v1/events",
    method: "POST",
    authorization: token,
    body: JSON.stringify(requestPara),
  });
};

export const apiEventShow = (token, requestId) => {
  return makeRequest({
    uri: `/organizers/v1/events/${requestId}`,
    authorization: token,
  });
};

export const apiCompanyEventShow = (token, requestId, id) => {
  return makeRequest({
    uri: `/admins/v1/companies/${id}/events/${requestId}`,
    authorization: token,
  });
};

export const apiEventSetting = (token, id, requestPara) => {
  return makeRequest({
    uri: `/organizers/v1/events/${id}/settings`,
    method: "PUT",
    body: JSON.stringify(requestPara),
    authorization: token,
  });
};

export const apiAdminEventSetting = (token, comId, eventId, requestPara) => {
  return makeRequest({
    uri: `/admins/v1/companies/${comId}/events/${eventId}/settings`,
    method: "PUT",
    body: JSON.stringify(requestPara),
    authorization: token,
  });
};

export const apiEventDesign = (token, id, requestPara) => {
  return makeRequest({
    uri: `/organizers/v1/events/${id}/details`,
    method: "PUT",
    body: JSON.stringify(requestPara),
    authorization: token,
  });
};

export const apiAdminEventDesign = (token, comId, eventId, requestPara) => {
  return makeRequest({
    uri: `/admins/v1/companies/${comId}/events/${eventId}/details`,
    method: "PUT",
    body: JSON.stringify(requestPara),
    authorization: token,
  });
};

export const apiTeamlist = (token, id, pagination) => {
  return makeRequest({
    uri: `/organizers/v1/events/${id}/teams?page=${pagination.page}&per_page=${pagination.perPage}`,
    authorization: token,
  });
};

export const apiAdminTeamlist = (token, comId, eventId, pagination) => {
  return makeRequest({
    uri: `/admins/v1/companies/${comId}/events/${eventId}/teams?page=${pagination.page}&per_page=${pagination.perPage}`,
    authorization: token,
  });
};

export const apiParticipantslist = (token, id, pagination) => {
  return makeRequest({
    uri: `/organizers/v1/events/${id}/participants?page=${pagination.pageValue}&per_page=${pagination.per_page}`,
    authorization: token,
  });
};

export const apiEventParticipantslist = (token, comId, eventId, pagination) => {
  return makeRequest({
    uri: `/admins/v1/companies/${comId}/events/${eventId}/participants?page=${pagination.pageValue}&per_page=${pagination.per_page}`,
    authorization: token,
  });
};

export const apiAddmember = (token, id, requestBody) => {
  return makeRequest({
    uri: `/organizers/v1/teams/${id}/participant`,
    method: "POST",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
};

export const apiTeamdetails = (token, id) => {
  return makeRequest({
    uri: `/organizers/v1/teams/${id}`,
    authorization: token,
  });
};

export const apiAdminTeamdetails = (token, comId, eventId) => {
  return makeRequest({
    uri: `/admins/v1/companies/${comId}/events/${eventId}/team_details`,
    authorization: token,
  });
};

export const apiParticipantsDet = (token, comId, eventId, participantId) => {
  return makeRequest({
    uri: `/admins/v1/companies/${comId}/events/${eventId}/details?participant_id=${participantId}`,
    authorization: token,
  });
};

export const apiParticipantsDelete = (token, comId, eventId, params) => {
  return makeRequest({
    uri: `/admins/v1/companies/${comId}/events/${eventId}/remove_member`,
    authorization: token,
    method: "PUT",
    body: JSON.stringify(params),
  });
};

export const apiOrgParticipantsDet = (token, eventId, participant_id) => {
  return makeRequest({
    uri: `/organizers/v1/events/${eventId}/details?participant_id=${participant_id}`,
    authorization: token,
  });
};

export const apiCreateteam = (token, id, requestPara) => {
  return makeRequest({
    uri: `/organizers/v1/events/${id}/teams`,
    authorization: token,
    method: "POST",
    body: JSON.stringify(requestPara),
  });
};

export const apiUpdateteam = (token, id, requestPara) => {
  return makeRequest({
    uri: `/organizers/v1/teams/${id}`,
    authorization: token,
    method: "PUT",
    body: JSON.stringify(requestPara),
  });
};

export const apiAdminUpdateteam = (token, comId, eventId, requestPara) => {
  return makeRequest({
    uri: `/admins/v1/companies/${comId}/events/${eventId}/teams`,
    authorization: token,
    method: "PUT",
    body: JSON.stringify(requestPara),
  });
};

export const apiOrganizersroles = (token) => {
  return makeRequest({
    uri: `/organizers/v1/roles`,
    authorization: token,
  });
};

export const apiOrganizerlist = (token, search) => {
  return makeRequest({
    uri: `/organizers/v1/organizers?query=${search}`,
    authorization: token,
  });
};

export const apiUserroles = () => {
  return makeRequest({
    uri: `/organizers/v1/organizers/role_groups`,
  });
};

export const apiUsercreate = (token, id, requestBody) => {
  return makeRequest({
    uri: `/organizers/v1/organizers`,
    method: "POST",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
};

export const apiUseredit = (token, id, requestBody) => {
  return makeRequest({
    uri: `/organizers/v1/organizers/${id}`,
    method: "PUT",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
};

export const apiAdminroles = (token) => {
  return makeRequest({
    uri: `/admins/v1/roles`,
    authorization: token,
  });
};

export const apiCompanieslist = (token, search, pagination) => {
  return makeRequest({
    uri: `/admins/v1/companies?page=${pagination.page}&per_page=${pagination.per_page}?query=${search.query}`,
    authorization: token,
  });
};

export const apiCompaniescreate = (token, id, requestBody) => {
  return makeRequest({
    uri: `/admins/v1/companies`,
    authorization: token,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
};

export const apiCompaniesedit = (token, id, requestBody) => {
  return makeRequest({
    uri: `/admins/v1/companies/${id}`,
    authorization: token,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
};
export const apiAdminorganizerlist = (token, id, search) => {
  return makeRequest({
    uri: `/admins/v1/companies/${id}/organizers?query=${search.query}`,
    authorization: token,
  });
};

export const apiProductlist = (token) => {
  return makeRequest({
    uri: `/admins/v1/products`,
    authorization: token,
  });
};

export const apiSubscriptionlist = (token, id) => {
  return makeRequest({
    uri: `/admins/v1/subscriptions/${id}`,
    authorization: token,
  });
};

export const apiSubscriptionedit = (token, id, requestBody) => {
  return makeRequest({
    uri: `/admins/v1/subscriptions/${id}`,
    authorization: token,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
};

export const apiProductedit = (token, id, requestBody) => {
  return makeRequest({
    uri: `/admins/v1/products/${id}`,
    authorization: token,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
};

export const apiSubscriptioncreate = (token, requestBody) => {
  return makeRequest({
    uri: `/admins/v1/products`,
    authorization: token,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
};

export const apiOrganizercreate = (token, companyid, requestBody) => {
  return makeRequest({
    uri: `/admins/v1/companies/${companyid}/organizers`,
    authorization: token,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
};

export const apiOrganizeredit = (
  token,
  companyid,
  requestBody,
  organizerId
) => {
  return makeRequest({
    uri: `/admins/v1/companies/${companyid}/organizers/${organizerId}`,
    authorization: token,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
};

export const apiAdminevents = (token, id, pagination) => {
  return makeRequest({
    uri: `/admins/v1/companies/${id}/events?page=${pagination.page}&per_page=${pagination.per_page}`,
    authorization: token,
  });
};

export const apiCompanyroles = () => {
  return makeRequest({
    uri: `/admins/v1/companies/role_groups`,
  });
};

export const apiAdminDashboard = (token) => {
  return makeRequest({
    uri: `/admins/v1/dashboards/events_chart`,
    authorization: token,
  });
};

export const apiAdminDashboardTiles = (token) => {
  return makeRequest({
    uri: `/admins/v1/dashboards`,
    authorization: token,
  });
};

export const apiAdminDashboardList = (token) => {
  return makeRequest({
    uri: `/admins/v1/dashboards/events_list`,
    authorization: token,
  });
};
export const apiLeadIndividualOrganizer = (token) => {
  return makeRequest({
    uri: `/organizers/v1/events/champions`,
    authorization: token,
  });
};
export const apiLeadTeamsOrganizer = (token) => {
  return makeRequest({
    uri: `/organizers/v1/events/champion_teams`,
    authorization: token,
  });
};
export const totalCountsOrganizer = (token) => {
  return makeRequest({
    uri: `/organizers/v1/events/card_info`,
    authorization: token,
  });
};
export const stepSyncOrganizer = (token, params) => {
  return makeRequest({
    uri: `/organizers/v1/events/syncs?from_date=${params.from_date}&to_date=${params.to_date}`,
    authorization: token,
  });
};
export const apiLeadIndividualAdmin = (token, id) => {
  return makeRequest({
    uri: `/admins/v1/companies/${id}/events/champions`,
    authorization: token,
  });
};
export const apiStatisticsData = (token) => {
  return makeRequest({
    uri: `/admins/v1/dashboards/site_info`,
    authorization: token,
  });
};

export const apiPopularData = (token) => {
  return makeRequest({
    uri: `/admins/v1/dashboards/popular_events`,
    authorization: token,
  });
};

export const apiWeeklyData = (token, params) => {
  return makeRequest({
    uri: `/admins/v1/dashboards/registrations?from_date=${params.from_date}&to_date=${params.to_date}`,
    authorization: token,
  });
};

export const apiLeadTeamsAdmin = (token, id) => {
  return makeRequest({
    uri: `/admins/v1/companies/${id}/events/champion_teams`,
    authorization: token,
  });
};
export const totalCountsAdmin = (token, id) => {
  return makeRequest({
    uri: `/admins/v1/companies/${id}/events/card_info`,
    authorization: token,
  });
};
export const stepSyncAdmin = (token, params, id) => {
  return makeRequest({
    uri: `/admins/v1/companies/${id}/events/syncs?from_date=${params.from_date}&to_date=${params.to_date}`,
    authorization: token,
  });
};
export const apiRemoveParticipant = (token, id, requestBody) => {
  return makeRequest({
    uri: `/organizers/v1/events/${id}/remove_member`,
    authorization: token,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
};
