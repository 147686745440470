import React from "react";
import { IoClose } from "react-icons/io5";
import { ToastContainer } from "react-toastify";
import { Header } from "./header";

function UpdateStatus({ status, accountUpdate, modalClose }) {
  return (
    <div>
      <div
        style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
        className="w-full h-full z-[2] absolute top-0 left-0  flex justify-center items-center "
      >
        <div className="bg-white p-6 flex flex-col gap-4 flex-[0.3]">
          <div className="flex justify-between">
            <div>
              <Header
                title={
                  status === "active" ? "Recovery Account" : "Delete Account"
                }
              />
            </div>

            <div>
              <IoClose
                onClick={modalClose}
                className="h-10 w-8 cursor-pointer"
              />
            </div>
          </div>
          <div className="text-center">
            <h1 className="text-xl font-semibold">
              Are you sure{" "}
              {status === "active"
                ? "Recovery this Account"
                : "Delete this Account"}
            </h1>
          </div>
          <div className="pt-7 flex gap-4 justify-end">
            <div
              onClick={modalClose}
              className=" hover:bg-[#42C3AA] flex flex-[0.2] 
                         cursor-pointer text-[#42C3AA] h-10 bg-white border border-solid
                          border-[#42C3AA]  justify-center hover:text-white p-2 "
            >
              <p>Cancel</p>
            </div>
            <div
              onClick={accountUpdate}
              className={
                ` bg-[#42C3AA] flex flex-[0.2]
                         cursor-pointer hover:text-[#42C3AA] hover:bg-white hover:border hover:border-solid
                          hover:border-[#42C3AA]  justify-center text-white p-2 h-10 `
                //   : ` disabled:bg-slate-200 flex flex-[0.2] h-10
                //           cursor-pointer text-slate-300  justify-center p-2 border border-slate-200 border-solid`
              }
            >
              <p>Confirm</p>
            </div>
          </div>
          <ToastContainer autoClose={2200} style={{ fontWeight: "normal" }} />
        </div>
      </div>
    </div>
  );
}

export default UpdateStatus;
