import makeRequest from "./index";

export const apiUsersShow = (token, pagination, search) => {
  return makeRequest({
    uri: `/admins/v1/users/request_info?page=${pagination.page}&per_page=${pagination.per_page}&query=${search.query}`,
    authorization: token,
  });
};

export const apiUpdateAccount = (id, status, token) => {
  return makeRequest({
    uri: `/admins/v1/users/status`,
    authorization: token,
    method: "PUT",
    body: JSON.stringify({ user_id: id, status: status }),
  });
};
