import { apiUsersShow, apiUpdateAccount } from "api/accounts";
import { titleize } from "helper";
import { getCookie } from "helper/cookie";
import React, { useEffect, useState } from "react";
import { Header } from "./header";
import { toast, ToastContainer } from "react-toastify";
import { toastStyle } from "helper";
import Pagination from "./pagination";
import moment from "moment";
import Empty from "./empty";
// import Skeleton from "./skeleton";
import UpdateStatus from "./updateStatus";

function UserData({ handleSearchshow, searchName }) {
  const [data, setData] = useState([]);
  const [toasts, setToast] = useState(false);
  const [pagination, setPagination] = useState({ per_page: 25, page: 1 });
  const [total_entries, setTotalEntries] = useState("");
  const [search, setSearch] = useState({ query: "" });
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [details, setDetails] = useState({ id: "", status: "" });

  useEffect(() => {
    fetchData();
    handleSearch({ target: { value: searchName } });
  }, [searchName]);

  useEffect(() => {
    fetchData();
    handleSearchshow(true);
  }, [search, pagination]);

  const fetchData = () => {
    apiUsersShow(getCookie("auth_token"), pagination, search)
      .then(({ data, total_entries }) => {
        setData(data);
        setLoading(false);
        setTotalEntries(total_entries);
      })
      .catch((err) => {
        toast.error(err[0]?.message, toastStyle);
      });
  };

  const updateConfermation = (id, status) => {
    setDetails({ id: id, status: status });
    setModal(true);
  };

  const accountUpdate = () => {
    apiUpdateAccount(details.id, details.status, getCookie("auth_token"))
      .then(({ message }) => {
        toast.success(message, toastStyle);
        setModal(false);
        setToast(true);
        fetchData();
      })
      .catch((err) => {
        toast.error(
          err[0]?.data ? err[0]?.data[0] : err[0]?.message,
          toastStyle
        );
      });
  };

  const modalClose = () => {
    setModal(false);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch({ query: value });
  };
  const handlePagination = (page) => {
    setPagination((preState) => ({
      ...preState,
      page: page,
    }));
  };

  if (loading) {
    const row = [];
    for (let i = 0; i < 8; i++) {
      row.push(
        <div key={i} className="flex-[0_0_25%] ">
          <div className="flex flex-col animate-pulse gap-1 m-3 border-[1px]">
            <div className="bg-[#dcdde1] h-8 rounded-sm"> </div>
          </div>
        </div>
      );
    }
    return <div className="flex flex-wrap py-10">{row}</div>;
  }
  const modalProps = {
    status: details.status,
    accountUpdate,
    modalClose,
  };

  return (
    <div>
      <Header title={"Deleting Accounts"} />
      {data ? (
        <table className=" w-[100%] bg-slate-50">
          <tr>
            <th className="bg-[#F9FAFC] shadow-sm w-[20%]">Name/Phone</th>
            <th
              className=" bg-[#F9FAFC] shadow-sm p-4 w-[20%]"
              style={{ borderLeftWidth: "2px" }}
            >
              Email
            </th>
            <th
              className=" bg-[#F9FAFC] shadow-sm p-4 w-[10%]"
              style={{ borderLeftWidth: "2px" }}
            >
              status
            </th>
            <th
              className=" bg-[#F9FAFC] shadow-sm p-4 w-[12%]"
              style={{ borderLeftWidth: "2px" }}
            >
              Delete Time
            </th>
            <th
              className=" bg-[#F9FAFC] shadow-sm p-4 w-[12%]"
              style={{ borderLeftWidth: "2px" }}
            >
              Recover Time
            </th>
            <th
              className=" bg-[#F9FAFC] shadow-sm p-4 w-[10%]"
              style={{ borderLeftWidth: "2px" }}
            >
              Manage
            </th>
          </tr>
          {data.map((val, i) => {
            const bg = i % 2 === 1;
            return (
              <tr
                key={i}
                className={`text-center ${
                  !bg ? "bg-white" : "bg-slate-50"
                } text-[#534F5A] font-['Nunito_Sans'] hover:bg-slate-100`}
                style={{ borderBottomWidth: "1px" }}
              >
                <td className="p-3 w-[20%]">
                  <div style={{ display: "flex", gap: "15px" }}>
                    {val?.img_url && (
                      <img
                        src={val?.img_url}
                        className="rounded-full ml-3"
                        height={"50px"}
                        width={"50px"}
                      />
                    )}
                    <p style={{ display: "flex", flexDirection: "column" }}>
                      <p>{val.name ? titleize(val.name) : "-"}</p>
                      <p className="text-[#8A8D93]">
                        {val.phone ? val.phone : "-"}
                      </p>
                    </p>
                  </div>
                </td>
                <td className="p-3 w-[20%]">{val.email ? val.email : "-"}</td>
                <td className="p-3 w-[10%]">
                  <span
                    className="p-3  text-xs"
                    style={{
                      background:
                        val.status === "deleting" ? "#EAEAEB" : "#DBEFF8",
                      color: val.status === "deleting" ? "#9B9DA3" : "#5EC8FC",
                      borderRadius: "10px",
                    }}
                  >
                    {titleize(val.status)}
                  </span>
                </td>
                <td className="p-3 w-[12%]">
                  {val.delete_timing
                    ? moment(val?.delete_timing).format("ll")
                    : "-"}
                </td>

                <td className="p-3 w-[12%] ">
                  {val.recover_timing
                    ? moment(val?.recover_timing).format("ll")
                    : "-"}
                </td>
                <td className="p-3 text-center w-[16%]">
                  <span
                    className="bg-[#EBE3F8] text-[#9155FD] p-2 rounded cursor-pointer text-center"
                    onClick={() => updateConfermation(val.id, "active")}
                  >
                    Recovery
                  </span>
                  <span
                    className="bg-[#FFE9EA] text-[#FF4E53] p-2 ml-5 rounded cursor-pointer text-center"
                    onClick={() => updateConfermation(val.id, "deleted")}
                  >
                    Delete
                  </span>
                </td>
              </tr>
            );
          })}
        </table>
      ) : (
        <Empty no_data />
      )}
      {total_entries != 0 && (
        <div className="mt-4">
          <Pagination
            per_page={pagination?.per_page}
            total_entries={total_entries}
            handlePagination={handlePagination}
            color={"purple"}
          />
        </div>
      )}
      {toasts && (
        <ToastContainer autoClose={2200} style={{ fontWeight: "normal" }} />
      )}
      {modal && <UpdateStatus {...modalProps} />}
    </div>
  );
}

export default UserData;
