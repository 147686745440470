import makeRequest from "./index"

export const apiLogin = (requestParams) => {
    return makeRequest({
        uri: "/organizers/v1/login",
        method: "PUT",
        body: JSON.stringify(requestParams)
    })
}

export const apiAdminlogin = (requestParams) => {
    return makeRequest({
        uri: "/admins/v1/login",
        method: "PUT",
        body: JSON.stringify(requestParams)
    })
}

export const apiForgotpassword = (requestBody) => {
    return makeRequest({
        uri: "/organizers/v1/forgot_password",
        method: "PUT",
        body: JSON.stringify(requestBody)
    })
}

export const apiVerifyotp = (requestBody) => {
    return makeRequest({
        uri: "/organizers/v1/verify_otp",
        method: "PUT",
        body: JSON.stringify(requestBody)
    })
}

export const apiSetpassword = (token, requestBody) => {
    return makeRequest({
        uri: `/organizers/v1/set_password`,
        method: 'PUT',
        authorization: token,
        body: JSON.stringify(requestBody),
    })
}