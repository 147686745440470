import config from "config.json";
import { getCookie } from "helper/cookie";

export const apiHost = config.host;
export const tinyMcKey = config.tiny_api_key;
export const organizerAuth = getCookie("auth_token")
export const adminAuth = getCookie("admin_auth_token")
export const organizerStore = window.localStorage.getItem('roles')
export const adminStore = window.localStorage.getItem('adminroles')
export const countryCodeoption =
    [
        { lable: 'IN 91 +', value: '91' },
        { lable: 'SG 65 +', value: '65', },
        { lable: 'MY 60 +', value: '60' },
        { lable: 'ID 62 +', value: '62', }
    ]