import { apiHost } from "config";
import makeRequest from "./index";

export const apiImageUploader = (token, requestParams) => {
  const headers = {
    Authorization: `API_KEY ${token}`,
  };
  return fetch(`${apiHost}/organizers/v1/images`, {
    method: "POST",
    body: requestParams,
    headers: headers,
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    }
    const errorMessage = [response.status, await response.json()];
    throw errorMessage;
  });
};

export const apiFetchImage = (token, pagination) => {
  return makeRequest({
    uri: `/organizers/v1/images?page=${pagination.page}&per_page=${pagination.perPage}`,
    // uri: `/organizers/v1/images`,
    authorization: token,
  });
};

export const apiFetchAdminImage = (token, comId, pagination) => {
  return makeRequest({
    uri: `/admins/v1/images/${comId}/images_list?page=${pagination.page}&per_page=${pagination.perPage}`,
    // uri: `/organizers/v1/images`,
    authorization: token,
  });
};
