import React, { useEffect, useState } from "react";
import { DropDown, TextField } from "./formField";
import { Header } from "./header";
import { apiUsercreate, apiUseredit } from "api/event";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastStyle } from "helper";
import { getCookie } from "helper/cookie";
import { IoClose } from "react-icons/io5";
let labelname;

function Userform({ handleExit, handleUserchange, userCreate, fetchOrganizer, organizerId, dropdown, title }) {
    const { organizers: { name, email, role_group_id, status } } = userCreate;
    const { dropDown } = dropdown
    const [disabled, setDisabled] = useState(true)
    const [toastMessage, setToastMessage] = useState({ message: "", type: "" });
    let navigate = useNavigate()

    useEffect(() => {
        if (toastMessage.type !== "") {
            notify();
        }
    }, [toastMessage]);
    const handleUsersubmit = () => {
        let action = organizerId ? apiUseredit : apiUsercreate;
        action(getCookie("auth_token"), organizerId, { ...userCreate })
            .then(({ message }) => {
                setDisabled(false)
                setToastMessage({
                    message,
                    type: "success",
                });
                setTimeout(() => {
                    handleExit()
                    fetchOrganizer()
                }, 3000);
            }).catch((err) => {
                setDisabled(false)
                setTimeout(() => {
                    setDisabled(true);
                }, 4000);
                if (err[0] === 403 || err[0] === 401) {
                    navigate("/organizers/login")
                }
                setToastMessage({
                    message: err[1].data[0],
                    type: "error"
                })
            })
    }

    const userOption = dropDown.map((data) => {
        if (data?.id === role_group_id) {
            labelname = data?.name
        }
        return {
            value: data?.id,
            lable: data?.name
        }
    })

    const statusOption = [
        {
            value: 'active', lable: 'Active'
        },
        {
            value: 'inactive', lable: 'Inactive'

        }]

    const notify = () => {
        if (toastMessage.type === "error") {
            toast.error(toastMessage.message, toastStyle);
        }
        if (toastMessage.type === "success") {
            toast.success(toastMessage.message, toastStyle);
        }
    };

    return (
        <div>
            <div style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
                className='w-full h-full z-[2] absolute top-0 left-0  flex justify-center items-center '>
                <div className="bg-white p-6 flex flex-col gap-5 flex-[0.4]">
                    <div className="flex justify-between">
                        <div>
                            <Header title={title} />
                        </div>
                        <div>
                            <IoClose onClick={handleExit} className="h-10 w-8 cursor-pointer" />
                        </div>
                    </div>
                    <div className="flex flex-1 gap-4">
                        <div className="flex flex-col gap-3 flex-1">
                            <div>
                                <TextField required={true} onHandleChange={handleUserchange} placeholder={'Name'} name='name' value={name} />
                            </div>
                            <div>
                                <TextField required={true} onHandleChange={handleUserchange} placeholder={'Email'} name='email' value={email} />
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 flex-1">
                            <div>
                                <DropDown placeholder={'Role'} option={userOption} name='role_group_id' value={organizerId ? labelname : role_group_id && labelname} onHandleChange={handleUserchange} />
                            </div>
                            <div>
                                <DropDown placeholder={'Status'} name="status" option={statusOption} onHandleChange={handleUserchange} value={status} />
                            </div>
                        </div>
                    </div>
                    <div className="pt-7 flex gap-4 justify-end">
                        <div onClick={handleExit} className=" hover:bg-[#42C3AA] flex flex-[0.2] 
                                 cursor-pointer text-[#42C3AA] h-10 bg-white border border-solid
                                  border-[#42C3AA]  justify-center hover:text-white p-2 ">
                            <p>Cancel</p>
                        </div>
                        <div onClick={disabled ? handleUsersubmit : null} className={
                            disabled ?
                                ` bg-[#42C3AA] flex flex-[0.2] 
                                 cursor-pointer hover:text-[#42C3AA] hover:bg-white hover:border hover:border-solid
                                  hover:border-[#42C3AA]  justify-center text-white p-2 h-10 ` :
                                ` disabled:bg-slate-200 flex flex-[0.2] h-10
                                  cursor-pointer text-slate-300  justify-center p-2 border border-slate-200 border-solid`
                        }>
                            <p>Submit</p>
                        </div>
                    </div>
                    <ToastContainer autoClose={2200} style={{ fontWeight: 'normal' }} />
                </div>
            </div>
        </div>
    )
}

export default Userform;