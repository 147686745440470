import React, { useEffect, useState } from "react";
import { Header } from "./header";
import { IoClose } from "react-icons/io5";
import { DropDown, TextField } from "./formField";
import { apiCompaniescreate, apiCompaniesedit } from "api/event";
import { getCookie } from "helper/cookie";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastStyle } from "helper";

function Companyform({
  handleExit,
  handleAdminchange,
  Admin,
  groupId,
  title,
  fetchCompanies,
}) {
  const [disabled, setDisabled] = useState(true);
  const { name, adminEmail, status, companyid, productId } = Admin;
  let navigate = useNavigate();
  const [toastMessage, setToastMessage] = useState({ message: "", type: "" });

  useEffect(() => {
    if (toastMessage.type !== "") {
      notify();
    }
  }, [toastMessage]);

  let companyBody = {
    companies: {
      name: name,
      status: status,
      admin: {
        email: adminEmail,
      },
      product_id: groupId,
    },
  };

  let companiesEdit = {
    companies: {
      name: name,
      status: status,
    },
  };
  const handleAdminsubmit = () => {
    let company = companyid ? apiCompaniesedit : apiCompaniescreate;
    company(
      getCookie("auth_token"),
      companyid,
      !companyid ? companyBody : companiesEdit
    )
      .then(({ message }) => {
        setDisabled(false);
        setToastMessage({
          message,
          type: "success",
        });
        setTimeout(() => {
          fetchCompanies();
          handleExit();
        }, 3000);
      })
      .catch(async (err) => {
        setDisabled(false);
        setTimeout(() => {
          setDisabled(true);
        }, 4000);
        if (err[0] === 403 || err[0] === 401) {
          navigate("/admin/login");
        }
        await setToastMessage({
          message: err[1].data?.response || err[1].data[0],
          type: "error",
        });
      });
  };

  const notify = () => {
    if (toastMessage.type === "error") {
      toast.error(toastMessage.message, toastStyle);
    }
    if (toastMessage.type === "success") {
      toast.success(toastMessage.message, toastStyle);
    }
  };

  const statusOption = [
    {
      value: "active",
      lable: "Active",
    },
    {
      value: "inactive",
      lable: "Inactive",
    },
  ];

  let lableName;

  const productOption = productId.map((value) => {
    if (value?.id === groupId) {
      lableName = value?.name;
    }
    return {
      value: value?.id,
      lable: value?.name,
    };
  });

  return (
    <div>
      <div
        style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
        className="w-full h-full z-[2] absolute top-0 left-0  flex justify-center items-center "
      >
        <div
          className={`bg-white p-6 flex flex-col gap-8 ${
            companyid ? "flex-[0.2]" : "flex-[0.4]"
          }`}
        >
          <div className="flex justify-between">
            <Header title={title} />
            <IoClose onClick={handleExit} className="h-10 w-8 cursor-pointer" />
          </div>
          <div
            className={`${
              companyid ? "flex flex-col" : "flex"
            } items-center gap-8`}
          >
            <div className={`flex flex-col gap-5 flex-1`}>
              <div className="w-96">
                <TextField
                  required={true}
                  placeholder={"Name"}
                  name="name"
                  value={name}
                  onHandleChange={handleAdminchange}
                />
              </div>

              {/* {!companyid && <div>
                                <TextField required={true} placeholder={'Admin Name'} name="adminName" value={adminName} onHandleChange={handleAdminchange} />
                            </div>} */}

              {!companyid && (
                <div>
                  <TextField
                    required={true}
                    placeholder={"Admin Email"}
                    name="adminEmail"
                    value={adminEmail}
                    onHandleChange={handleAdminchange}
                  />
                </div>
              )}
            </div>
            <div className={`flex flex-col gap-5  flex-1`}>
              <div className="w-96">
                <DropDown
                  admin
                  placeholder={"Status"}
                  onHandleChange={handleAdminchange}
                  name="status"
                  option={statusOption}
                  value={status}
                />
              </div>
              {!companyid && (
                <div>
                  <DropDown
                    admin
                    placeholder={"Products"}
                    onHandleChange={handleAdminchange}
                    name="groupId"
                    option={productOption}
                    value={lableName}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-end gap-4 pt-7">
            <div
              onClick={handleExit}
              className={` hover:bg-[#8854d0] flex ${
                companyid ? "flex-[1]" : "flex-[0.2]"
              }  
                                 cursor-pointer text-[#8854d0] h-10 bg-white border border-solid
                                  border-[#8854d0]  justify-center hover:text-white p-2 `}
            >
              <p>Cancel</p>
            </div>
            <div
              onClick={disabled ? handleAdminsubmit : null}
              className={
                disabled
                  ? ` bg-[#8854d0] flex ${
                      companyid ? "flex-[1]" : "flex-[0.2]"
                    } h-10
                                 cursor-pointer hover:text-[#8854d0] hover:bg-white hover:border hover:border-solid
                                  hover:border-[#8854d0]  justify-center text-white p-2 `
                  : ` disabled:bg-slate-200 flex ${
                      companyid ? "flex-[1]" : "flex-[0.2]"
                    } h-10
                                  cursor-pointer text-slate-300 justify-center p-2 border border-slate-200 border-solid`
              }
            >
              <p>Submit</p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={3000} style={{ fontWeight: "normal" }} />
    </div>
  );
}
export default Companyform;
