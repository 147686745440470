import React from "react";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { MdOutlineEvent } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { getCookie } from "helper/cookie";
import { GiFactory } from "react-icons/gi";
import EventList from "view/eventList";
import AdminDashboard from "./adminDashboard";
import AdminIndex from "./admincompanys";
import AdminUsers from "./organizerUsers";
import { apiAdminroles, apiOrganizersroles } from "api/event";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import AdminProducts from "./adminProducts";
import Adminevents from "./adminEvents";
import Adminanalytics from "./adminAnalytics";
import AdminSubscription from "./adminSubscription";
import Adminorganizer from "./adminOrganizer";
import { MdSubscriptions, MdManageAccounts } from "react-icons/md";
import { VscOrganization } from "react-icons/vsc";
import { TbDeviceAnalytics } from "react-icons/tb";
import CreateEvents from "view/createEvents";
import Participants from "./participants";
import circle from "assets/media/circle.png";
import { BiBuildingHouse } from "react-icons/bi";
import UserData from "./userData";
import ProfileUpdate from "./profileUpdate";
import OrganizerProfile from "./organizerProfile";

const organizerMenu = [
  {
    index: 0,
    level: 1,
    name: "Dashboard",
    icon: MdOutlineDashboardCustomize,
    url: "/organizers/dashboard",
    match_url: "dashboard",
    permissions: {
      "organizers/v1/organizers": ["index", "create", "role_groups", "update"],
    },
    render: (props) => <Adminanalytics {...props} />,
  },
  {
    index: 2,
    level: 1,
    name: "Events",
    icon: MdOutlineEvent,
    url: "/organizers/events",
    match_url: "events",
    permissions: {
      "organizers/v1/events": [
        "update_detail",
        "create",
        "update_setting",
        "participants",
        "teams",
        "show",
        "index",
        "create_team",
      ],
    },
    render: (props) => <EventList {...props} />,
  },
  {
    url: "/organizers/events/teams/:id",
    match_url: "events",
    permissions: {
      "organizers/v1/events": [
        "update_detail",
        "create",
        "update_setting",
        "participants",
        "teams",
        "show",
        "index",
        "create_team",
      ],
    },
    render: (props) => <Participants {...props} />,
  },
  {
    index: 5,
    level: 1,
    name: "Users",
    icon: AiOutlineUser,
    url: "/organizers/users",
    match_url: "users",
    permissions: {
      "organizers/v1/organizers": ["index", "create", "role_groups", "update"],
    },
    render: (props) => <AdminUsers {...props} />,
  },
  {
    url: "/organizers/events/new",
    match_url: "events",
    permissions: {
      "organizers/v1/events": [
        "update_detail",
        "create",
        "update_setting",
        "participants",
        "teams",
        "show",
        "index",
        "create_team",
      ],
    },
    render: (props) => (
      <CreateEvents {...props} controls={true} design={true} />
    ),
  },
  {
    url: "/organizers/events/:id/controls",
    match_url: "events",
    permissions: {
      "organizers/v1/events": [
        "update_detail",
        "create",
        "update_setting",
        "participants",
        "teams",
        "show",
        "index",
        "create_team",
      ],
    },
    render: (props) => (
      <CreateEvents {...props} controls={true} design={false} />
    ),
  },
  {
    url: "/organizers/events/:id/design",
    match_url: "events",
    permissions: {
      "organizers/v1/events": [
        "update_detail",
        "create",
        "update_setting",
        "participants",
        "teams",
        "show",
        "index",
        "create_team",
      ],
    },
    render: (props) => (
      <CreateEvents {...props} controls={false} design={true} />
    ),
  },
  {
    url: "/organizers/profile",
    permissions: {
      "organizers/v1/organizers": ["profile", "update_profile"],
    },
    render: (props) => (
      <OrganizerProfile {...props} controls={false} design={true} />
    ),
  },
];

const adminMenu = [
  {
    index: 1,
    level: 1,
    name: "Dashboards",
    icon: MdOutlineDashboardCustomize,
    url: "/admin/dashboards",
    match_url: "dashboards",
    permissions: {
      "admins/v1/companies": ["create", "index", "update"],
    },
    render: (props) => <AdminDashboard {...props} />,
  },
  {
    index: 3,
    level: 1,
    name: "Companies",
    icon: GiFactory,
    url: "/admin/companies",
    match_url: "companies",
    permissions: {
      "admins/v1/companies": ["create", "index", "update"],
    },
    render: (props) => <AdminIndex {...props} />,
  },
  {
    index: 4,
    level: 1,
    name: "Products",
    icon: MdOutlineProductionQuantityLimits,
    url: "/admin/products",
    match_url: "products",
    permissions: {
      "admins/v1/companies": ["create", "index"],
    },
    render: (props) => <AdminProducts {...props} />,
  },
  {
    index: 3,
    level: 1,
    name: "A/C Deletion",
    icon: MdManageAccounts,
    url: "/admin/users",
    match_url: "users",
    permissions: {
      "admins/v1/users": ["update_status", "deleting_data"],
    },
    render: (props) => <UserData {...props} />,
  },
  {
    index: 0,
    level: 2,
    name: "Organizers",
    icon: VscOrganization,
    url: `/admin/companies/:id/organizers`,
    match_url: "organizers",
    permissions: {
      "admins/v1/companies": ["create", "index", "update"],
    },
    render: (props) => <Adminorganizer {...props} />,
  },
  {
    index: 1,
    level: 2,
    name: "Events",
    icon: MdOutlineEvent,
    url: `/admin/companies/:id/events`,
    match_url: "events",
    permissions: {
      "admins/v1/companies": ["create", "index", "update"],
    },
    render: (props) => <Adminevents {...props} />,
  },
  {
    index: 2,
    level: 2,
    name: "Analytics",
    icon: TbDeviceAnalytics,
    url: `/admin/companies/:id/analytics`,
    match_url: "analytics",
    permissions: {
      "admins/v1/companies": ["create", "index", "update"],
    },
    render: (props) => <Adminanalytics {...props} />,
  },
  {
    url: "/admin/companies/:id/events/:eventid/controls",
    menu_level: 2,
    match_url: "events",
    permissions: {
      "admins/v1/companies": ["create", "index", "update"],
    },
    render: (props) => (
      <CreateEvents {...props} controls={true} design={false} />
    ),
  },
  {
    url: "/admin/companies/:id/events/:eventid/design",
    match_url: "events",
    menu_level: 2,
    permissions: {
      "admins/v1/companies": ["create", "index", "update"],
    },
    render: (props) => (
      <CreateEvents {...props} controls={false} design={true} />
    ),
  },
  {
    url: "/admin/companies/:id/events/:eventid/teams",
    match_url: "events",
    menu_level: 2,
    permissions: {
      "admins/v1/companies": ["create", "index", "update"],
    },
    render: (props) => <Participants {...props} />,
  },
  {
    index: 4,
    level: 2,
    name: "Subscription",
    icon: MdSubscriptions,
    url: `/admin/companies/:id/subscription`,
    match_url: "subscription",
    permissions: {
      "admins/v1/companies": ["create", "index", "update"],
    },
    render: (props) => <AdminSubscription {...props} />,
  },
  {
    index: 3,
    url: `/admin/profile`,
    match_url: "/admin/profile",
    render: (props) => <ProfileUpdate {...props} />,
    permissions: {
      "admins/v1/admins": ["profile", "update_profile"],
    },
  },
];

const localPromise = new Promise((res) => {
  try {
    const data = JSON.parse(window.localStorage.getItem("roles"));
    if (!data || "object" !== typeof data) {
      res([false]);
    }
    res([true, data]);
  } catch (err) {
    res([false]);
  }
});

export const filteredMenu = async () => {
  let [status, userRoles] = await localPromise;
  let path = window.location.pathname.split("/")[1];

  let Roles = path === "organizers" ? apiOrganizersroles : apiAdminroles;
  if (!status) {
    await Roles(getCookie("auth_token"))
      .then(({ data }) => {
        userRoles = data;
        window.localStorage.setItem("roles", JSON.stringify(userRoles));
      })
      .catch((err) => {
        if (err[0] === 403 || err[0] === 401) {
          window.location.pathname = "/organizers/login";
        }
      });
  }
  let menus = path === "organizers" ? organizerMenu : adminMenu;
  const menuData = menus?.filter((value) => {
    return (
      Object.keys(value?.permissions)?.every((permission) =>
        Object.keys(userRoles)?.includes(permission)
      ) && value
    );
  });
  return menuData;
};

export const chartCards = [
  {
    name: "Events",
    count: "30",
    company: "Companies",
    total: "Companies count",
  },
  {
    name: "Events",
    count: "30",
    company: "Overall amount",
    total: "Subscriptions amount",
  },
  {
    name: "Events",
    count: "30",
    company: "Published Events",
    total: "Published events",
  },
  {
    name: "Events",
    count: "30",
    company: "Registered Users",
    total: "Registered users",
  },
];

export const Icon1 = () => {
  return (
    <svg
      className="h-10 w-10"
      fill="white"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="TrendingUpIcon"
    >
      <path d="M16,6L18.29,8.29L13.41,13.17L9.41,9.17L2,16.59L3.41,18L9.41,12L13.41,16L19.71,9.71L22,12V6H16Z"></path>
    </svg>
  );
};
export const Icon2 = () => {
  return (
    <svg
      className="h-10 w-10"
      fill="white"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="AccountOutlineIcon"
    >
      <path d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,6A2,2 0 0,0 10,8A2,2 0 0,0 12,10A2,2 0 0,0 14,8A2,2 0 0,0 12,6M12,13C14.67,13 20,14.33 20,17V20H4V17C4,14.33 9.33,13 12,13M12,14.9C9.03,14.9 5.9,16.36 5.9,17V18.1H18.1V17C18.1,16.36 14.97,14.9 12,14.9Z"></path>
    </svg>
  );
};
export const Icon3 = () => {
  return (
    <svg
      className="h-10 w-10"
      fill="white"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="CellphoneLinkIcon"
    >
      <path d="M22,17H18V10H22M23,8H17A1,1 0 0,0 16,9V19A1,1 0 0,0 17,20H23A1,1 0 0,0 24,19V9A1,1 0 0,0 23,8M4,6H22V4H4A2,2 0 0,0 2,6V17H0V20H14V17H4V6Z"></path>
    </svg>
  );
};
export const Icon4 = () => {
  return (
    <svg
      className="h-10 w-10"
      fill="white"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="CurrencyUsdIcon"
    >
      <path d="M7,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z"></path>
    </svg>
  );
};

export const analyticIcon = [
  {
    icon: <MdOutlineEvent className="text-[2.5rem] text-white" />,
  },
  {
    icon: <BiBuildingHouse className="text-[2.5rem] text-white" />,
  },
  {
    icon: <Icon2 />,
  },
  {
    icon: <Icon4 />,
  },
];

export const AdminAnalyticCards = ({ count, Total, Calculation }) => {
  return (
    <div
      // style={{ boxShadow: 'rgba(58, 53, 65, 0.1) 0px 2px 10px 0px' }}
      className="flex items-center  justify-between rounded-[6px] flex-[0.5] p-5"
    >
      <div className="flex flex-col gap-3">
        <p
          style={{ color: "rgba(58, 53, 65, 0.87)" }}
          className="text-[1.25rem] font-[700] tracking-[0.15px] leading-[1.6]"
        >
          {Total}
        </p>
        <p
          style={{ color: "rgba(58, 53, 65, 0.68)" }}
          className="text-[0.85rem] font-[500] tracking-[0.4px] leading-[1.25rem]"
        >
          {Calculation}
        </p>
        <p
          style={{ color: "rgba(58, 53, 65, 0.87)" }}
          className="text-[1.95rem]  font-[700] tracking-[0.15px] leading-[1.7rem]"
        >
          {count}
        </p>
      </div>
      <div>
        <img src={circle} />
      </div>
    </div>
  );
};
