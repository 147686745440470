import {
  apiGetProfile,
  apiProfileUpdate,
  apiRestPasseord,
} from "api/profileUpdateAdmin";
import { getCookie } from "helper/cookie";
import React, { useEffect, useState } from "react";
import { TextField } from "./formField";
import { toast, ToastContainer } from "react-toastify";
import { toastStyle } from "helper";
import { MdUpdate, MdLock } from "react-icons/md";

function ProfileUpdate({ handleSearchshow }) {
  const [profile, setProfile] = useState({ name: "", email: "" });
  const [btn, setBtn] = useState(true);
  const [password, setPassword] = useState({
    current_password: "",
    new_password: "",
  });
  const [passbtn, setPassBtn] = useState(true);

  useEffect(() => {
    fetchProfile();
    handleSearchshow(false);
  }, []);

  const fetchProfile = () => {
    apiGetProfile(getCookie("auth_token"))
      .then(({ data }) => {
        setProfile({ name: data.name, email: data.email });
      })
      .catch((err) => {
        toast.error(err[0]?.message, toastStyle);
      });
  };

  const profileSubmit = () => {
    let admin = {
      name: profile.name,
      email: profile.email,
    };
    setBtn(true);
    apiProfileUpdate(getCookie("auth_token"), admin)
      .then(({ message }) => {
        toast.success(message, toastStyle);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err[1]?.data ? err[1]?.data.response : err[1]?.message,
          toastStyle
        );
      });
  };

  const resetPassword = () => {
    setPassBtn(false);
    const { new_password, current_password } = password;
    apiRestPasseord(getCookie("auth_token"), current_password, new_password)
      .then(({ message }) => {
        toast.success(message, toastStyle);
        setPassBtn(true);
      })
      .catch((err) => {
        toast.error(
          err[1]?.data ? err[1]?.data.response : err[1]?.message,
          toastStyle
        );
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
    setBtn(false);
  };

  const handlePassword = (e) => {
    const { name, value } = e.target;
    setPassword({ ...password, [name]: value });
    setPassBtn(false);
  };

  return (
    <div>
      <div className="flex gap-7">
        <div className="flex flex-1 flex-col">
          <div style={{ display: "flex" }}>
            <div
              style={{
                backgroundColor: "#A020F0",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
              className={`h-15 w-[3.5vw]  rounded-md flex justify-center items-center relative z-10 left-5 top-10`}
            >
              <MdUpdate className="text-3xl text-white" />
            </div>
            <div className="flex justify-center items-center relative z-10 left-8 top-12">
              <h3 className="uppercase text-xl text-[#A020F0] mb-4 mt-5">
                Update Profile
              </h3>
            </div>
          </div>
          <div
            className={`flex flex-col bg-white
              rounded-[6px] flex-wrap  hover:shadow-lg shadow-md  text-black p-5`}
          >
            <div className={`flex flex-col gap-5 flex-1`}>
              <div className="mt-8">
                <TextField
                  required={true}
                  placeholder={"Name"}
                  name="name"
                  value={profile.name}
                  onHandleChange={handleChange}
                />
              </div>

              <div>
                <TextField
                  required={true}
                  placeholder={"Email"}
                  name="email"
                  value={profile.email}
                  onHandleChange={handleChange}
                />
              </div>
              <div className="flex justify-end">
                <button
                  disabled={btn}
                  type="submit"
                  onClick={profileSubmit}
                  className="bg-[#a020f0] text-[white] p-2 w-[25%] rounded "
                  style={{ cursor: btn ? "not-allowed" : "pointer" }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-1 flex-col">
          <div style={{ display: "flex" }}>
            <div
              style={{
                backgroundColor: "#A020F0",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
              className={`h-15 w-[3.5vw]  rounded-md flex justify-center items-center relative z-10 left-5 top-10`}
            >
              <MdLock className="text-3xl text-white" />
            </div>
            <div className="flex justify-center items-center relative z-10 left-8 top-12">
              <h3 className="uppercase text-xl text-[#A020F0] mb-4 mt-5">
                Change Password
              </h3>
            </div>
          </div>
          <div
            className={`flex flex-auto bg-white
              rounded-[6px]  hover:shadow-lg shadow-md  text-black p-5`}
          >
            <div className={`flex flex-col gap-5 flex-1`}>
              <div className="mt-8">
                <TextField
                  required={true}
                  placeholder={"Current Password"}
                  name="current_password"
                  type="password"
                  value={password.current_password}
                  onHandleChange={handlePassword}
                />
              </div>

              <div>
                <TextField
                  required={true}
                  placeholder={"New Password"}
                  name="new_password"
                  type="password"
                  value={password.new_password}
                  onHandleChange={handlePassword}
                />
              </div>
              <div className="flex justify-end">
                <button
                  disabled={passbtn}
                  type="submit"
                  onClick={resetPassword}
                  className="bg-[#a020f0] text-[white] p-2 w-[25%] rounded "
                  style={{ cursor: passbtn ? "not-allowed" : "pointer" }}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer autoClose={2200} style={{ fontWeight: "normal" }} />
    </div>
  );
}

export default ProfileUpdate;
