import { DateTime } from "helper";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel, MdProductionQuantityLimits } from "react-icons/md";

function SubscriptionCard({
  editVisible,
  name,
  price,
  cardNew,
  Date,
  count_limit,
  onclick,
  participant_limit,
  duration,
  media,
  enable_teams,
  allow_team_create,
  start_date,
  end_date,
}) {
  // var a = duration
  var b = duration / 12;
  var d = Math.floor(b);
  // var c = a - (Math.floor(b) * 12)
  return (
    <div>
      <div
        className={`flex  ${
          cardNew ? "w-[35rem] flex-1" : " w-[19rem] flex-2"
        } flex-col`}
      >
        {!cardNew && (
          <div
            style={{ backgroundColor: "#9980FA" }}
            className={`h-10 w-full rounded-[30px] relative -left-3 top-4`}
          ></div>
        )}
        {cardNew && (
          <div
            style={{
              backgroundColor: "#6c5ce7",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
            className={`h-14 w-[3vw]  rounded-md flex justify-center items-center relative z-10 left-3 top-10`}
          >
            <MdProductionQuantityLimits className="text-3xl text-white " />
          </div>
        )}
        <div
          style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
          className={` relative  ${
            cardNew && "border-t border-solid  border-slate-200"
          } font-['Nunito_Sans']  bg-slate-50 ${
            cardNew ? "flex" : "flex flex-col"
          }  p-6 gap-5`}
        >
          {!cardNew && (
            <div>
              <div className={`text-md flex justify-between  text-slate-700`}>
                {name ? name : "-"}
                <p
                  className={`${
                    !editVisible && "hidden"
                  } font-bold bg-[#9c88ff] flex justify-center text-[14px] p-1 w-14 h-7 rounded-lg cursor-pointer text-slate-200`}
                  onClick={onclick}
                >
                  Edit
                </p>
              </div>
              <div className={`text-3xl `}>
                <p>
                  {"₹"} {price ? price : "-"}
                  <sub className="text-slate-400 relative -top-1 text-[14px]">
                    {"/ per year"}
                  </sub>
                </p>
              </div>
            </div>
          )}

          <div
            className={`flex-col flex ${
              cardNew ? "gap-6" : "gap-3"
            } text-slate-400`}
          >
            <div>
              {cardNew && (
                <div
                  className={` flex justify-between text-[23px] font-semibold relative top-5 text-slate-700`}
                >
                  {name ? name : "-"}
                </div>
              )}
            </div>
            <p className={`text-slate-600 ${cardNew && " mt-5"}`}>
              Benefits of this product plans
            </p>

            <div className="flex gap-3">
              <p>Events Limit</p> <p> {count_limit ? count_limit : "-"} </p>
            </div>
            <div className="flex gap-3">
              <p>Events Participants list</p>{" "}
              <p> {participant_limit ? participant_limit : "-"}</p>
            </div>
            {duration && (
              <div className="flex gap-3">
                <p>Events Duration</p>{" "}
                <p> {duration == "0" ? "" : d + "year"} </p>
              </div>
            )}
            {Date && (
              <div className="flex gap-3">
                <p className="text-purple-400">Activated on</p>{" "}
                <p> {DateTime(start_date) ? DateTime(start_date) : "-"}</p>
              </div>
            )}
          </div>

          <div className={`flex-col  ${cardNew ? "gap-7" : "gap-3"} flex `}>
            <div>
              {cardNew && (
                <div className={`text-3xl ${cardNew && "relative top-5"} `}>
                  <p>
                    {"₹"} {price ? price : "-"}
                    <sub className="text-slate-400 relative -top-1 text-[14px]">
                      {"/ per year"}
                    </sub>
                  </p>
                </div>
              )}
            </div>

            <div
              className={`flex-col text-slate-400 flex ${
                cardNew && " mt-4"
              } gap-3`}
            >
              <p className="text-slate-600">Key Features</p>

              <div className="flex gap-2 items-center">
                {media === true ? (
                  <FaCheckCircle className="text-green-500" />
                ) : (
                  <MdCancel className="text-red-500 text-lg" />
                )}
                <p>Media</p>
              </div>

              <div className="flex gap-2 items-center">
                {enable_teams === true ? (
                  <FaCheckCircle className="text-green-500" />
                ) : (
                  <MdCancel className="text-red-500 text-lg" />
                )}
                <p>Allow teams for the events</p>
              </div>

              <div className="flex gap-2 items-center">
                {allow_team_create === true ? (
                  <FaCheckCircle className="text-green-500" />
                ) : (
                  <MdCancel className="text-red-500 text-lg" />
                )}
                <p>Allow teams create </p>
              </div>

              {Date && (
                <div className="flex gap-3">
                  <p className="text-purple-400">Expiry on</p>{" "}
                  <p> {end_date ? DateTime(end_date) : "-"}</p>
                </div>
              )}
            </div>

            <div className="flex justify-end items-end">
              {cardNew && (
                <p
                  className={`${
                    !editVisible && "hidden"
                  } font-bold border-2  text-slate-500 hover:shadow-md hover:z-1  border-slate-400 border-solid flex justify-center items-center text-[15px] p-3 w-24 h-9  cursor-pointer`}
                  onClick={onclick}
                >
                  Edit
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionCard;
