import React from "react";
import DragBox from "assets/media/dragdrap.svg";

export const ImageViewer = ({ placeholder, onHandleClick, value }) => {
  return (
    <>
      <div onClick={onHandleClick} className="cursor-pointer">
        {Array.isArray(value) ? (
          value.length !== 0 ? (
            <div className="flex flex-wrap overflow-scroll gap-2 flex-row h-[170px] border-4 p-2">
              {value?.map((banner, index) => {
                return (
                  <div
                    key={index}
                    className={`${value.length === 1
                        ? "w-full h-full object-cover"
                        : "flex-[0_0_48%]"
                      }`}
                  >
                    <img
                      src={banner?.url}
                      className="w-full h-full object-cover"
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex flex-col gap-8 items-center bg-[#F7F7FA] py-10 rounded-2xl">
              <div>
                <img src={DragBox} />
              </div>
              <div className="text-[#42C3AA]">
                <p>{placeholder}</p>
              </div>
            </div>
          )
        ) : value.image_id ? (
          <div className="w-full h-[170px] border-4 p-2">
            <img src={value?.url} className="w-full h-full object-cover" />
          </div>
        ) : (
          <div className="flex flex-col gap-8 items-center bg-[#F7F7FA] py-10 rounded-2xl">
            <div>
              <img src={DragBox} />
            </div>
            <div className="text-[#42C3AA]">
              <p>{placeholder}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
