import { apiAdminevents } from "api/event";
import { getCookie, kickUser, setCookie } from "helper/cookie";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "./card";
import Empty from "./empty";
import { Header } from "./header";
import Pagination from "./pagination";

function Adminevents({ handleSearchshow }) {
  const [Adminevents, setAdminevents] = useState([]);
  const [pagination, setPagination] = useState({
    per_page: 16,
    page: 1,
    total_entries: "",
  });
  const [show] = useState(false);
  let locationId = window.location.pathname.split("/")[3];

  useEffect(() => {
    fetchEvents();
    handleSearchshow(show);
  }, []);

  const fetchEvents = () => {
    apiAdminevents(getCookie("auth_token"), locationId, pagination)
      .then(({ data, total_entries }) => {
        setAdminevents(data);
        setPagination((preState) => ({
          ...preState,
          total_entries: total_entries,
        }));
      })
      .catch((err) => {
        if (err[0] === 403 || err[0] === 401) {
          navigate("/admin/login");
          kickUser();
        }
      });
  };

  const handlePagination = (page) => {
    setPagination((preState) => ({
      ...preState,
      page: page,
    }));
  };
  let navigate = useNavigate();
  const handleNav = (url, id, data, participants, title) => {
    setCookie("eventName", title);
    navigate(
      url === "event"
        ? `/admin/companies/${locationId}/events/${id}/controls`
        : `/admin/companies/${locationId}/events/${id}/teams`,
      {
        state: {
          value: data,
          max_participant: participants,
          title: title,
          comId: locationId,
        },
      }
    );
  };
  const cardProps = {
    locationId,
    handleNav,
  };
  return (
    <div className="flex flex-col h-full">
      <Header companyName={getCookie("companyName")} title={"Events"} />
      <div className="flex flex-col justify-between h-full">
        {Adminevents.length !== 0 ? (
          <div className="flex flex-wrap gap-5">
            {Adminevents.map((value, index) => (
              <div key={index}>
                <Card {...value} {...cardProps} />
              </div>
            ))}
          </div>
        ) : (
          <Empty no_data />
        )}
        <div className="flex justify-end pt-5">
          {pagination.total_entries != 0 && (
            <Pagination
              per_page={pagination?.per_page}
              total_entries={pagination.total_entries}
              handlePagination={handlePagination}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Adminevents;
