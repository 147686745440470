import { TimeFormat } from "helper";
import { getCookie } from "helper/cookie";
import React, { useEffect, useState } from "react";
import { EventButton } from "./formField";
import { PreviewPage } from "./preview";
import { ImTable2 } from "react-icons/im";
import { BsCardList } from "react-icons/bs";

export const Header = ({
  title,
  eventTitle,
  preview,
  handlePreview,
  data,
  submit,
  exportBtn,
  previewBtn,
  eventCreate,
  handleNav,
  handleDesign,
  design,
  control,
  submitDisable,
  handleEventSubmit,
  user,
  handleUser,
  handleCompany,
  company,
  subscribe,
  handleProduct,
  admins,
  organizer,
  handleTableview,
  tableview,
  total_entries,
  adminSubscribe,
  toogle,
  handleToogleright,
  handleToogleleft,
  cardTable,
  organizerTable,
  // maxParticipants
  companyName,
}) => {
  const [count, setCount] = useState({ countLimit: "" });
  // if (maxParticipants > maxParticipants) {
  //   return maxParticipants
  // }
  useEffect(() => {
    let a = parseInt(getCookie("product_count"));
    setCount({ countLimit: a });
  }, []);
  const { countLimit } = count;
  return (
    <div className="flex justify-between items-center pb-8">
      <div className="flex justify-between">
        <div>
          <h1 className="text-2xl font-semibold flex gap-2">
            {companyName} {title}
          </h1>
          {((design && !control) || (!design && control)) &&
            (data?.updated_at || data.updated_by) && (
              <p className="text-[#858585] pt-1 text-[14px]">
                <span className="text-lg"> {eventTitle}</span> Updated
                {data?.updated_at && TimeFormat(data?.updated_at)}
                {data.updated_by && <span> by {data?.updated_by?.name}</span>}
              </p>
            )}
        </div>
      </div>
      <div className="flex justify-between gap-5 items-center">
        <div>
          {toogle && (
            <div className="flex cursor-pointer ">
              <div
                onClick={handleToogleleft}
                className={`${
                  cardTable
                    ? organizerTable
                      ? "bg-[#42C3AA]"
                      : "bg-[#A020F0]"
                    : "bg-slate-50"
                } flex justify-center p-2  w-[3vw] border border-slate-300 border-solid`}
              >
                <BsCardList
                  className={`${cardTable ? "text-white" : "text-black"}`}
                />
              </div>
              <div
                onClick={handleToogleright}
                className={`${
                  !cardTable
                    ? organizerTable
                      ? "bg-[#42C3AA]"
                      : "bg-[#A020F0]"
                    : "bg-slate-50"
                } flex justify-center p-2 w-[3vw] border-r border-b border-t border-slate-300 border-solid`}
              >
                <ImTable2
                  className={`${!cardTable ? "text-white" : "text-black"}`}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex-1 text-right flex gap-4 justify-end">
          {previewBtn && (
            <div>
              <EventButton
                placeholder="Preview"
                styleProps="!border-[#42C3AA] !bg-white !text-[#42C3AA] hover:!bg-[#42C3AA] hover:!text-white"
                onclick={handlePreview}
              />
              {preview && <PreviewPage data={data} onHandle={handlePreview} />}
            </div>
          )}
          {!design && control && (
            <EventButton
              placeholder="Design"
              styleProps="!border-[#42C3AA] !bg-white !text-[#42C3AA] hover:!bg-[#42C3AA] hover:!text-white"
              onclick={() => {
                handleDesign(data?.id, "design");
              }}
            />
          )}
          {!control && design && (
            <EventButton
              placeholder="Control"
              styleProps="!border-[#42C3AA] !bg-white !text-[#42C3AA] hover:!bg-[#42C3AA] hover:!text-white"
              onclick={() => {
                handleDesign(data?.id, "controls");
              }}
            />
          )}
          {submit && (
            <div>
              <EventButton
                placeholder="Submit"
                onclick={handleEventSubmit}
                disable={submitDisable}
              />
            </div>
          )}
          {exportBtn && (
            <div>
              <EventButton placeholder="Export" />
            </div>
          )}
          {countLimit > total_entries
            ? eventCreate && (
                <EventButton
                  placeholder="+ Add Event"
                  onclick={() => {
                    handleNav("new");
                  }}
                />
              )
            : null}

          {tableview && (
            <EventButton placeholder={"Add Member"} onclick={handleTableview} />
          )}
          {user && (
            <EventButton
              placeholder={"Create User"}
              onclick={() => handleUser()}
            />
          )}
          {adminSubscribe && (
            <EventButton
              placeholder={"Edit Subscription"}
              admin
              onclick={handleUser}
            />
          )}
          {organizer && (
            <EventButton
              placeholder={"Create Organizers"}
              admin
              onclick={() => handleUser()}
            />
          )}
          {admins && (
            <EventButton
              placeholder={"Create company"}
              admin
              onclick={() => handleUser()}
            />
          )}
          {subscribe && (
            <EventButton
              placeholder={"Edit Product"}
              admin
              onclick={handleProduct}
            />
          )}
          {company && (
            <EventButton
              placeholder={"Add companies"}
              onclick={handleCompany}
            />
          )}
        </div>
      </div>
    </div>
  );
};
