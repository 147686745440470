import moment from "moment";
import { getCookie, setCookie } from "./cookie";

const welcome = () => {
  let cookie = getCookie("israndom")
  var array = ["Hello", "Hi", "Howdy!", "Hey!", "G’day!", "Welcome"];
  cookie == "true" && setCookie("admin", array[Math.floor(Math.random() * array.length)])
  setCookie("israndom", "false")
  return getCookie("admin");
}

function SliceName(str, size) {
  if (!str) {
    return 'Empty'
  }
  if (str.length > 10) {
    return str.slice(0, size) + '....'
  }
  return str
}

function titleize(str) {
  if (!str) {
    return str;
  }

  return str
    .split(" ")
    .map(function (string) {
      return string?.charAt(0).toUpperCase() + string.slice(1);
    })
    .join(" ")
    .split("_")
    .map(function (string) {
      return string?.charAt(0).toUpperCase() + string.slice(1);
    })
    .join(" ");
}

function DateTime(time) {
  if (!time) {
    return "-";
  } else {
    return moment(time).format("DD-MMM, hh:ss A");
  }
}

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

const jsonHeaders = {
  Accept: "application/json",
  "Content-type": "application/json",
};

const toastStyle = {
  position: "bottom-left",
  autoclose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

function TimeFormat(time) {
  if (!time) {
    return "";
  } else {
    const current = moment();
    return (
      moment.duration(moment(time).diff(moment(current))).humanize() + " ago"
    );
  }
}

export {
  welcome,
  titleize,
  jsonHeaders,
  toastStyle,
  DateTime,
  TimeFormat,
  SliceName,
  formatDate
}