import React, { useEffect, useState } from "react";
import { apiAdminTeamdetails, apiTeamdetails } from "api/event";
import { useNavigate } from "react-router-dom";
import { getCookie } from "helper/cookie";
import { Button, Headers } from "data/eventData";
import { Header } from "./header";
import Empty from "./empty";
import TableForm from "./tableForm";
import { IoClose } from "react-icons/io5";
import { BsFillInfoCircleFill } from "react-icons/bs";


function Tableview({
  id,
  tableViewdata,
  handleExit,
  handleReset,
  fetchParticipant,
  fetchTeam,
  mobileNo,
  countryCode,
  handleTablechange,
  loginType,
  comId,
}) {
  const [viewData, setViewdata] = useState([]);
  const [tableView, setTableview] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    fetchDetails();
  }, []);

  let UrlPath = window.location.pathname.split('/');
  const fetchDetails = () => {
    if (loginType === "admin") {
      apiAdminTeamdetails(getCookie("auth_token"), comId, id)
        .then(({ data }) => {
          setViewdata(data?.participants);
        })
        .catch((err) => {
          if (err[0] === 403 || err[0] === 401) {
            navigate("/organizers/login");
          }
        });
    } else {
      apiTeamdetails(getCookie("auth_token"), id)
        .then(({ data }) => {
          setViewdata(data?.participants);
        })
        .catch((err) => {
          if (err[0] === 403 || err[0] === 401) {
            navigate("/organizers/login");
          }
        });
    }
  };

  const handleAdd = () => {
    setTableview(true);
  };
  const handleViewexit = () => {
    handleReset();
    setTableview(false);
  };

  const tableViewprops = {
    tableViewdata,
    mobileNo,
    countryCode,
    handleTablechange,
    handleViewexit,
    fetchTeam,
    fetchDetails,
    handleReset,
    fetchParticipant,
  };
  return (
    <div>
      <div
        className="w-full h-full z-[2] absolute top-0 left-0 flex justify-center items-center"
        style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
      >
        <div className="h-[70vh] w-[55%]">
          <div className="bg-white p-7 flex justify-between">
            <Header title={"Participants List"} />
            <div className="relative -left-20 top-1">
              {loginType !== "admin" && (
                <Button btn={"Add"} view onclick={handleAdd} />
              )}
            </div>
            <IoClose
              onClick={handleExit}
              className="h-10 w-8 cursor-pointer relative -top-2"
            />
          </div>
          <div className="p-5 bg-white  h-[50vh] overflow-scroll">
            <table className="bg-[#42C3AA] w-full relative -top-6">
              <tr>
                <th>
                  {Headers.map((value, i) => {
                    return (
                      <div
                        key={i}
                        className={
                          "p-5 flex items-center bg-[#42C3AA] text-white font-bold"
                        }
                      >
                        <td className="flex  w-[15vw] justify-start">
                          {value.participants}
                        </td>
                        {UrlPath[1] !== "organizers" && <td className="flex  w-[10vw] justify-center">
                          {value.phonenumber}
                        </td>}
                        <td className="flex w-[10vw]  justify-start relative left-32">
                          {value.Joindate}
                        </td>
                        <td className="flex w-[10vw]  justify-start relative left-32">
                          {value.Stepscount}
                        </td>
                      </div>
                    );
                  })}
                </th>
              </tr>
              <tr className="bg-white  text-white">
                {viewData.length !== 0 ? (
                  <div>
                    {viewData.map((team, i) => {
                      const even = i % 2 === 1;
                      return (
                        <div
                          key={i}
                          className={
                            even
                              ? "p-5 flex bg-slate-100 items-center text-black font-medium"
                              : "p-5 flex items-center text-black font-medium"
                          }
                        >
                          <td className="flex w-[15vw] gap-1 justify-start">
                            <img
                              src={team.img_url}
                              className="h-6 w-6 bg-slate-200 rounded-full p-1"
                            />
                            {team.name ? team.name : "-"}
                          </td>
                          {UrlPath[1] !== "organizers" && <td className="flex  w-[10vw] justify-center">
                            {team.country_code && team.phone ? "+" : null}
                            {team.country_code}
                            <p className="px-2">{team?.phone ? team?.phone.slice(0, 4) + "*".repeat(team.phone.length - 3) : "-"}</p>
                            {team?.phone && <BsFillInfoCircleFill
                              data-te-toggle="tooltip"
                              title={`${team.country_code} ${team?.phone}`}
                              data-te-placement="right"
                              data-te-ripple-color="light"
                              className="text-[#42C3AA] text-md cursor-pointer" />}
                          </td>}
                          <td className="flex w-[10vw] justify-end">
                            {team.Joindate ? team.Joindate : "-"}
                          </td>
                          <td className="flex w-[10vw] justify-end">
                            {team.step_count ? team.step_count : "0"}
                          </td>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="relative -top-40 left-5">
                    <Empty no_data />
                  </div>
                )}
              </tr>
            </table>
          </div>
        </div>
      </div>
      {tableView && <TableForm {...tableViewprops} />}
    </div>
  );
}

export default Tableview;
