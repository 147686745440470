import React, { useState } from "react";

const Pagination = ({ per_page, total_entries, handlePagination, color }) => {
  const pageNumbers = [];
  const [active, setActive] = useState(1);

  for (let i = 1; i <= Math.ceil(total_entries / per_page); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="flex justify-end gap-1 pr-4">
        {pageNumbers.map((number) => (
          <li
            onClick={() => {
              handlePagination(number);
              setActive(number);
            }}
            key={number}
            className={
              active == number
                ? `border border-solid ${
                    color ? "border-[#A020F0]" : "border-[#42C3AA]"
                  } text-sm cursor-pointer
                ${
                  color ? "text-[#A020F0]" : "text-[#42C3AA]"
                } p-1 h-7 w-8 justify-center items-center flex`
                : `border border-solid ${
                    color ? "border-[#A020F0]" : "border-[#42C3AA]"
                  }  cursor-pointer
              p-1 h-7 w-8 justify-center items-center flex`
            }
          >
            {number}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
