import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie, kickUser } from "helper/cookie";
import Chart from "react-apexcharts";
import "assets/css/main.css";
import {
  apiLeadIndividualOrganizer,
  apiLeadTeamsOrganizer,
  totalCountsOrganizer,
  stepSyncOrganizer,
  apiLeadIndividualAdmin,
  apiLeadTeamsAdmin,
  totalCountsAdmin,
  stepSyncAdmin,
} from "api/event";
import { Icon1 } from "./menu";
import { BsCalendar4Event } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import steps from "assets/media/steps.svg";
import { RiArrowLeftSLine } from "react-icons/ri";
import { RiArrowRightSLine } from "react-icons/ri";
import moment from "moment";

const chartProps = {
  chart: {
    id: "basic-bar",
    toolbar: {
      show: true,
      tools: {
        download: false,
      },
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      columnWidth: "50%",
    },
  },
  grid: {
    strokeDashArray: 7,
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  xaxis: {
    categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  yaxis: {
    show: false,
  },
};

let currentDate = new Date();
let first = currentDate.getDate() - currentDate.getDay();
let last = first + 6;

const Adminanalytics = ({ handleSearchshow }) => {
  let navigate = useNavigate();
  const [count, setCount] = useState();
  const [leadIndividual, setLeadIndividual] = useState();
  const [leadTeams, setLeadTeams] = useState();
  const [options, setOptions] = useState({});
  const [seriesList, setSeries] = useState({ series: [] });
  const [show] = useState(false);
  const [weekStart, setWeekStart] = useState(
    new Date(currentDate.setDate(first))
  );
  const [weekEnd, setWeekEnd] = useState(new Date(currentDate.setDate(last)));
  let companyId = window.location.pathname.split("/")[3];

  useEffect(() => {
    handleSearchshow(show);
    if (!getCookie("auth_token")) {
      kickUser();
      navigate(`admin/login`);
    }
    totalCount();
    chartLeadIndividual();
    chartLeadTeams();
    fetchChartdetails();
  }, []);

  useEffect(() => {
    fetchChartdetails();
    setOptions(chartProps);
    setSeries({
      series: [
        {
          name: "Steps Synced",
          data: [],
        },
      ],
    });
  }, [weekStart]);
  const totalCount = () => {
    const tileData = companyId ? totalCountsAdmin : totalCountsOrganizer;
    tileData(getCookie("auth_token"), companyId)
      .then(({ data }) => {
        setCount(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const chartLeadIndividual = () => {
    const tileData = companyId
      ? apiLeadIndividualAdmin
      : apiLeadIndividualOrganizer;
    tileData(getCookie("auth_token"), companyId)
      .then(({ data }) => {
        setLeadIndividual(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const chartLeadTeams = () => {
    const tileData = companyId ? apiLeadTeamsAdmin : apiLeadTeamsOrganizer;
    tileData(getCookie("auth_token"), companyId)
      .then(({ data }) => {
        setLeadTeams(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchChartdetails = () => {
    const tileData = companyId ? stepSyncAdmin : stepSyncOrganizer;
    const params = {
      from_date: moment(weekStart).format("yyyy-MM-DD"),
      to_date: moment(weekEnd).format("yyyy-MM-DD"),
    };
    tileData(getCookie("auth_token"), params, companyId)
      .then(({ data }) => {
        setSeries({
          series: [
            {
              name: "Steps Synced",
              data: data?.map((value) => {
                return value?.count;
              }),
            },
          ],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePreviousWeek = () => {
    var today = new Date();
    var lastWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      weekStart.getDate() - 7
    );
    let first = lastWeek.getDate() - lastWeek.getDay();
    let last = first + 6;
    setWeekStart(new Date(lastWeek.setDate(first)));
    setWeekEnd(new Date(lastWeek.setDate(last)));
  };

  const handleNextWeek = () => {
    var today = new Date();
    var lastWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      weekStart.getDate() - -7
    );
    let first = lastWeek.getDate() - lastWeek.getDay();
    let last = first + 6;
    setWeekStart(new Date(lastWeek.setDate(first)));
    setWeekEnd(new Date(lastWeek.setDate(last)));
  };

  const { series } = seriesList;
  const participant_percent =
    count?.previous_week > 0 &&
    ((count?.this_week - count?.previous_week) / count?.previous_week) * 100;
  return (
    <div className=" p-3">
      <div className="flex gap-5  flex-[0.5] ">
        <div
          style={{ boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px" }}
          className="bg-[#FFFFFF] rounded-[6px]  min-h-[180px] flex flex-col  cursor-pointer  p-6 w-[25%]"
        >
          <div className="flex justify-between items-center ">
            <div className="py-4  ">
              <h1 className="font-medium text-[1.25rem] font-['Inter']">
                Total Events
              </h1>
            </div>
            <div
              style={{
                background: "rgb(255, 180, 0)",
                boxShadow: "rgba(58, 53, 65, 0.42) 0px 4px 8px -4px",
              }}
              className={`h-20 flex justify-center items-center w-20 rounded-[5px] text-[2rem] text-[white] rounded-[50px]`}
            >
              <BsCalendar4Event />
            </div>
          </div>
          <div className="font-medium">
            <h1 className=" text-[2.15rem] font-['Inter'] text-[#3a3541de]">
              {count?.total_events
                ? `${count?.created_events}/${count?.total_events}`
                : ""}
            </h1>
          </div>
          <div className="font-normal">
            <h1 className="text-base text-[#6b7280] font-['Inter']">
              {count?.expiry_date
                ? `Your Plan Will Be Expires On" ${moment(
                    count?.expiry_date
                  ).format("MMMM Do YYYY")}`
                : ""}
            </h1>
          </div>
        </div>

        <div
          style={{ boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px" }}
          className="bg-[#FFFFFF] rounded-[6px]  min-h-[180px] flex flex-col   cursor-pointer  p-6  w-[25%]"
        >
          <div className="flex justify-between items-center">
            <div className="py-4 ">
              <h1 className="font-medium text-[1.25rem] font-['Inter']">
                Total Participants
              </h1>
            </div>
            <div
              style={{
                background: "rgb(145, 85, 253)",
                boxShadow: "rgba(58, 53, 65, 0.42) 0px 4px 8px -4px",
              }}
              className={`h-20 flex justify-center items-center w-20 rounded-[50px]`}
            >
              <Icon1 />
            </div>
          </div>
          <div className="font-medium">
            <h1 className="font-medium text-[2.15rem] font-['Inter'] text-[#3a3541de]">
              {count?.this_week}
              {count?.previous_week > 0 && (
                <span
                  className={`${
                    participant_percent > 0 ? "text-[green]" : "text-[red]"
                  } text-sm ml-2`}
                >
                  {Math.round(participant_percent)}%
                </span>
              )}
            </h1>
          </div>
          <div className="font-medium">
            <h1 className="font-normal text-base text-[#6b7280] font-['Inter']">
              {count?.previous_week > 0 && "Compared To Last Week"}
            </h1>
          </div>
        </div>
        <div
          style={{ boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px" }}
          className="bg-[#FFFFFF] rounded-[6px]  min-h-[180px] flex flex-col  cursor-pointer  p-6 w-[25%]"
        >
          <div className="flex justify-between items-center">
            <div className="py-4 ">
              <h1 className="font-medium text-[1.25rem] font-['Inter']">
                Total Organizers
              </h1>
            </div>
            <div
              style={{
                background: "#56ca00",
                boxShadow: "rgba(58, 53, 65, 0.42) 0px 4px 8px -4px",
              }}
              className={`h-20 flex justify-center items-center w-20 rounded-[5px] text-[2rem] text-[white] rounded-[50px]`}
            >
              <FiUser />
            </div>
          </div>
          <div className="font-medium">
            <h1 className="font-medium text-[2.15rem] font-['Inter'] text-[#3a3541de]">
              {count?.total_organizers || 0}/5
            </h1>
          </div>
        </div>
        <div
          style={{ boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px" }}
          className="bg-[#FFFFFF] rounded-[6px]  min-h-[180px] flex flex-col  cursor-pointer  p-6 w-[25%]"
        ></div>
      </div>
      <div className="flex gap-5">
        <div
          style={{ boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px" }}
          className="flex-1 bg-white rounded-md p-6 flex flex-col gap-3 mt-[34px] min-h-[400px] "
        >
          <div className="flex justify-between items-center">
            <div className="text-[1.25rem] text-[#534F5A] font-[500]">
              Overview of Last Week
            </div>
            <div className="flex items-center shrink-0">
              <button
                className="mr-2 text-[1.5rem] "
                onClick={handlePreviousWeek}
              >
                <RiArrowLeftSLine />
              </button>
              {moment(weekStart).format("MMM DD")}-
              {moment(weekEnd).format("MMM DD")}
              <button className="ml-2 text-[1.5rem]" onClick={handleNextWeek}>
                <RiArrowRightSLine />
              </button>
            </div>
          </div>
          <div className="flex-1">
            <Chart options={options} series={series} type="bar" height="100%" />
          </div>
        </div>
        <div
          style={{ boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px" }}
          className="flex bg-white rounded-[6px] gap-5 p-5 min-h-[400px] mt-[34px] flex-[2_0_0]"
        >
          <div
            className="flex flex-col flex-1 "
            style={{ borderRight: "0.2px solid #d1d5db" }}
          >
            <div className="font-medium text-[1.25rem] sticky top-0 mt-1.5 bg-white flex items-center">
              <h2 className="ml-2 pb-5 text-[#6b7280] card-title">
                Chart Leaders - Individuals
              </h2>
            </div>
            <div className="m-2 mr-5 h-full">
              {leadIndividual?.length > 0 ? (
                leadIndividual?.map((value, index) => {
                  return (
                    <div
                      className="flex flex-row justify-between p-2 font-semibold mb-3 items-center"
                      key={index}
                    >
                      <div className="flex items-center">
                        <div className="text-2xl text-[#6b7280] avatar-parent">
                          <img src={value.img_url} className="avatar" />
                        </div>
                        <div className="ml-5 text-sm text-[#3A3541] ">
                          <p className="title">{value.name}</p>
                          <span className="sub-title">{value.event_title}</span>
                        </div>
                      </div>
                      <div className="flex justify-between text-[#56CA00] items-center">
                        <p
                          className="title"
                          style={{ fontSize: "1rem", marginRight: 10 }}
                        >
                          {value.steps}
                        </p>
                        <span>
                          <img src={steps} />
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className=" h-full items-center flex justify-center">
                  <p className="flex justify-center items-center">
                    No Data Found
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className=" flex flex-col flex-1 ">
            <div className="font-medium text-[1.25rem] sticky top-0 mt-1.5 bg-white flex items-center">
              <h2 className="ml-4 pb-5 text-[#6b7280] card-title">
                Chart Leaders - Teams
              </h2>
            </div>
            <div className="m-2 mr-5 h-full">
              {leadTeams?.length > 0 ? (
                leadTeams?.map((value, index) => {
                  return (
                    <div
                      className="flex flex-row justify-between p-2 font-semibold mb-3 items-center"
                      key={index}
                    >
                      <div className="flex items-center">
                        <div className="text-2xl text-[#6b7280] avatar-parent">
                          <img src={value.img_url} className="avatar" />
                        </div>
                        <div className="ml-5 text-sm text-[#3A3541] ">
                          <p className="title">{value.name}</p>
                          <span className="sub-title">{value.event_title}</span>
                        </div>
                      </div>
                      <div className="flex text-[#56CA00] items-center">
                        <p
                          className="title"
                          style={{ fontSize: "1rem", marginRight: "10px" }}
                        >
                          {value.steps}
                        </p>
                        <span>
                          <img src={steps} />
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className=" h-full items-center flex justify-center">
                  <p className="">No Data Found</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Adminanalytics;
