import { apiImageUploader } from "api/image";
import { EventForm } from "component/eventForm";
import { Header } from "component/header";
import { ImageUploader } from "component/imageUploader";
import { getCookie } from "helper/cookie";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastStyle } from "helper";
import {
  apiAdminEventDesign,
  apiAdminEventSetting,
  apiCompanyEventShow,
  apiEventCreate,
  apiEventDesign,
  apiEventSetting,
  apiEventShow,
} from "api/event";
import moment from "moment";
import CropImage from "component/cropImage";

const CreateEvents = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dataState, setState] = useState({
    control: true,
    design: false,
    preview: false,
    previewBtn: false,
    modal: false,
    multi: false,
    submitDisable: false,
    rowId: "",
    data: {
      event_setting: {
        enable_teams: false,
        allow_team_create: false,
        allow_sharing: false,
      },
      event_detail: {},
      banner_image_ids: [],
      title_image_id: "",
      plan_type: "free",
      event_type: "public_event",
      start_date: new Date(),
      end_date: new Date(new Date().getTime() + 31 * 24 * 60 * 60 * 1000),
    },
  });
  const [cropModal, setCropModal] = useState(false);

  const [toastMessage, setToastMessage] = useState({ message: "", type: "" });
  const [eventTitle, setTitle] = useState("");
  const [show, setShow] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loginType, setLoginType] = useState("");
  const [beforeCrop, setBeforeCrop] = useState([]);

  useEffect(() => {
    props.handleSearchshow(show);
    if (toastMessage.type !== "") {
      notify();
    }
    setTitle(location.state.title);
  }, [toastMessage]);
  useEffect(() => {
    setState((preState) => ({
      ...preState,
      design: props.design,
      previewBtn: props.design,
      control: props.controls,
      imageUploader: [],
    }));
    window.location.pathname.split("/")[3] !== "new" &&
      fetchShowEvent(
        window.location.pathname.split("/")[4] === "events"
          ? window.location.pathname.split("/")[5]
          : window.location.pathname.split("/")[3],
        window.location.pathname.split("/")[4]
      );
    window.location.pathname.split("/")[4] === "events" &&
      setLoginType("admin");
  }, [props]);
  const comId =
    window.location.pathname.split("/")[4] === "events" &&
    window.location.pathname.split("/")[3];
  const notify = () => {
    if (toastMessage.type === "error") {
      toast.error(toastMessage.message, toastStyle);
    }
    if (toastMessage.type === "success") {
      toast.success(toastMessage.message, toastStyle);
    }
  };
  const fetchShowEvent = (rowId, type) => {
    const action =
      type === "events"
        ? apiCompanyEventShow(
            getCookie("auth_token"),
            rowId,
            window.location.pathname.split("/")[3]
          )
        : apiEventShow(getCookie("auth_token"), rowId);
    action
      .then(({ data }) => {
        setState((preState) => ({
          ...preState,
          data,
        }));
      })
      .catch((err) => {
        if (err[0] === 403 || err[0] === 401) {
          navigate("/organizers/login");
        }
      });
  };

  const handleDateshow = (e) => {
    e == "show" && (setVisible(false), setShow(true));
    e == "visible" && (setShow(false), setVisible(true));
  };

  const handleDateexit = () => {
    setShow(false);
    setVisible(false);
  };
  const handlePreview = () => {
    const { preview } = dataState;
    setState((preState) => ({
      ...preState,
      preview: !preview,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const { data } = dataState;
    setState((preState) => ({
      ...preState,
      submitDisable: false,
      data: { ...data, [name]: value },
    }));
  };

  const handleDatechange = (e) => {
    const { start_date, end_date } = e.target.events;
    setState((preState) => ({
      ...preState,
      submitDisable: false,
      data: { ...data, start_date: start_date, end_date: end_date },
    }));
  };
  const handleCopy = (code) => {
    navigator.clipboard.writeText(code);
    setToastMessage({ message: "Code Copied To Clipboard", type: "success" });
  };

  const handleSettingChange = (e) => {
    const { name, value } = e.target;
    const { data } = dataState;
    setState((preState) => ({
      ...preState,
      submitDisable: false,
      data: {
        ...data,
        event_setting: { ...data.event_setting, [name]: value },
      },
    }));
  };
  const handleDesignChange = (e) => {
    const { name, value } = e.target;
    const { data } = dataState;
    setState((preState) => ({
      ...preState,
      submitDisable: false,
      data: {
        ...data,
        event_detail: { ...data.event_detail, [name]: value },
      },
    }));
  };

  const handleDesign = (id, type) => {
    const url =
      window.location.pathname.split("/")[4] === "events"
        ? `/admin/companies/${
            window.location.pathname.split("/")[3]
          }/events/${id}/${type}`
        : `/organizers/events/${id}/${type}`;
    const { data } = dataState;
    navigate(url, { state: data });
  };
  const handleModel = (multi) => {
    const { modal } = dataState;
    setState((preState) => ({
      ...preState,
      modal: !modal,
      imageUploader: [],
      multi: multi,
    }));
    setBeforeCrop([]);
  };
  const handleImageUploader = (e) => {
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setBeforeCrop(reader.result);
    };
    reader.readAsDataURL(files[0]);
    setCropModal(!cropModal);
  };

  const handleImageSubmit = () => {
    const { imageUploader } = dataState;
    const imageForm = [...imageUploader];
    const form = new FormData();
    imageForm.forEach((element) => {
      form.append("images[]", element);
    });

    apiImageUploader(getCookie("auth_token"), form)
      .then(({ message }) => {
        setToastMessage({
          message,
          type: "success",
        });
      })
      .catch((err) => {
        setToastMessage({
          message: err[1]?.data.response,
          type: "error",
        });
      });
    setState((preState) => ({ ...preState, imageUploader: [] }));
    setBeforeCrop([]);
  };
  const handleImageSelectSubmit = (imageSelect) => {
    const { multi, data } = dataState;
    setState((preState) => ({
      ...preState,
      submitDisable: false,
      data: {
        ...data,
        [multi ? "banner_images" : "title_image"]: multi
          ? imageSelect
          : imageSelect.image_id && imageSelect,
        [multi ? "banner_image_ids" : "title_image_id"]: Array.isArray(
          imageSelect
        )
          ? imageSelect?.map((value) => value.image_id) || []
          : imageSelect?.image_id || {},
      },
    }));
    handleModel();
  };
  const handleCloseModal = () => {
    setCropModal(!cropModal);
    setBeforeCrop([]);
  };

  const handleEventSubmit = () => {
    const { banner_images, title_image, ...events } = dataState.data;
    const { title, event_detail, ...settings } = events;
    setState((preState) => ({
      ...preState,
      submitDisable: true,
    }));

    const comId = window.location.pathname.split("/")[3];
    if (loginType === "admin") {
      if (control && !design) {
        apiAdminEventSetting(getCookie("auth_token"), comId, data?.id, {
          events: { ...settings },
        })
          .then(({ data }) => {
            setToastMessage({
              message: data && "Setting Saved Successfully...!",
              type: "success",
            });
            setTimeout(() => {
              navigate(`/admin/companies/${comId}/events`);
            }, 3000);
          })
          .catch((err) => {
            if (err[0] === 403 || err[0] === 401) {
              navigate("/admin/login");
            }
            setToastMessage({
              message: err[1]?.data[0],
              type: "error",
            });
          });
      }
      if (!control && design) {
        apiAdminEventDesign(getCookie("auth_token"), comId, data.id, {
          events: {
            title,
            title_image_id: title_image?.image_id,
            banner_image_ids: banner_images?.map((value) => value.image_id),
            event_detail,
          },
        })
          .then(({ data }) => {
            setToastMessage({
              message: data && "Design Saved Successfully...!",
              type: "success",
            });
            setTimeout(() => {
              navigate(`/admin/companies/${comId}/events`);
            }, 3000);
          })
          .catch((err) => {
            if (err[0] === 403 || err[0] === 401) {
              navigate("/admin/login");
            }
            setToastMessage({
              message: err[1]?.data[0],
              type: "error",
            });
          });
      }
    } else {
      if (control && design) {
        apiEventCreate(getCookie("auth_token"), {
          events: {
            ...events,
            start_date: moment(settings?.start_date).format("DD-MM-yyyy"),
            end_date: moment(settings?.end_date).format("DD-MM-yyyy"),
          },
        })
          .then((data) => {
            setToastMessage({
              message: data?.message,
              type: "success",
            });
            setTimeout(() => {
              navigate("/organizers/events");
            }, 3000);
          })
          .catch((err) => {
            if (err[0] === 403 || err[0] === 401) {
              navigate("/organizers/login");
            }
            setToastMessage({
              message: err[1]?.data[0],
              type: "error",
            });
          });
      }
      if (control && !design) {
        apiEventSetting(getCookie("auth_token"), data.id, {
          events: {
            ...settings,
            start_date: moment(settings?.start_date).format("DD-MM-yyyy"),
            end_date: moment(settings?.end_date).format("DD-MM-yyyy"),
          },
        })
          .then(({ data }) => {
            setToastMessage({
              message: data && "Setting Saved Successfully...!",
              type: "success",
            });
            setTimeout(() => {
              navigate("/organizers/events");
            }, 3000);
          })
          .catch((err) => {
            if (err[0] === 403 || err[0] === 401) {
              navigate("/organizers/login");
            }
            setToastMessage({
              message: err[1]?.data[0],
              type: "error",
            });
          });
      }
      if (!control && design) {
        apiEventDesign(getCookie("auth_token"), data.id, {
          events: {
            title,
            title_image_id: title_image?.image_id,
            banner_image_ids: banner_images?.map((value) => value.image_id),
            event_detail,
          },
        })
          .then(({ data }) => {
            setToastMessage({
              message: data && "Design Saved Successfully...!",
              type: "success",
            });
          })
          .catch((err) => {
            if (err[0] === 403 || err[0] === 401) {
              navigate("/organizers/login");
            }
            setToastMessage({
              message: err[1]?.data[0],
              type: "error",
            });
          });
      }
    }
  };
  const {
    control,
    design,
    preview,
    data,
    previewBtn,
    modal,
    imageUploader,
    multi,
    submitDisable,
  } = dataState;
  const statusOption = [
    { value: "draft", lable: "Draft" },
    { value: "published", lable: "Published" },
    { value: "cancelled", lable: "Cancelled" },
    { value: "completed", lable: "Completed" },
  ];
  const measureOption = [
    { value: "steps", lable: "Steps" },
    { value: "distance", lable: "Distance" },
    { value: "calories", lable: "Calories" },
  ];

  const headerProps = {
    title: control && design ? "Add Your Event" : eventTitle,
    control,
    design,
    preview,
    previewBtn,
    submit: true,
    submitDisable,
    handlePreview,
    data,
    handleDesign,
    handleEventSubmit,
    loginType,
  };
  const eventProps = {
    data,
    handleChange,
    handleDatechange,
    statusOption,
    control,
    design,
    handleModel,
    handleSettingChange,
    handleDesignChange,
    measureOption,
    handleCopy,
    handleDateshow,
    show,
    visible,
    handleDateexit,
  };
  const imageProps = {
    beforeCrop,
    handleModel,
    handleImageUploader,
    imageUploader,
    handleImageSubmit,
    handleImageSelectSubmit,
    multi,
    imageData: data,
    loginType,
    comId,
  };

  const cropProps = {
    beforeCrop,
    handleCloseModal,
    setState,
    imageUploader,
  };

  return (
    <>
      <Header {...headerProps} />
      <EventForm {...eventProps} />
      {modal && <ImageUploader {...imageProps} />}
      {cropModal && <CropImage {...cropProps} />}
      <ToastContainer autoClose={2200} style={{ fontWeight: "normal" }} />
    </>
  );
};

export default CreateEvents;
