import React, { useEffect, useState } from "react";
import { LoginInput } from "./formField";
import loginBack from "assets/media/loginBg.png";
import loginBg from "assets/media/loginRunbg.png";
import { apiAdminlogin, apiForgotpassword, apiLogin, apiSetpassword, apiVerifyotp } from "api/login";
import { getCookie, setCookie } from "helper/cookie";
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
import { toastStyle } from "helper";
import { IoMdArrowRoundBack } from "react-icons/io";

export const LoginForm = ({ organizer }) => {
  const [email, setEmail] = useState("");
  const [forgotEmail, setForgotemail] = useState({
    forgotEmailvalue: '',
    forgotOtp: false,
    forgotOtpvalue: '',
    confirmPassword: false,
    newPassword: '',
    confirmNewPassword: '',
  })
  const [forgot, setForgot] = useState(false)
  const [password, setPassword] = useState("");
  const [toastMessage, setToastMessage] = useState({ message: "", type: "" });
  const [disabled, setDisabled] = useState(false);
  const [otpButton, setOtpButton] = useState(false);
  const [verifyButton, setVerifyButton] = useState(false)
  const [emailButton, setEmailButton] = useState(false)

  const notify = () => {
    const { type, message } = toastMessage;
    if (type === "error") {
      toast.error(message, toastStyle);
    }
    if (type === "success") {
      toast.success(message, toastStyle);
    }
  };
  useEffect(() => {
    if (toastMessage !== "") {
      notify();
    }
  }, [toastMessage]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    name === "email" ? setEmail(value) : setPassword(value);
  };

  const handleEmailchange = (e) => {
    const { name, value } = e.target;
    setForgotemail(prestate => ({ ...prestate, [name]: value }))
    setVerifyButton(false)
    setOtpButton(false)
    setEmailButton(false)
  }
  const handleLogin = (e) => {
    e.preventDefault();
    setDisabled(true);

    let Login = organizer ? apiLogin : apiAdminlogin;
    Login({ username: email, password })
      .then(({ data }) => {
        setCookie("auth_token", data?.auth_token);
        setCookie("name", data?.name);
        setCookie("path", window.location.pathname.split("/")[1]);
        setCookie("israndom", "true");
        setCookie("product_count", data?.company?.subscription_details?.count_limit);
        setCookie(
          "product_participant_count",
          data?.company?.subscription_details?.participant_limit
        );
        setCookie("organizerId", data?.id)
        setCookie(
          "product_enable_teams",
          data?.company?.subscription_details?.enable_teams
        );
        setCookie(
          "product_allow_team_create",
          data?.company?.subscription_details?.allow_team_create
        );
        setToastMessage({
          message: "Login successfully",
          type: "success",
        });

        window.location.reload();
      })
      .catch(async (err) => {
        setTimeout(() => {
          setDisabled(false);
        }, 4000);

        if (err[0] === 401) {
          setToastMessage({
            message: "Invalid email and password",
            type: "error",
          });
        }
        await setToastMessage({
          message: err[1].data.response,
          type: "error",
        });
      });
  };
  const { forgotEmailvalue, forgotOtp, forgotOtpvalue, confirmPassword, newPassword, confirmNewPassword } = forgotEmail

  const handleForgotsubmit = () => {
    setEmailButton(true)
    apiForgotpassword({ email: forgotEmailvalue }).then(({ message }) => {
      setToastMessage({
        message: message,
        type: 'success'
      })
      setTimeout(() => {
        setForgotemail(prestate => ({ ...prestate, forgotOtp: true }))
        setForgotemail(prestate => ({ ...prestate, forgotOtpvalue: '' }))
      }, 3000);
    }).catch((err) => {
      setToastMessage({
        message: err[1].data.response,
        type: "error"
      })
    })
  }
  const handleOtpSubmit = () => {
    setOtpButton(true)
    apiVerifyotp({ email: forgotEmailvalue, otp_token: forgotOtpvalue }).then(({ message, auth_token }) => {
      setCookie("otp_token", auth_token)
      setToastMessage({
        message: message,
        type: 'success'
      })
      setTimeout(() => {
        setForgotemail(prestate => ({ ...prestate, confirmPassword: true }))
        setForgotemail(prestate => ({ ...prestate, newPassword: '', confirmNewPassword: '' }))
      }, 3000)
    }).catch((err) => {
      setToastMessage({
        message: err[1].data.response,
        type: "error"
      })
    })
  }
  const handlePasswordsubmit = () => {
    setVerifyButton(true)
    apiSetpassword(
      getCookie("otp_token"),
      { email: forgotEmailvalue, password: newPassword }).then(({ message }) => {
        setToastMessage({
          message: message,
          type: "success"
        })
        setTimeout(() => {
          setForgot(false)
          setForgotemail(prestate => ({ ...prestate, forgotOtp: false, confirmPassword: false }))
        }, 3000)
      }).catch((err) => {
        setToastMessage({
          message: err[1],
          type: 'error'
        })
      })
  }
  const handleForgot = () => {
    setForgot(true)
    setForgotemail(prestate => ({ ...prestate, forgotEmailvalue: '' }))
  }
  const handleBack = (e) => {
    e === "home" && setForgot(false),
      e === "emailPage" &&
      setForgotemail(prestate => ({ ...prestate, forgotOtp: false })),
      e === "otp" &&
      setForgotemail(prestate => ({ ...prestate, confirmPassword: false }))

  }

  return (
    <div
      className={`relative min-h-screen h-[100%]  flex justify-end items-center bg-no-repeat  md:bg-[length:70vw_100vh] lg:bg-[length:70vw_100vh]   bg-slate-100`}
      style={{ backgroundImage: `url("${organizer ? loginBg : loginBack}")` }}
    >
      <div className={`w-[450px] ${forgot ? "h-[460px]" : "h-[560px]"}${confirmPassword && "h-[500px]"}  -left-12 bg-slate-100 rounded-3xl shadow-slate-400 shadow-md  flex gap-3 relative overflow-hidden`}>
        <div className={`w-[250px] h-[250px] ${organizer ? 'bg-[#00CBB5]' : 'bg-[#A020F0]'}  absolute top-[-80px] left-[-80px] rounded-full z-[1]`} />
        <div className={`flex-1 px-8 pt-20   ${organizer ? 'bg-slate-200' : 'bg-slate-100'}rounded-2xl`}>
          <h1 className="text-4xl font-bold !text-black z-[2] relative">
            Login
          </h1>
          {(!forgot && !forgotOtp && !confirmPassword) &&
            <form onSubmit={handleLogin}>
              <div className="flex flex-col gap-14 pt-20">
                <div className="w-[90%] mx-auto">
                  <LoginInput
                    placeholder="Email"
                    type="text"
                    value={email}
                    name="email"
                    onHandle={handleChange}
                    organizer={organizer}
                  />
                </div>
                <div className="w-[90%] mx-auto">
                  <LoginInput
                    placeholder="Password"
                    type="password"
                    value={password}
                    name="password"
                    onHandle={handleChange}
                    organizer={organizer}
                  />
                </div>
                <div className="pt-5 w-[50%] flex flex-col mx-auto">
                  <button
                    disabled={disabled}
                    type="submit"
                    onClick={handleLogin}
                    className={`${organizer ? 'border-2 border-slate-100' : ' border-2 border-white'} py-2 text-white ${organizer ? 'bg-[#00CBB5]' : 'bg-[#A020F0]'} `}>
                    Submit
                  </button>
                </div>
              </div>
              <div style={{ color: "#1e90ff" }} className=" p-5 relative flex justify-center">
                {organizer &&
                  <p onClick={handleForgot} className="cursor-pointer">forgot password ?</p>
                }
              </div>
            </form>
          }
          {(forgot && !forgotOtp) &&
            <div>
              <div className="flex p-5 flex-col gap-14 pt-20">
                <div>
                  <LoginInput
                    organizer={organizer}
                    placeholder="Email"
                    onHandle={handleEmailchange}
                    value={forgotEmailvalue}
                    type="text"
                    name="forgotEmailvalue"
                  />
                </div>
                <div className="pt-5 w-[50%] flex flex-col mx-auto">
                  <button disabled={emailButton} onClick={handleForgotsubmit} className={`border-2 border-slate-100  py-2 text-white bg-[#00CBB5] `}>Send OTP</button>
                </div>
              </div>
              <div>
                <div className=" pb-5 flex justify-center">
                  <button onClick={() => handleBack('home')}
                    className=" text-lg p-1 font-medium flex justify-center items-center text-[#00CBB5] bg-transparent rounded-lg">
                    <span>
                      <IoMdArrowRoundBack className="h-5 w-6" />
                    </span>
                    Back</button>
                </div>
              </div>
            </div>
          }
          {(forgotOtp && !confirmPassword) &&
            <div>
              <div>
                <div className="flex p-5 flex-col gap-14 pt-20">
                  <div>
                    <LoginInput
                      organizer={organizer}
                      placeholder="Enter Your OTP"
                      onHandle={handleEmailchange}
                      value={forgotOtpvalue}
                      type="text"
                      name="forgotOtpvalue"
                    />
                  </div>
                  <div className="pt-5 w-[50%] flex flex-col mx-auto">
                    <button disabled={otpButton} onClick={handleOtpSubmit} className={`border-2 border-slate-100  py-2 text-white bg-[#00CBB5] `}>Submit OTP</button>
                  </div>
                </div>
                <div>
                  <div className="px-3 pb-5 flex justify-center">
                    <button onClick={() => handleBack('emailPage')}
                      className=" text-lg p-1 font-medium flex justify-center items-center text-[#00CBB5] bg-transparent rounded-lg">
                      <span>
                        <IoMdArrowRoundBack className="h-5 w-6" />
                      </span>
                      Back</button>
                  </div>
                </div>
              </div>
            </div>}

          {confirmPassword &&
            <div>
              <div>
                <div className="flex p-5 flex-col gap-14 pt-20">
                  <div>
                    <LoginInput
                      organizer={organizer}
                      placeholder="Enter New Password"
                      onHandle={handleEmailchange}
                      value={newPassword}
                      type="password"
                      name="newPassword"
                    />
                  </div>
                  <div>
                    <LoginInput
                      organizer={organizer}
                      placeholder="Confirm Password"
                      onHandle={handleEmailchange}
                      value={confirmNewPassword}
                      type="password"
                      name="confirmNewPassword"
                    />
                  </div>
                  <div className="pt-5 w-[50%] flex flex-col mx-auto">
                    <button disabled={verifyButton} onClick={handlePasswordsubmit} className={`border-2 border-slate-100  py-2 text-white bg-[#00CBB5] `}>Submit</button>
                  </div>
                  <div>
                    <div className="px-3 pb-5 flex justify-center">
                      <button onClick={() => handleBack('otp')}
                        className=" text-lg p-1 font-medium flex justify-center items-center text-[#00CBB5] bg-transparent rounded-lg">
                        <span>
                          <IoMdArrowRoundBack className="h-5 w-6" />
                        </span>
                        Back</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>}
        </div>
      </div>
      <ToastContainer autoClose={2200} />
    </div>
  );
};
