import makeRequest from "./index";

export const apiGetProfileOrg = (token) => {
  return makeRequest({
    uri: `/organizers/v1/organizers/profile`,
    authorization: token,
  });
};

export const apiUpdateProfileOrg = (token, organizer) => {
  return makeRequest({
    uri: `/organizers/v1/organizers/profile`,
    authorization: token,
    body: JSON.stringify({ organizer }),
    method: "PUT",
  });
};

export const apiRestPasseordOrg = (token, current_password, new_password) => {
  return makeRequest({
    uri: `/organizers/v1/change_password`,
    authorization: token,
    method: "PUT",
    body: JSON.stringify({ new_password, current_password }),
  });
};
