import React, { useEffect, useState } from "react";
import Arrow from "assets/media/downarrow.svg";
import "assets/css/toggleSwitch.css";
import "assets/css/main.css";
import DragBox from "assets/media/dragdrap.svg";
import { Editor } from "@tinymce/tinymce-react";
import { tinyMcKey } from "config.js";
// import DatePicker from "react-datepicker";
// import { addDays } from 'date-fns'
// import "react-datepicker/dist/react-datepicker.css";
import DateImg from "assets/media/date.svg";
import dummy from "assets/media/dummy.jpg";
import { titleize } from "helper";
import { FaCopy } from "react-icons/fa";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";

export const LoginInput = ({
  type,
  placeholder,
  value,
  name,
  onHandle,
  organizer,
}) => {
  return (
    <div className="relative flex gap-3 items-center">
      <input
        type={type}
        className={`w-full outline-none bg-slate-100  border-b-2 py-2  ${
          organizer ? "border-[#00CBB5]" : " border-[#A020F0]"
        }  text-[18px] relative clinput ${value && "bg-white"}`}
        style={{ background: "rgb(241 245 249)" }}
        value={value}
        name={name}
        onChange={onHandle}
        maxLength="70"
      />
      <label
        className={`absolute left-0  ${
          organizer ? "clbel" : "clbal"
        } font-medium ${
          organizer ? "text-[#00CBB5]" : "text-[#A020F0]"
        } autofill:-top-10 hover:top-[-32px] text-[17px] uppercase ${
          value ? "top-[-36px]  text-[#00CBB5]" : "top-0"
        }`}
      >
        {placeholder}
      </label>
    </div>
  );
};

export const ToggleButton = ({ value, name, onHandleChange, tab }) => {
  const [checkedValue, setCheckedValue] = useState(value);
  const handleChecked = () => {
    setCheckedValue(!checkedValue);
  };
  return (
    <label className="switch">
      <input
        type="checkbox"
        name={name}
        checked={value}
        className="checkbtn"
        onChange={(e) => {
          onHandleChange({
            target: {
              name,
              value: e.target.checked,
            },
          });
          handleChecked();
        }}
      />
      <span className={tab ? "sliderTab round" : "slider round"}></span>
    </label>
  );
};

export const DropDown = ({
  value,
  style,
  placeholder,
  option,
  onHandleChange,
  name,
  admin,
}) => {
  const [open, setOpen] = useState(false);
  let selectValue = titleize(value);
  const handleOpen = () => {
    setOpen(!open);
  };
  return (
    <div className={`${style} cursor-pointer`}>
      <p className="text-[16px] font-medium pb-[0.75rem]">{placeholder}</p>
      <div
        className={`bg-[#F7F7FA] h-[52px] hover:border-[#3498FF] focus:border-[#3498FF] border border-solid border-slate-200 rounded-lg py-[15px] px-3 flex justify-between gap-2`}
        onClick={handleOpen}
      >
        <p className=" overflow-hidden flex-1 h-[24px]">{selectValue}</p>
        <img src={Arrow} className="h-3" />
      </div>
      <div className="w-full relative ">
        <ul
          className={` ${
            !open && "hidden"
          } min-h-40 overflow-scroll absolute bg-white w-full shadow-[-1px_-1px_10px_#dcdcdc] z-[1]`}
        >
          {option?.map((value, index) => {
            return (
              <li
                value={value?.value}
                label={value?.lable}
                key={index}
                className={`px-3 py-2 cursor-pointer ${
                  admin ? "hover:bg-[#8854d0]" : "hover:bg-[#42C3AA]"
                }  hover:text-white`}
                onClick={(e) => {
                  onHandleChange({
                    target: {
                      value: e.target?.attributes?.value?.nodeValue,
                      name: name,
                    },
                  });
                  handleOpen();
                }}
              >
                {value?.lable}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export const TextField = ({
  placeholder,
  name,
  value,
  onHandleChange,
  required,
  type,
}) => {
  return (
    <>
      <p className="text-[16px] font-medium pb-[0.75rem]">{placeholder}</p>
      <input
        className="bg-[#F7F7FA] hover:border-[#3498FF] focus:border-[#3498FF] border border-solid border-slate-200 rounded-lg p-3 w-full outline-none"
        name={name}
        value={value}
        required={required}
        maxLength="70"
        onChange={(e) => {
          onHandleChange({
            target: {
              name,
              value: e.target.value,
            },
          });
        }}
        type={type}
      />
    </>
  );
};

export const ToggleField = ({
  leftValue,
  rightValue,
  name,
  onHandleChange,
  value,
  toogles,
}) => {
  const [left, setLeft] = useState(true);
  const [right, setRight] = useState(false);

  useEffect(() => {
    value && fetchValue();
  });

  const fetchValue = () => {
    setLeft(value?.split("_")[0] === leftValue.toLowerCase());
    setRight(value?.split("_")[0] === rightValue.toLowerCase());
  };

  const handleLeft = () => {
    setLeft(true);
    setRight(false);
  };
  const handleRight = () => {
    setRight(true);
    setLeft(false);
  };
  return (
    <div>
      <div>
        <div
          className={` relative bg-white rounded-[30px] border-[#42C3AA] border-2`}
        >
          <div
            className={`${left ? "active" : right ? "inactive" : "active"} btn`}
          ></div>
          <button
            className={`${left ? "text-white" : "text-[#42C3AA]"} ${
              toogles && "relative w-8 text-sm -left-5"
            } py-[10px] px-[40px] cursor-pointer bg-transparent w-[50%] border-0 outline-none relative text-center`}
            onClick={() => {
              handleLeft();
              onHandleChange({
                target: {
                  name: name,
                  value:
                    leftValue.toLowerCase() === "public"
                      ? leftValue.toLowerCase() + "_event"
                      : leftValue.toLowerCase(),
                },
              });
            }}
          >
            {leftValue}
          </button>
          <button
            className={` ${left ? "text-[#42C3AA]" : "text-white"} ${
              toogles && "relative w-8 text-sm -left-4 "
            } py-[10px] px-[40px] cursor-pointer bg-transparent w-[50%] border-0 outline-none relative text-center`}
            onClick={() => {
              handleRight();
              onHandleChange({
                target: {
                  name: name,
                  value:
                    rightValue.toLowerCase() === "private"
                      ? rightValue.toLowerCase() + "_event"
                      : rightValue.toLowerCase(),
                },
              });
            }}
          >
            {rightValue}
          </button>
        </div>
      </div>
    </div>
  );
};

export const DragDrop = ({ placeholder, onHandleChange, name, value }) => {
  const [image, setImage] = useState(value || dummy);
  const uploadUrl = (imgValue) => {
    setImage(URL.createObjectURL(imgValue));
  };
  return (
    <div className="cursor-pointer">
      <span
        className="w-full h-full flex flex-col justify-center items-center relative text-center font-bold leading-[95px] text-[#42C3AA]
      inline-block duration-[0.3s] bg-[#F2F2F2] rounded-[10px] p-5"
      >
        {Object.keys(image).length === 0 ? (
          <>
            <img src={DragBox} className="flex-1" />
            <p className="flex-1">{placeholder}</p>
          </>
        ) : (
          <img src={image} className="w-[300px] h-[200px] object-cover" />
        )}
        <input
          type="file"
          onChange={(e) => {
            onHandleChange({
              target: {
                name: name,
                value: e.target.files[0],
              },
            });
            uploadUrl(e.target.files[0]);
          }}
        />
      </span>
    </div>
  );
};

export const TinyMceField = ({ value, onChange, name, height, width }) => {
  return (
    <Editor
      value={value}
      apiKey={tinyMcKey}
      init={{
        height: `${height}`,
        width: `${width}`,
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "image",
          "charmap",
          "preview",
          "anchor",
          "searchreplace",
          "visualblocks",
          "code",
          "fullscreen",
          "insertdatetime",
          "media",
          "table",
          "help",
          "wordcount",
        ],
        toolbar:
          "undo redo | blocks | " +
          "bold italic backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "removeformat | help",
      }}
      onEditorChange={onChange}
      name={name}
    />
  );
};

export const DateField = ({
  placeholder,
  name,
  value,
  onHandleChange,
  shown,
  handleDateshow,
  handleDateexit,
}) => {
  // const [state, setState] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: addDays(new Date(), 7),
  //     key: 'selection'
  //   }
  // ]);
  // const handleSelect=(ranges)=>{
  //   // {
  //   //   selection: {
  //   //     startDate: [native Date Object],
  //   //     endDate: [native Date Object],
  //   //   }
  //   // }
  // }
  return (
    <>
      <p className="text-[16px] font-medium pb-[0.75rem]">{placeholder}</p>
      <div className="bg-[#F7F7FA] rounded-lg w-full relative">
        {/* <DatePicker
          className="w-full bg-[#F7F7FA] outline-none"
          id={name}
          name={name}
          minDate={new Date()}
          selected={value}
          dateFormat="dd/MM/yyyy"
          onChange={(date) =>
            onHandleChange({
              target: {
                name: name,
                value: moment(date).format("YYYY-MM-DD"),
              },
            })
          }
        /> */}
        <div
          onClick={() =>
            handleDateshow(name === "start_date" ? "show" : "visible", name)
          }
          className="p-4 relative"
        >
          <input
            className="bg-[#F7F7FA] outline-none"
            value={
              name === "start_date"
                ? format(new Date(value?.startDate), "dd-MM-yyyy")
                : format(new Date(value?.endDate), "dd-MM-yyyy")
            }
          />
        </div>
        {shown && (
          <div
            className={`absolute z-30 ${
              name === "end_date" && "-left-96"
            } flex  flex-col items-start`}
          >
            <div>
              <DateRangePicker
                // onChange={item => setState([item.selection])}
                showSelectionPreview={false}
                moveRangeOnFirstSelection={false}
                months={2}
                dateDisplayFormat={"dd mm yyyy"}
                ranges={[value]}
                onChange={(e) => {
                  onHandleChange({
                    target: {
                      events: {
                        start_date: e?.events?.startDate,
                        end_date: e?.events?.endDate,
                      },
                    },
                  });
                }}
                direction="horizontal"
                className=""
              />
            </div>
            <div className="flex justify-center relative bg-slate-100 w-20 p-1 rounded-lg left-80">
              <button onClick={handleDateexit}>Close</button>
            </div>
          </div>
        )}

        <img src={DateImg} className="absolute top-3 right-5" />
      </div>
    </>
  );
};

export const SingleDate = ({ dateRange }) => {
  return (
    <div className="relative">
      <input />
      <DateRangePicker
        showSelectionPreview={false}
        moveRangeOnFirstSelection={false}
        months={1}
        dateDisplayFormat={"dd mm yyyy"}
        ranges={[dateRange]}
        onChange={(e) => {
          onHandleChange({
            target: {
              events: {
                start_week: e?.events?.startDate,
                end_week: e?.events?.endDate,
              },
            },
          });
        }}
        direction="horizontal"
        className="d-block"
      />
    </div>
  );
};

export const EventButton = ({
  placeholder,
  onclick,
  styleProps,
  disable,
  admin,
}) => {
  return (
    <button
      className={
        styleProps +
        ` border-[1px] ${
          admin ? "border-[#A020F0]" : "border-[#42C3AA]"
        }  border-solid px-8 py-2 font-medium hover:bg-white ${
          admin ? "bg-[#A020F0]" : "bg-[#42C3AA]"
        } ${admin ? "hover:text-[#A020F0]" : "hover:text-[#42C3AA]"} ${
          admin ? "text-white" : "text-white"
        } rounded`
      }
      onClick={onclick}
      disabled={disable}
    >
      {placeholder}
    </button>
  );
};

export const EventCode = ({ handleCopy, code }) => {
  return (
    <div
      className="text-[19px] font-bold flex items-center gap-2 cursor-pointer"
      onClick={() => {
        handleCopy(code);
      }}
    >
      <FaCopy style={{ color: "#42C3AA" }} />
      <h1>
        Event Code :
        <span className="text-[17px] text-gray-500">
          {" "}
          {`${code?.substr(0, 4)}-${code?.substr(4, 7)}`}
        </span>
      </h1>
    </div>
  );
};
