import { apiAdminorganizerlist, apiCompanyroles } from "api/event";
import { getCookie, kickUser } from "helper/cookie";
import React, { useEffect, useState } from "react";
import Admincards from "./adminCards";
import { Header } from "./header";
import Skeleton from "./skeleton";
import OrganizerForm from "./organizerForm";
import Empty from "./empty";
import TableCardview from "./tableCardview";
function Adminorganizer({ handleSearchshow, searchName }) {
  const [companyAdmin, setCompanyadmin] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [id, setId] = useState("");
  const [organizerValue, setOrganizer] = useState({
    organizerName: "",
    organizerEmail: "",
    organizerRole: [],
    organizerStauts: "",
  });
  const [roleGroupid, setRole] = useState("");
  const [search, setSearch] = useState({ query: "" });
  const [cardTable, setCardtable] = useState(true);

  let locationId = window.location.pathname.split("/")[3];
  const [show] = useState(true);

  useEffect(() => {
    fetchCompany();
    handleSearchshow(show);
  }, [search]);

  useEffect(() => {
    handleSearch({ target: { value: searchName } });
    return () => {
      handleSearch({ target: { value: "" } });
    };
  }, [searchName]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch((preState) => ({ ...preState, query: value }));
  };
  const fetchCompany = () => {
    apiAdminorganizerlist(getCookie("auth_token"), locationId, search)
      .then(({ data }) => {
        setLoading(false);
        setCompanyadmin(data);
      })
      .catch((err) => {
        if (err[0] === 403 || err[0] === 401) {
          navigate("/admin/login");
          kickUser();
        }
      });
    apiCompanyroles().then(({ data }) => {
      setOrganizer((prestate) => ({ ...prestate, organizerRole: data }));
    });
  };
  const handleReset = () => {
    setOrganizer((prestate) => ({
      ...prestate,
      organizerName: "",
      organizerEmail: "",
      organizerStauts: "",
    }));
    setRole("");
  };
  const handleExit = () => {
    setVisible(false);
    handleReset();
  };

  const handleOrganizerchange = (e) => {
    const { name, value } = e.target;
    name === "roleGroupid"
      ? setRole(value)
      : setOrganizer((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleOrganizercreate = (id, admin, roleid, email, status) => {
    setId(id);
    setOrganizer((preState) => ({
      ...preState,
      organizerName: admin,
      organizerEmail: email,
      organizerStauts: status,
    }));
    setRole(roleid);
    setVisible(true);
  };

  const { organizerRole } = organizerValue;
  const organizerProps = {
    title: id ? "Organizer Edit" : "Organizer Create",
    handleExit,
    handleOrganizerchange,
    organizerValue,
    locationId,
    id,
    roleGroupid: roleGroupid,
    fetchCompany,
    organizerRole,
  };

  if (loading) {
    const row = [];
    for (let i = 0; i < 8; i++) {
      row.push(
        <div key={i} className="flex-[0_0_25%]">
          <Skeleton />
        </div>
      );
    }
    return <div className="flex flex-wrap py-10">{row}</div>;
  }
  const handleToogleright = () => {
    setCardtable(false);
  };
  const handleToogleleft = () => {
    setCardtable(true);
  };
  const headerProps = {
    handleToogleright,
    handleToogleleft,
    cardTable,
  };
  return (
    <div>
      <div>
        <Header
          title={"Organizers"}
          toogle
          companyName={getCookie("companyName")}
          {...headerProps}
          organizer
          handleUser={handleOrganizercreate}
        />
      </div>
      {cardTable ? (
        <div className="flex gap-4 flex-wrap ">
          {companyAdmin.length != 0 ? (
            companyAdmin.map((value, index) => (
              <div key={index}>
                <Admincards
                  name={value.name !== null ? value.name : "-"}
                  status={value.status}
                  email={value.email}
                  adminName={value?.role_group?.name}
                  edit
                  productRole
                  onclick={() =>
                    handleOrganizercreate(
                      value?.id,
                      value?.name,
                      value?.role_group.id,
                      value?.email,
                      value?.status,
                      value?.role_group.name
                    )
                  }
                />
              </div>
            ))
          ) : (
            <Empty no_data />
          )}
        </div>
      ) : (
        <div>
          <TableCardview
            handleOrganizercreate={handleOrganizercreate}
            cardOrganizerloop={companyAdmin}
          />
        </div>
      )}
      {visible && <OrganizerForm {...organizerProps} />}
    </div>
  );
}
export default Adminorganizer;
