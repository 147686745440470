function getCookie(name) {
  const cookie = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return cookie && cookie[2];
}

function setCookie(name, value, days = 0, hours = 0) {
  const date = new Date();
  if (days || hours) {
    date.setTime(
      date.getTime() + hours * 60 * 60 * 1000 + 24 * 60 * 60 * 1000 * days
    );
    document.cookie =
      name + "=" + value + ";path=/;expires=" + date.toGMTString();
  } else {
    document.cookie = name + "=" + value + ";path=/";
  }
}

const deleteAllCookie = () => {
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};
function delete_cookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

function kickUser() {
  window.location.href = `/${getCookie("path")}/login`
  deleteAllCookie();
  localStorage.removeItem('roles')
}

export { getCookie, setCookie, delete_cookie, kickUser };
