import { titleize } from "helper";
import React, { useEffect, useState } from "react";
import partiImg from "assets/media/participant.svg";
import cal from "assets/media/calender.svg";
import calImg from "assets/media/cal_img.svg";
import moment from "moment";
import dummy from "assets/media/dummy.jpg";
import ongoing from "assets/media/orange 1.svg"
import completed from "assets/media/gray 1.svg"
let eventContent;

export const PreviewPage = ({ onHandle, data }) => {
  const [bg, setBg] = useState('')
  const [image, setImage] = useState('')
  useEffect(() => {
    if (moment() <= moment(data?.start_date)) {
      eventContent = 'Event starting in'
      setBg('#A7D8CF')
      setImage(calImg)
    } else {
      if (moment(data?.end_date) >= moment()) {
        eventContent = "Ongoing event"
        setBg('#FFCEB8')
        setImage(ongoing)
      } else {
        eventContent = "Events Completed"
        setBg('#E2E2E2')
        setImage(completed)
      }
    }
  }, [eventContent, bg, image])

  return (
    <div
      className="w-full h-full z-[9] absolute top-0 left-0 flex justify-center items-center font-['Nunito_Sans']"
      style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
    >
      <div className="h-[660px] w-[350px] bg-[#B8AFE6] relative flex justify-center items-center rounded-[35px] shadow-[30px_30px_29px_#333]">
        <div className="h-[650px] w-[340px] bg-black rounded-[30px] flex justify-center items-center">
          <div className="h-[640px] w-[330px] bg-gray-500 rounded-[30px] flex flex-col">
            <div className="h-[40px] w-[4px] rounded-tl-[16px] rounded-bl-[16px] bg-[#B8AFE6] absolute top-[130px] left-[-4px] bg-black"></div>
            <div className="h-[60px] w-[4px] rounded-tl-[16px] rounded-bl-[16px] bg-[#B8AFE6] absolute top-[190px] left-[-4px] bg-black"></div>
            <div className="h-[60px] w-[4px] rounded-tl-[16px] rounded-bl-[16px] bg-[#B8AFE6] absolute top-[260px] left-[-4px] bg-black"></div>
            <div className="h-[80px] w-[4px] rounded-tr-[16px] rounded-br-[16px] bg-[#B8AFE6] absolute top-[160px] right-[-4px] bg-black"></div>
            <div
              className="h-[25px] w-[140px] z-[2] bg-black absolute top-[10px] left-[50%] translate-x-[-50%] rounded-br-[16px] rounded-bl-[16px] flex justify-center after:content-[''] after:block after:h-[4px] after:w-[80px] after:bg-[#333] after:rounded-lg
            before:content-[''] before:block before:h-[13px] before:w-[13px] before:bg-[#333] before:rounded-[20px] before:mt-[5px] before:ml-[-20px]"
            ></div>
            <div
              className="absolute top-[16px] z-[2] right-[34px] cursor-pointer"
              onClick={onHandle}
            >
              <span className="rounded-full bg-[#dcdcdc] px-3 py-1 text-[12px] font-medium">
                Close
              </span>
            </div>

            <div
              className="bg-white w-full h-full overflow-scroll border-2 text-left rounded-[30px]"
              style={{ scrollbarWidth: "none" }}
            >
              <p className="bg-white py-3 px-2 sticky top-0 font-bold text-[19px]">
                Runji
              </p>
              <div className="px-3 flex flex-col gap-10 pb-8">
                <div>
                  <img
                    src={
                      data.banner_images ? data?.banner_images[0]?.url : dummy
                    }
                    className="rounded-2xl w-[300px] h-[200px]"
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex gap-2 justify-between">
                    <h1 className="text-[18px] font-medium">{data?.title}</h1>
                    <div className="rounded-full bg-[#E1F7E3] text-center px-5 py-1">
                      <span className="text-[#429D4A] font-normal text-[14px]">
                        {titleize(data?.plan_type?.split("_")[0])} Event
                      </span>
                    </div>
                  </div>
                  {data?.event_detail?.description && (
                    <div className="break-words text-[#989898] text-justify">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data?.event_detail?.description,
                        }}
                      />
                      <p className="text-[#2B2C43] font-normal text-[13px] pt-2">
                        Read More
                      </p>
                    </div>
                  )}
                </div>
                {data?.event_setting?.max_participants && (
                  <div className="flex justify-between">
                    <div>
                      <p className="text-[14px] font-medium">
                        {data?.event_setting?.max_participants} people joined
                      </p>
                    </div>
                    <div>
                      <img src={partiImg} />
                    </div>
                  </div>
                )}
                <div>
                  <div style={{ background: bg }} className={`p-3 rounded-lg flex  items-center justify-between gap-5 shadow-[2px_2px_9px_#f0dcf5]`}>

                    <div>
                      <div className="flex flex-col gap-2 justify-between">
                        <div className="flex gap-0 items-center">
                          <h1 className="font-semibold text-[14px] text-[#2B2C43]">
                            {eventContent}
                          </h1>
                          <img src={cal} className="h-3" />
                        </div>
                        <h2 className={`${eventContent && "text-orange-900"} text-white font-bold text-[15px]`}>
                          {moment(data?.end_date).diff(
                            moment(data?.start_date),
                            "days"
                          )}{" "}
                          Days Left
                        </h2>
                        <h1 className={`${eventContent && "pt-5 text-slate-500"} text-[13px]`}>
                          {moment(data?.start_date).format("DD-MMM-YYYY")}
                        </h1>
                      </div>
                    </div>
                    <div className="pt-2 flex justify-between h-32 w-32">
                      <img src={image} />
                    </div>
                  </div>
                </div>
                {/* <div>
                  <div className="rounded-xl shadow-[2px_2px_9px_#dcdcdc] px-2">
                    <div>
                      <img
                        src={data.title_image ? data?.title_image?.url : dummy}
                        className="rounded-2xl py-2 w-[300px] h-[200px]"
                      />
                    </div>
                    <div>
                      <h1 className="font-semibold">{data?.title}</h1>
                      {data?.start_date && data?.end_date && (
                        <h1 className="text-[14px] text-[#868686]">
                          {moment(data?.start_date).format("DD-MMM-YYYY")} -{" "}
                          {moment(data?.end_date).format("DD-MMM-YYYY")}
                        </h1>
                      )}
                    </div>
                    <div className="py-5">
                      <span className="bg-[#43AF9E] rounded px-4 py-2 text-white">
                        Register Now
                      </span>
                    </div>
                  </div>
                </div> */}
                {data?.event_detail?.awards && (
                  <div className="pb-2">
                    <h1 className="pb-4 font-medium">Awards</h1>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data?.event_detail?.awards,
                      }}
                    />
                  </div>
                )}
                {data?.event_detail?.rules && (
                  <div className="pb-4">
                    <h1 className="pb-4 font-medium">Rules</h1>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data?.event_detail?.rules,
                      }}
                    />
                  </div>
                )}
                {data?.event_detail?.sponsors && (
                  <div>
                    <h1>Sponsors</h1>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data?.event_detail?.sponsors,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
