import { Headers } from "data/eventData";
import React from "react";
import editIcon from "assets/media/edit-icon.svg";

function TableCardview({
  cardLoop,
  companyCardloop,
  cardOrganizerloop,
  handleEdit,
  handleMove,
  handleUser,
  handleOrganizercreate,
}) {
  return (
    <div>
      <table className="w-[100%]">
        <tr>
          {cardLoop && (
            <th className="bg-[#42C3AA] shadow-sm text-white">
              {Headers.map((value, index) => {
                return (
                  <div key={index} className="p-5 flex items-center text-md">
                    <td className="flex font-['Nunito_Sans'] justify-start">
                      {value?.adminName}
                    </td>
                    <td className="flex  font-['Nunito_Sans'] justify-start">
                      {value?.adminEmail}
                    </td>
                    <td className="flex font-['Nunito_Sans'] justify-center">
                      {value?.adminStatus}
                    </td>
                    <td className="flex font-['Nunito_Sans'] justify-center  ">
                      {value?.adminRole}
                    </td>
                    <td className="flex font-['Nunito_Sans'] justify-center  ">
                      {value?.companyEdit}
                    </td>
                  </div>
                );
              })}
            </th>
          )}
          {companyCardloop && (
            <th className="bg-[#A020F0] shadow-sm text-white">
              {Headers.map((value, index) => {
                return (
                  <div key={index} className="p-5 flex items-center text-md">
                    <td className="flex font-['Nunito_Sans'] justify-start">
                      {value?.companyName}
                    </td>
                    <td className="flex  relative -left-8 font-['Nunito_Sans'] justify-center">
                      {value?.companyEmail}
                    </td>
                    <td className="flex relative left-0 font-['Nunito_Sans'] justify-center">
                      {value?.companyStatus}
                    </td>
                    <td className="flex relative left-0 font-['Nunito_Sans'] justify-center  ">
                      {value?.companyProduct}
                    </td>
                    <td className="flex relative left-0 font-['Nunito_Sans'] justify-center  ">
                      {value?.companyEdit}
                    </td>
                  </div>
                );
              })}
            </th>
          )}
          {cardOrganizerloop && (
            <th className="bg-[#A020F0] shadow-sm text-white">
              {Headers.map((value, index) => {
                return (
                  <div key={index} className="p-5 flex items-center text-md">
                    <td className="flex font-['Nunito_Sans'] justify-start">
                      {value?.adminName}
                    </td>
                    <td className="flex  font-['Nunito_Sans'] justify-start">
                      {value?.adminEmail}
                    </td>
                    <td className="flex font-['Nunito_Sans'] justify-start">
                      {value?.adminStatus}
                    </td>
                    <td className="flex font-['Nunito_Sans'] justify-start  ">
                      {value?.adminRole}
                    </td>
                    <td className="flex font-['Nunito_Sans'] justify-start  ">
                      {value?.companyEdit}
                    </td>
                  </div>
                );
              })}
            </th>
          )}
        </tr>
        {cardLoop && (
          <tr>
            {cardLoop.map((value, index) => {
              const even = index % 2 === 1;
              return (
                <div
                  key={index}
                  className={`text-black ${
                    even ? "bg-white" : "bg-slate-50"
                  } font-medium p-3 font-['Nunito_Sans'] flex hover:bg-slate-100 cursor-pointer`}
                >
                  <td className="flex font-['Nunito_Sans'] justify-start">
                    {value.name !== null ? value.name : "-"}
                  </td>
                  <td className="flex  font-['Nunito_Sans'] justify-start">
                    {value.email !== null ? value.email : "-"}
                  </td>
                  <td className="flex font-['Nunito_Sans'] justify-center">
                    {value.status !== null ? value.status : "-"}
                  </td>
                  <td className="flex font-['Nunito_Sans'] justify-center">
                    {/* {DateTime(value?.last_login) ? DateTime(value?.last_login) : '-'} */}
                    {value?.role_group.name ? value?.role_group.name : "-"}
                  </td>
                  <td className="flex relative  font-['Nunito_Sans'] justify-center">
                    <button onClick={() => handleUser(value)}>
                      <img src={editIcon} alt="edit" className="h-5" />
                    </button>
                  </td>
                </div>
              );
            })}
          </tr>
        )}
        {companyCardloop && (
          <tr>
            {companyCardloop.map((value, index) => {
              const even = index % 2 === 1;
              return (
                <div
                  key={index}
                  className={`text-black ${
                    even ? "bg-white" : "bg-slate-50"
                  } font-medium p-3 font-['Nunito_Sans'] flex hover:bg-slate-100 cursor-pointer`}
                >
                  <td
                    onClick={() => handleMove(value?.id, value?.name)}
                    className="flex font-['Nunito_Sans'] justify-start"
                  >
                    {value.name !== null ? value.name : "-"}
                  </td>
                  <td
                    onClick={() => handleMove(value?.id, value?.name)}
                    className="flex  relative  font-['Nunito_Sans'] justify-start left-10"
                  >
                    {value?.admins[0].email}
                  </td>
                  <td
                    onClick={() => handleMove(value?.id, value?.name)}
                    className="flex relative left-0 font-['Nunito_Sans'] justify-center"
                  >
                    {value.status !== null ? value.status : "-"}
                  </td>
                  <td
                    onClick={() => handleMove(value?.id, value?.name)}
                    className={`flex relative left-0  font-['Nunito_Sans'] text-[14px] items-center font-semibold  justify-center`}
                  >
                    <p
                      className={`${
                        value?.subscription?.product.name === "Bronze"
                          ? "bg-[#9155FD]"
                          : value?.subscription?.product.name === "Platinum" &&
                            "bg-[#56CA00]"
                      } ${
                        value?.subscription?.product.name === "Diamond" &&
                        " bg-[#16B1FF]"
                      }
                                   p-1 px-4 rounded-full text-white
                                   `}
                    >
                      {value?.subscription?.product.name
                        ? value?.subscription?.product.name
                        : "-"}
                    </p>
                  </td>
                  <td className="flex relative left-0 font-['Nunito_Sans'] justify-center">
                    <button
                      onClick={() =>
                        handleEdit(value?.id, value?.name, value?.status)
                      }
                    >
                      <img src={editIcon} alt="edit" className="h-5" />
                    </button>
                  </td>
                </div>
              );
            })}
          </tr>
        )}
        {cardOrganizerloop && (
          <tr>
            {cardOrganizerloop.map((value, index) => {
              const even = index % 2 === 1;
              return (
                <div
                  key={index}
                  className={`text-black ${
                    even ? "bg-white" : "bg-slate-50"
                  } font-medium p-3 font-['Nunito_Sans'] flex hover:bg-slate-100 cursor-pointer`}
                >
                  <td className='flex font-["Nunito_Sans"] justify-start'>
                    {value?.name !== null ? value?.name : "-"}
                  </td>
                  <td className="flex  font-['Nunito_Sans'] justify-start">
                    {value?.email ? value?.email : "-"}
                  </td>
                  <td className="flex font-['Nunito_Sans'] justify-start">
                    {value?.status ? value?.status : "-"}
                  </td>
                  <td className="flex font-['Nunito_Sans'] justify-start ">
                    {value?.role_group?.name ? value?.role_group?.name : "-"}
                  </td>
                  <td className="flex font-['Nunito_Sans'] justify-start  ">
                    <div
                      onClick={() =>
                        handleOrganizercreate(
                          value?.id,
                          value?.name,
                          value?.role_group.id,
                          value?.email,
                          value?.status,
                          value?.role_group.name
                        )
                      }
                      className="cursor-pointer border-slate-500 border-solid border h-8 flex justify-center items-center p-2"
                    >
                      <button>Edit</button>
                    </div>
                  </td>
                </div>
              );
            })}
          </tr>
        )}
      </table>
    </div>
  );
}

export default TableCardview;
