import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Header } from "./header";
import "../assets/css/main.css";
import { useNavigate } from "react-router-dom";
import { apiAdminUpdateteam, apiCreateteam, apiUpdateteam } from "api/event";
import { getCookie } from "helper/cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastStyle } from "helper";
import { DropDown, TextField } from "./formField";
import { IoClose } from "react-icons/io5";

function Teamform({
  teamId,
  handleExit,
  valueCreate,
  handleChange,
  eventId,
  fetchTeam,
  visible,
  title,
  loginType,
  comId,
  disabled,
  setDisabled
}) {
  const [toastMessage, setToastMessage] = useState({ message: "", type: "" });
  let navigate = useNavigate();

  useEffect(() => {
    if (toastMessage.type !== "") {
      notify();
    }
    setDisabled(true)
  }, [toastMessage]);

  const handleSubmit = () => {
    const action = teamId ? apiUpdateteam : apiCreateteam;
    const id = teamId ? teamId : eventId;
    if (loginType === "admin") {
      apiAdminUpdateteam(getCookie("auth_token"), comId, id, { ...valueCreate })
        .then(({ message }) => {
          setDisabled(false);
          setToastMessage({
            message,
            type: "success",
          });
          setTimeout(() => {
            fetchTeam();
            handleExit("visible");
          }, 3000);
        })
        .catch((err) => {
          setTimeout(() => {
            setDisabled(false);
          }, 4000);
          if (err[0] === 403 || err[0] === 401) {
            navigate("/admin/login");
          }
          setToastMessage({
            message: err[1].data[0],
            type: "error",
          });
        });
    } else {
      action(getCookie("auth_token"), id, { ...valueCreate })
        .then(({ message }) => {
          setDisabled(false);
          setToastMessage({
            message,
            type: "success",
          });
          setTimeout(() => {
            fetchTeam();
            handleExit("visible");
          }, 3000);
        })
        .catch((err) => {
          toast.error(err?.[1]?.data?.response)
          setTimeout(() => {
            setDisabled(false);
          }, 1000);
          if (err[0] === 403 || err[0] === 401) {
            navigate("/organizers/login");
          }
          setToastMessage({
            message: err[1].data[0],
            type: "error",
          });
        });
    }
  };
  const notify = () => {
    if (toastMessage.type === "error") {
      toast.error(toastMessage.message, toastStyle);
    }
    if (toastMessage.type === "success") {
      toast.success(toastMessage.message, toastStyle);
    }
  };
  const statusOption = [
    {
      value: "active",
      lable: "Active",
    },
    {
      value: "inactive",
      lable: "Inactive",
    },
  ];

  const {
    teams: { name, description, status },
  } = valueCreate;

  return (
    <div>
      <div
        style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
        className="w-full h-full z-[2] absolute top-0 left-0 flex justify-center items-center "
      >
        <div className="flex flex-col flex-[0.2] bg-white p-6 ">
          <div className="flex justify-between flex-[0.2]">
            <div>
              <Header title={title} />
            </div>

            <div
              onClick={() =>
                visible ? handleExit("visible") : handleExit("show")
              }
            >
              <IoClose className="h-10 w-8 cursor-pointer" />
            </div>
          </div>

          <div className="flex flex-col flex-[0.2] gap-10">
            <div className="flex flex-1 items-center gap-5">
              <div className="flex flex-col flex-1">
                <TextField
                  name="name"
                  onHandleChange={handleChange}
                  value={name}
                  required={true}
                  placeholder={"Team Name"}
                />
              </div>

              <div className="flex flex-col flex-1">
                <DropDown
                  placeholder={"Status"}
                  option={statusOption}
                  name={"status"}
                  value={status}
                  onHandleChange={handleChange}
                />
              </div>
            </div>

            <div className="flex flex-col gap-11  flex-[0.2] overflow-scroll">
              <div className="flex flex-col  flex-[0.2] ">
                <label className="font-medium">Description</label>
                <textarea
                  onChange={handleChange}
                  value={description}
                  className="border-2 rounded-xl border-solid outline-none p-2 "
                  name="description"
                  rows="6"
                  required={true}
                  cols="77"
                ></textarea>
              </div>
            </div>

            <div className="flex justify-end gap-2 ">
              <div
                onClick={() => handleExit("visible")}
                className="border  border-solid  hover:bg-[#42C3AA] hover:text-white hover:border-solid cursor-pointer
                        border-[#42C3AA]  flex flex-[0.2] text-[#42C3AA] justify-center p-2"
              >
                <p>Cancel</p>
              </div>
              <div
                onClick={disabled ? handleSubmit : null}
                className={
                  disabled
                    ? ` bg-[#42C3AA] flex flex-[0.2]
                                 cursor-pointer hover:text-[#42C3AA] hover:bg-white hover:border hover:border-solid
                                  hover:border-[#42C3AA]  justify-center text-white p-2`
                    : ` disabled:bg-slate-200 flex flex-[0.2]
                                  cursor-pointer text-slate-300 justify-center p-2 border border-slate-200 border-solid`
                }
              >
                <p>Submit</p>
              </div>
              <ToastContainer
                autoClose={2200}
                style={{ fontWeight: "normal" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Teamform;
