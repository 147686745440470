import React, { useEffect, useState } from "react";
import { Headers, Sports } from "data/eventData";
import "../assets/css/main.css";
import { useLocation } from "react-router-dom";
import Tableview from "./tableView";
import { useNavigate } from "react-router-dom";
import {
  apiAdminTeamlist,
  apiEventParticipantslist,
  apiOrgParticipantsDet,
  apiParticipantsDet,
  apiParticipantslist,
  apiTeamlist,
  // apiRemoveParticipant,
} from "api/event";
import { getCookie } from "helper/cookie";
import Empty from "./empty";
import Teamform from "./teamForm";
import Pagination from "./pagination";
// import TableForm from './tableForm';
import { MdRemoveRedEye } from "react-icons/md";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { RiEdit2Fill } from "react-icons/ri";
import { titleize } from "helper";
import { EventButton } from "./formField";
// import { ToggleButton } from "./formField";
import "react-toastify/dist/ReactToastify.css";
import ParticepantDets from "./particepantDets";
import { toast, ToastContainer } from "react-toastify";
import { toastStyle } from "helper";
import "react-toastify/dist/ReactToastify.css";

function Participants({ handleSearchshow }) {
  const [data, setData] = useState([]);
  const [participantslist, setParticipantlist] = useState([]);
  const [event, setEvent] = useState(false);
  const [pagination, setPagination] = useState({ perPage: 25, page: 1 });
  const [participantPage, setParticipantpage] = useState({
    per_page: 25,
    pageValue: 1,
  });
  const [participant, setParticipant] = useState(false);
  const [visible, setVisible] = useState(false);
  const [view, setView] = useState(false);
  const [show] = useState(false);
  const [eventName] = useState(true);
  const [valueCreate, setValue] = useState({
    teams: { name: "", description: "", status: "active" },
  });
  const [teamId, setShowvalue] = useState("");
  const [id, setId] = useState("");
  const [total_entries, setTotal] = useState(0);
  const [totalEntries, setTotalentries] = useState(0);
  const eventId =
    window.location.pathname.split("/")[4] === "events"
      ? window.location.pathname.split("/")[5]
      : window.location.pathname.split("/")[4];
  const location = useLocation();
  const [tableViewdata, setTable] = useState({
    tableData: false,
    image: "",
    participantName: "",
    eventTableid: "",
    mobileNo: "",
    countryCode: "91",
  });
  const [particepantDet, setParticipantDet] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    handleSearchshow(show, eventId, eventName);
    fetchTeam();
    fetchParticipant();
  }, [pagination, participant, event]);
  let navigate = useNavigate();

  const fetchTeam = () => {
    if (window.location.pathname.split("/")[4] === "events") {
      apiAdminTeamlist(
        getCookie("auth_token"),
        window.location.pathname.split("/")[3],
        eventId,
        pagination
      )
        .then(({ data, total_entries }) => {
          setData(data);
          setTotal(total_entries);
        })
        .catch((err) => {
          if (err[0] === 403 || err[0] === 401) {
            navigate("/admin/login");
          }
        });
    } else {
      apiTeamlist(getCookie("auth_token"), eventId, pagination)
        .then(({ data, total_entries }) => {
          setData(data);
          setTotal(total_entries);
        })
        .catch((err) => {
          if (err[0] === 403 || err[0] === 401) {
            navigate("/organizers/login");
          }
        });
    }
  };
  const fetchParticipant = () => {
    if (window.location.pathname.split("/")[4] === "events") {
      apiEventParticipantslist(
        getCookie("auth_token"),
        window.location.pathname.split("/")[3],
        eventId,
        participantPage
      )
        .then(({ data, total_entries }) => {
          setParticipantlist(data);
          setTotalentries(total_entries);
        })
        .catch((err) => {
          if (err[0] === 403 || err[0] === 401) {
            navigate("/organizers/login");
          }
        });
    } else {
      apiParticipantslist(getCookie("auth_token"), eventId, participantPage)
        .then(({ data, total_entries }) => {
          setParticipantlist(data);
          setTotalentries(total_entries);
        })
        .catch((err) => {
          if (err[0] === 403 || err[0] === 401) {
            navigate("/organizers/login");
          }
        });
    }
  };
  const handleClick = () => {
    setEvent(false);
    setParticipant(false);
  };
  const handleManage = () => {
    setEvent(true);
    setParticipant(true);
  };
  const handlePagination = (page) => {
    setPagination((preState) => ({
      ...preState,
      page: page,
    }));
    fetchTeam();
  };

  const handleParticipantpage = (page) => {
    setParticipantpage((preState) => ({
      ...preState,
      pageValue: page,
    }));
    fetchParticipant();
  };

  const handleCreate = (value) => {
    setVisible(true);
    setShowvalue(value.id);
    setValue({
      teams: {
        name: value?.name,
        description: value?.description,
        status: value?.status,
      },
    });
  };

  const handleChange = (e) => {
    setDisabled(true)
    const { name, value } = e.target;
    setValue((prevState) => ({
      ...prevState,
      teams: { ...prevState.teams, [name]: value },
    }));
  };
  const handleTablechange = (e) => {
    const { name, value } = e.target;
    setTable((preState) => ({ ...preState, [name]: value }));
  };
  const handleView = (value) => {
    setView(true);
    setId(value.id);
    setTable((preState) => ({
      ...preState,
      tableData: true,
      image: value?.img_url,
      participantName: value?.name,
      eventTableid: value?.id,
    }));
  };
  const handleExit = (show) => {
    show === "visible" ? setVisible(false) : setView(false);
    show === "show" && setShow({ shown: false });
    setTable(false);
    setTable((preState) => ({
      ...preState,
      tableData: false,
    }));
    setView(false);
  };
  // const triggerToggle = (e) => {
  //   const { value, name } = e.target;
  //   let params = {
  //     participant_ids: [name],
  //     status: value ? "joined" : "removed",
  //   };
  //   apiRemoveParticipant(getCookie("auth_token"), eventId, params)
  //     .then(({ message }) => {
  //       toast.success(message, toastStyle);
  //       fetchParticipant();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const loginType =
    window.location.pathname.split("/")[4] === "events" && "admin";
  const comId =
    window.location.pathname.split("/")[4] === "events" &&
    window.location.pathname.split("/")[3];
  const handleReset = () => {
    setTable((preState) => ({ ...preState, mobileNo: "", countryCode: "91" }));
  };

  const detailsModal = (value) => {
    if (!loginType) {
      apiOrgParticipantsDet(getCookie("auth_token"), eventId, value.id)
        .then(({ data }) => {
          setParticipantDet(data);
          setModalOpen(true);
        })
        .catch((err) => {
          console.log(err);
          toast.success(err[1].message, toastStyle);
        });
    } else {
      apiParticipantsDet(getCookie("auth_token"), comId, eventId, value.id)
        .then(({ data }) => {
          setParticipantDet(data);
          setModalOpen(true);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err[1].message, toastStyle);
        });
    }
  };

  const modalClose = () => {
    setModalOpen(!modalOpen);
  };
  const createProps = {
    teamId,
    valueCreate,
    visible,
    eventId,
    handleChange,
    handleExit,
    fetchTeam,
    title: teamId ? "Update Form" : "Create Team",
    loginType,
    comId,
    disabled,
    setDisabled
  };
  const { mobileNo, countryCode } = tableViewdata;
  const tableProps = {
    id,
    handleExit,
    tableViewdata,
    mobileNo,
    countryCode,
    handleTablechange,
    fetchTeam,
    fetchParticipant,
    handleReset,
    loginType,
    comId,
  };

  const detailsProps = {
    modalClose,
    particepantDet,
    comId,
    eventId,
    fetchParticipant,
    loginType,
  };

  let url = window.location.toString().split("/")

  return (
    <div>
      {modalOpen && <ParticepantDets {...detailsProps} />}
      <div className=" w-[100%]">
        <div className=" p-6 h-24 flex justify-between items-center">
          <div className="flex  gap-14">
            <h1
              className={
                !participant
                  ? "text-[#42C3AA] duration-200 text-[21px]  font-bold border-b-[3px] border-[#42C3AA] border-solid"
                  : "font-bold text-[21px] cursor-pointer"
              }
              onClick={handleClick}
            >
              Team
            </h1>
            <h1
              className={
                participant
                  ? "text-[#42C3AA] duration-200  text-[21px] font-bold border-b-[3px] border-[#42C3AA] border-solid"
                  : "font-bold text-[21px] cursor-pointer"
              }
              onClick={handleManage}
            >
              Participant
            </h1>
          </div>
          <div>
            {
              (loginType !== "admin" && !participant) && (
                <EventButton
                  placeholder="+ Create Team"
                  onclick={handleCreate}
                />
              )
              // {/* : null */}
            }
          </div>
        </div>

        {event && (
          <div>
            <table className="w-[100%]">
              <tr className="bg-[#42C3AA] shadow-sm text-white">
                <th>
                  {Headers.map((value, index) => (
                    <div
                      key={index}
                      className="p-5 flex justify-between items-center text-md"
                    >
                      <td className="flex font-['Nunito_Sans'] justify-start">
                        {value.participants}
                      </td>
                      <td className="flex  font-['Nunito_Sans'] justify-center">
                        {value.email}
                      </td>
                      <td className="flex font-['Nunito_Sans'] justify-start">
                        {value.teamname}
                      </td>
                      <td className="flex font-['Nunito_Sans'] justify-center">
                        {value.status}
                      </td>
                      <td className="flex font-['Nunito_Sans'] justify-center">
                        {value.manage}
                      </td>
                    </div>
                  ))}
                </th>
              </tr>

              {participantslist.length === 0 ? (
                <Empty no_data />
              ) : (
                participantslist.map((value, i) => {
                  const even = i % 2 !== 1;
                  return (
                    <tr
                      key={i}
                      className={`
                        ${even ? "bg-white" : "bg-[#F5F5F5]"}
                       text-black font-medium`}
                    >
                      <div className="px-5 py-3 shadow-sm flex justify-between items-center">
                        <td className="flex  justify-start">
                          <div className='flex items-center gap-3 font-["Nunito_Sans"]'>
                            <img
                              src={value?.img_url}
                              className="h-[40px] w-[40px] bg-slate-200 rounded-full p-1"
                            />
                            <div>
                              <h2 className="font-bold">
                                {titleize(value?.name ? value?.name : "-")}
                              </h2>
                              {url[3] === "admin" &&
                                <div className="flex gap-4">
                                  <p className="text-[13px] font-semibold text-[#626262]"

                                  >
                                    {value.country_code && value.phone ? "+" : null}
                                    {value?.country_code}{" "}
                                    {value?.phone ? value?.phone.slice(0, 4) + "*".repeat(value.phone.length - 4) : "-"}

                                  </p>
                                  {value?.phone && <BsFillInfoCircleFill
                                    data-te-toggle="tooltip"
                                    title={`${value?.country_code} ${value?.phone}`}
                                    data-te-placement="right"
                                    data-te-ripple-color="light"
                                    className="text-[#42C3AA] text-md cursor-pointer" />}
                                </div>
                              }
                            </div>
                          </div>
                        </td>
                        <td className="flex  justify-center">
                          {value?.email ? value?.email : "-"}
                        </td>
                        <td className="flex justify-start">
                          <div className="flex gap-3 items-center">
                            {value?.team?.img_url && (
                              <img
                                src={value?.team?.img_url}
                                className="h-[40px] w-[40px] object-cover bg-slate-200 rounded-full p-[2px]"
                              />
                            )}
                            <h3>
                              {value?.team?.name
                                ? titleize(value?.team?.name)
                                : "-"}
                            </h3>
                          </div>
                        </td>
                        <td className="flex justify-center">
                          <div className="flex gap-3 items-center">
                            <p className="text-[13px] font-semibold text-[#626262]">
                              {value?.status}
                            </p>
                          </div>
                        </td>
                        <td className="flex justify-center">
                          <div className="flex gap-3 items-center">
                            <MdRemoveRedEye
                              className="text-[#42C3AA] text-2xl cursor-pointer"
                              onClick={() => detailsModal(value)}
                            />
                          </div>
                        </td>
                      </div>
                    </tr>
                  );
                })
              )}
            </table>
            <ToastContainer autoClose={2200} style={{ fontWeight: "normal" }} />
          </div>
        )}

        <div className="flex justify-end">
          {event && totalEntries !== 0 && location.state.value === true && (
            <Pagination
              perPage={participantPage?.per_page}
              total_entries={totalEntries}
              handlePagination={handleParticipantpage}
            />
          )}
        </div>

        {!event && (
          <div>
            <table className="w-[100%]">
              <tr className='bg-[#42C3AA] shadow-sm text-white font-bold text-lg font-["Nunito_Sans"]'>
                <th>
                  {Sports.map((value, index) => (
                    <div
                      key={index}
                      className="p-5 flex justify-between items-center"
                    >
                      <td className="flex justify-start">
                        {value.participants}
                      </td>
                      <td className="flex justify-center">
                        {value.participantCount}
                      </td>
                      <td className="flex justify-center">
                        <div>{value.status}</div>
                      </td>
                      <td className="flex  justify-center">{value.manage}</td>
                    </div>
                  ))}
                </th>
              </tr>
              {data.length !== 0 ? (
                data.map((value, i) => {
                  const even = i % 2 === 1;
                  return (
                    <tr
                      key={i}
                      className={`
                        ${even ? "bg-white" : "bg-[#F5F5F5]"}
                      py-3 text-black font-medium flex shadow-sm bg-white justify-between items-center px-5 h-16 font-['Nunito_Sans']`}
                    >
                      <td className="flex justify-start">
                        <div className="flex justify-start items-center gap-3">
                          <img
                            src={value?.img_url}
                            className="h-[40px] w-[40px] bg-slate-200 rounded-full p-1"
                          />
                          <h2 className="font-bold">{value.name}</h2>
                        </div>
                      </td>
                      <td className="flex justify-center">
                        {value.participants_count
                          ? value.participants_count
                          : "0"}
                      </td>
                      <td className={`flex justify-center rounded-lg`}>
                        <div
                          className={`
                              ${value.status === "active"
                              ? "bg-[#4ad082]"
                              : "bg-red-500"
                            }
                              text-md  flex justify-center px-4 font-bold rounded-[12px] text-white`}
                        >
                          {titleize(value.status)}
                        </div>
                      </td>
                      <td className="flex gap-3 justify-center">
                        <MdRemoveRedEye
                          className="text-[#42C3AA] text-2xl cursor-pointer"
                          onClick={() => handleView(value)}
                        />
                        <RiEdit2Fill
                          className="text-[#42C3AA] text-2xl cursor-pointer"
                          onClick={() => handleCreate(value)}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <Empty no_data />
              )}
            </table>
          </div>
        )}

        {visible && <Teamform {...createProps} />}
        {view && <Tableview {...tableProps} />}
      </div>
      <div className="flex justify-end pt-5">
        {!event && total_entries !== 0 && location.state.value === true && (
          <Pagination
            perPage={pagination?.perPage}
            total_entries={total_entries}
            handlePagination={handlePagination}
          />
        )}
      </div>
    </div>
  );
}

export default Participants;
