import makeRequest from "./index";

export const apiGetProfile = (token) => {
  return makeRequest({
    uri: `/admins/v1/admins/profile`,
    authorization: token,
  });
};

export const apiProfileUpdate = (token, admin) => {
  return makeRequest({
    uri: `/admins/v1/admins/profile`,
    authorization: token,
    method: "PUT",
    body: JSON.stringify({ admin }),
  });
};

export const apiRestPasseord = (token, current_password, new_password) => {
  return makeRequest({
    uri: `/admins/v1/change_password`,
    authorization: token,
    method: "PUT",
    body: JSON.stringify({ new_password, current_password }),
  });
};
