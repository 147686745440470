import React, { useEffect, useState } from "react";
import { Header } from "./header";
import "assets/css/main.css";
import { useNavigate } from "react-router-dom";
import { getCookie, kickUser } from "helper/cookie";
import Chart from "react-apexcharts";
import upcommingEvent from "assets/media/Event.svg";
import { apiPopularData, apiStatisticsData, apiWeeklyData } from "api/event";
import { titleize } from "helper";
import { analyticIcon } from "./menu";
import { BsPerson } from "react-icons/bs";
import { RiArrowLeftSLine } from "react-icons/ri";
import { RiArrowRightSLine } from "react-icons/ri";
import moment from "moment";

const dataColor = ["#9155FD", "#56CA00", "#FFB400", "#16B1FF"];
let currentDate = new Date();
let first = currentDate.getDate() - currentDate.getDay();
let last = first + 6;

function AdminDashboard({ handleSearchshow }) {
  const [show] = useState(false);
  let navigate = useNavigate();
  const [statisticData, setStatisticData] = useState({});
  const [popularData, setPopularData] = useState([]);
  const [weekStart, setWeekStart] = useState(
    new Date(currentDate.setDate(first))
  );
  const [weekEnd, setWeekEnd] = useState(new Date(currentDate.setDate(last)));

  const [weeklyChat, setWeeklyChat] = useState({
    options: {
      chart: {
        id: "apexchart-example",
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
      },
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
      },
    ],
  });
  useEffect(() => {
    fetchStatisticsData();
    fetchPopularData();
    fetchWeekData();
    handleSearchshow(show);
    if (!getCookie("auth_token")) {
      kickUser();
      navigate(`admin/login`);
    }
    setWeeklyChat({
      options: {
        chart: {
          id: "apexchart-example",
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: "#A020F0",
        grid: {
          strokeDashArray: 7,
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "40%",
          },
        },
        xaxis: {
          categories: ["S", "M", "T", "W", "T", "F", "S"],
          labels: {
            style: {
              colors: "#8B898F",
              fontSize: "13px",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
      },
      series: [
        {
          name: "series-1",
          data: [],
        },
      ],
    });
  }, []);
  useEffect(() => {
    fetchWeekData();
  }, [weekStart]);

  const fetchStatisticsData = () => {
    apiStatisticsData(getCookie("auth_token")).then(({ data }) => {
      setStatisticData({ ...data });
    });
  };

  const fetchPopularData = () => {
    apiPopularData(getCookie("auth_token")).then(({ data }) => {
      setPopularData([...data]);
    });
  };
  const fetchWeekData = () => {
    const params = {
      from_date: moment(weekStart).format("yyyy-MM-DD"),
      to_date: moment(weekEnd).format("yyyy-MM-DD"),
    };
    apiWeeklyData(getCookie("auth_token"), params).then(({ data }) => {
      const count = data?.map((value) => {
        return value?.count;
      });
      setWeeklyChat((preState) => ({
        ...preState,
        series: [{ data: count, name: "Registered" }],
      }));
    });
  };

  const handlePreviousWeek = () => {
    var today = new Date();
    var lastWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      weekStart.getDate() - 7
    );
    let first = lastWeek.getDate() - lastWeek.getDay();
    let last = first + 6;
    setWeekStart(new Date(lastWeek.setDate(first)));
    setWeekEnd(new Date(lastWeek.setDate(last)));
  };

  const handleNextWeek = () => {
    var today = new Date();
    var lastWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      weekStart.getDate() - -7
    );
    let first = lastWeek.getDate() - lastWeek.getDay();
    let last = first + 6;
    setWeekStart(new Date(lastWeek.setDate(first)));
    setWeekEnd(new Date(lastWeek.setDate(last)));
  };
  return (
    <div className="flex flex-col flex-[1] p-2">
      <Header title={"Dashboard"} />
      <div className="flex flex-col gap-5 font-['Inter']">
        <div className="flex gap-5">
          <div
            style={{ boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px" }}
            className="flex-1 p-6 rounded-md bg-white flex flex-col gap-2"
          >
            <div>
              <h3 className="text-[1.30rem] font-[500] text-[#534F5A]">
                Ongoing Events
              </h3>
            </div>
            <div></div>
            <div className="flex justify-between items-center">
              <div>
                <p className="text-[3rem] text-[#A020F0] font-[600]">
                  {statisticData?.ongoing_events}
                </p>
              </div>
              <div>
                <img src={upcommingEvent} />
              </div>
            </div>
          </div>
          <div className="flex-[2_0_0%]">
            <div
              style={{ boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px" }}
              className="bg-white p-6 flex flex-col gap-7 rounded-md"
            >
              <div className="flex flex-col gap-2">
                <div>
                  <h3 className="text-[1.30rem] font-[500] text-[#534F5A]">
                    Statistics Card
                  </h3>
                </div>
                <div></div>
              </div>
              <div className="flex gap-10">
                {statisticData &&
                  Object.keys(statisticData)?.map((keys, index) => {
                    if (keys !== "ongoing_events") {
                      return (
                        <div key={index} className="flex-1">
                          <div className="flex gap-3">
                            <div
                              className="flex justify-center items-center p-3 rounded-md"
                              style={{ backgroundColor: dataColor[index] }}
                            >
                              {analyticIcon[index]?.icon}
                            </div>
                            <div>
                              <p className="text-[#908D94] text-[0.95rem] font-[400]">
                                {titleize(keys.split("_")[1])}
                              </p>
                              <h3 className="text-[1.5rem] text-[#534F5A] font-[500] pt-[1px]">
                                {statisticData?.[keys]}
                              </h3>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-5">
          <div
            style={{ boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px" }}
            className="flex-1 bg-white rounded-md p-6 flex flex-col gap-3"
          >
            <div className="flex justify-between">
              <h2 className="text-[1.25rem] text-[#534F5A] font-[500]">
                Weekly Registration
              </h2>
              <div className="flex items-center">
                <button
                  className="mr-2 text-[1.5rem]"
                  onClick={handlePreviousWeek}
                >
                  <RiArrowLeftSLine />
                </button>
                {moment(weekStart).format("MMM DD")} -{" "}
                {moment(weekEnd).format("MMM DD")}
                <button className="ml-2 text-[1.5rem]" onClick={handleNextWeek}>
                  <RiArrowRightSLine />
                </button>
              </div>
            </div>
            <div className="flex-1">
              <Chart
                options={weeklyChat?.options}
                series={weeklyChat?.series}
                type="bar"
                height="100%"
              />
            </div>
          </div>
          <div className="flex-[2_0_0%]">
            <div className="flex gap-5 w-full h-full">
              <div
                style={{ boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px" }}
                className="flex-1 bg-white rounded-md p-6 flex flex-col gap-5"
              >
                <div className="text-[1.25rem] text-[#534F5A] font-[500]">
                  Best Performing Companies
                </div>
                <div className="flex justify-center items-center h-full">
                  <p>No Data Found</p>
                </div>
              </div>
              <div
                style={{ boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px" }}
                className="flex-1 bg-white rounded-md p-6 flex flex-col gap-5"
              >
                <div className="text-[1.25rem] text-[#534F5A] font-[500]">
                  Best Performing Events
                </div>
                <div className="flex items-center justify-between gap-6">
                  <div className="flex items-center gap-2">
                    <div>
                      <img
                        src={popularData[0]?.url}
                        className="h-[60px] w-[60px] rounded-full"
                      />
                    </div>
                    <div>
                      <h3 className="text-[2.125rem] text-[#3a3541] font-[500]">
                        {popularData[0]?.title}
                      </h3>
                      <p className="text-[15px] text-[#A5A3A8]">
                        {popularData[0]?.name}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="text-[#A020F0] text-[1.5rem] gap-1 flex">
                      <p>{popularData[0]?.registrations}</p>
                      <BsPerson className="text-[#A020F0] text-[2rem] font-bold" />
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-4 mt-3">
                  {popularData &&
                    popularData.slice(1)?.map((value, keys) => {
                      return (
                        <div
                          className="flex flex-row justify-between items-center"
                          key={keys}
                        >
                          <div className="flex flex-row gap-3 items-center">
                            <div className="w-10 h-10 bg-[#F7F7F7] rounded-full">
                              <img
                                src={value?.url}
                                className="object-cover w-full h-full rounded-full "
                              />
                            </div>
                            <div>
                              <p className="text-[0.875rem] font-[600] text-[#534F5A]">
                                {value?.title}
                              </p>
                              <p className="text-[0.75rem] font-[400] text-[#A5A3A8]">
                                {value?.name}
                              </p>
                            </div>
                          </div>
                          <div className="flex items-center gap-2">
                            <p className="text-[0.875rem] font-[600] text-[#534F5A]">
                              {value?.registrations}{" "}
                            </p>
                            <BsPerson className="text-[#A020F0] text-[19px]" />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AdminDashboard;
