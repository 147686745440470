import React, { useEffect, useState } from "react";
import { apiCompanieslist, apiProductlist } from "api/event";
import { useNavigate } from "react-router-dom";
import { Header } from "./header";
import { getCookie, kickUser, setCookie } from "helper/cookie";
import Admincards from "./adminCards";
import Skeleton from "./skeleton";
import Companyform from "./companyForm";
import Empty from "./empty";
import TableCardview from "./tableCardview";
import Pagination from "./pagination";

function AdminIndex({ handleSearchshow, searchName }) {
  const [companiesList, setCompanieslist] = useState([]);
  const [pagination, setPagination] = useState({ per_page: 25, page: 1 });
  const [Admin, setAdmin] = useState({
    name: "",
    status: "",
    adminEmail: "",
    adminName: "",
    companyid: "",
    groupId: "",
    productId: [],
  });
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [show] = useState(true);
  const [cardTable, setCardtable] = useState(true);
  const [total_entries, setTotalEntries] = useState("");
  const [search, setSearch] = useState({ query: "" });

  const navigate = useNavigate();
  useEffect(() => {
    handleSearch({ target: { value: searchName } });
    return () => {
      handleSearch({ target: { value: "" } });
    };
  }, [searchName]);

  useEffect(() => {
    fetchCompanies();
    handleSearchshow(show);
    fetchSubscribe();
  }, [search, pagination]);

  const handlePagination = (page) => {
    setPagination((preState) => ({
      ...preState,
      page: page,
    }));
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch((preState) => ({ ...preState, query: value }));
  };

  const fetchCompanies = () => {
    apiCompanieslist(getCookie("auth_token"), search, pagination)
      .then(({ data, total_entries }) => {
        setLoading(false);
        setTotalEntries(total_entries);
        setCompanieslist(data);
      })
      .catch((err) => {
        if (err[0] === 403 || err[0] === 401) {
          navigate("/admin/login");
          kickUser();
        }
      });
  };

  const fetchSubscribe = () => {
    apiProductlist(getCookie("auth_token"), pagination).then(({ data }) => {
      setAdmin((preState) => ({
        // ...preState, productId: data, groupId: data.map((value) => value?.id)
        ...preState,
        productId: data,
        groupId: data.map((value) => value?.id),
      }));
    });
  };
  console.log(pagination, "pagination");
  const handleAdmin = (id, name, status) => {
    setVisible(true);
    setAdmin((preState) => ({
      ...preState,
      companyid: id,
      name: name,
      status: status,
    }));
  };
  const handleReset = () => {
    setAdmin((preState) => ({
      ...preState,
      name: "",
      status: "",
      adminEmail: "",
      adminName: "",
      companyid: "",
      groupId: "",
    }));
  };
  const handleExit = () => {
    setVisible(false);
    handleReset();
  };

  if (loading) {
    const row = [];
    for (let i = 0; i < 8; i++) {
      row.push(
        <div key={i} className="flex-[0_0_25%]">
          <Skeleton />
        </div>
      );
    }
    return <div className="flex flex-wrap py-10">{row}</div>;
  }

  const handleMove = (id, name, product, subscription_id) => {
    setCookie("organizer", id);
    setCookie("companyName", name);
    setCookie("products", product);
    setCookie("subscription_id", subscription_id);
    navigate(`/admin/companies/${id}/organizers`);
  };
  const handleAdminchange = (e) => {
    const { name, value } = e.target;
    setAdmin((preState) => ({
      ...preState,
      [name]: value,
    }));
  };
  const { productId, groupId, companyid } = Admin;
  const adminProps = {
    title: companyid ? "Update Company" : "Create Company",
    handleExit,
    handleAdminchange,
    Admin,
    productId,
    groupId,
    fetchCompanies,
  };

  const handleToogleright = () => {
    setCardtable(false);
  };
  const handleToogleleft = () => {
    setCardtable(true);
  };
  const headerProps = {
    handleToogleright,
    handleToogleleft,
    cardTable,
  };

  return (
    <div>
      <Header
        title={"Companies"}
        toogle
        admins
        {...headerProps}
        adminColor
        handleUser={() => handleAdmin("", "", "")}
      />
      {cardTable ? (
        <div className="flex gap-5 flex-wrap p-1">
          {companiesList.length !== 0 ? (
            companiesList.map((value, index) => (
              <div key={index}>
                <Admincards
                  name={value?.name}
                  email={value?.admins[0]?.email}
                  status={value?.status}
                  adminName={value?.subscription?.product.name}
                  edit={true}
                  handleMove={() =>
                    handleMove(
                      value?.id,
                      value?.name,
                      value?.subscription?.product.name,
                      value?.subscription?.product_subscription?.id
                    )
                  }
                  onclick={() =>
                    handleAdmin(value?.id, value?.name, value?.status)
                  }
                  {...value}
                />
              </div>
            ))
          ) : (
            <Empty no_data />
          )}
        </div>
      ) : (
        <div>
          <TableCardview
            handleEdit={handleAdmin}
            handleMove={handleMove}
            companyCardloop={companiesList}
          />
        </div>
      )}
      {total_entries != 0 && (
        <Pagination
          per_page={pagination?.per_page}
          total_entries={total_entries}
          handlePagination={handlePagination}
          color={"purple"}
        />
      )}
      {visible && <Companyform {...adminProps} />}
    </div>
  );
}

export default AdminIndex;
