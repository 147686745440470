import React, { useEffect, useState } from 'react'
import { DropDown, TextField } from './formField'
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastStyle } from "helper";
import { apiOrganizercreate, apiOrganizeredit } from 'api/event';
import { getCookie } from 'helper/cookie';
import { Header } from './header';
import { IoClose } from "react-icons/io5";


let lableName;

function OrganizerForm({ handleOrganizerchange, handleExit, organizerValue, roleGroupid, locationId, fetchCompany, title, id, organizerRole }) {
    const [disabled, setDisabled] = useState(true)
    const { organizerName, organizerEmail, organizerStauts } = organizerValue
    const [toastMessage, setToastMessage] = useState({ message: "", type: "" });

    useEffect(() => {
        if (toastMessage.type !== "") {
            notify();
        }
    }, [toastMessage]);
    let organizerBody = {
        organizers: {
            name: organizerName,
            email: organizerEmail,
            role_group_id: roleGroupid,
            status: organizerStauts
        }
    }

    const statusOption = [
        {
            value: 'active', lable: 'Active'
        },
        {
            value: 'inactive', lable: 'Inactive'

        }]

    const rolesOption = organizerRole.map((data) => {
        if (roleGroupid) {
            if (data?.id === roleGroupid) {
                lableName = data?.name
            }
        }
        else {
            lableName = ""
        }
        return {
            value: data?.id,
            lable: data?.name
        }
    })

    const handleOrganizersubmit = () => {
        const action = !id ? apiOrganizercreate : apiOrganizeredit
        action(getCookie("auth_token"), locationId, organizerBody, id).then(({ message }) => {
            setDisabled(false)
            setToastMessage({
                message,
                type: "success",
            });
            setTimeout(() => {
                fetchCompany()
                handleExit()
                handleReset()
            }, 3000);
        }).catch((err) => {
            setDisabled(false)
            setTimeout(() => {
                setDisabled(true);
            }, 4000);
            setToastMessage({
                message: err[1].data?.response || err[1].data[0],
                type: "error"
            })
        })
    }
    const notify = () => {
        if (toastMessage.type === "error") {
            toast.error(toastMessage.message, toastStyle);
        }
        if (toastMessage.type === "success") {
            toast.success(toastMessage.message, toastStyle);
        }
    };
    return (
        <div>
            <div style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
                className='w-full h-full z-[2] absolute top-0 left-0  flex justify-center items-center '>
                <div className='bg-white flex flex-[0.4] flex-col gap-5 p-7'>
                    <div className='flex flex-col flex-1 gap-5'>
                        <div className="flex justify-between">
                            <Header title={title} />
                            <IoClose onClick={handleExit} className="h-10 w-8 cursor-pointer" />
                        </div>
                        <div className='flex flex-1 gap-5'>
                            <div className='flex flex-col gap-5 flex-1'>
                                <div>
                                    <TextField placeholder={'Name'} name="organizerName" onHandleChange={handleOrganizerchange} value={organizerName} />
                                </div>
                                <div>
                                    <TextField placeholder={'Email'} name="organizerEmail" onHandleChange={handleOrganizerchange} value={organizerEmail} />
                                </div>
                            </div>
                            <div className='flex flex-col gap-4 flex-1'>
                                <div>
                                    <DropDown placeholder={"Role"} name="roleGroupid" option={rolesOption} value={lableName} onHandleChange={handleOrganizerchange} />
                                </div>
                                <div>
                                    <DropDown placeholder={"Status"} name="organizerStauts" option={statusOption} onHandleChange={handleOrganizerchange} value={organizerStauts} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end gap-4 pt-7">
                        <div onClick={handleExit} className="hover:bg-[#8854d0] flex flex-[0.2] 
                                 cursor-pointer text-[#8854d0] h-10 bg-white border border-solid
                                  border-[#8854d0]  justify-center hover:text-white p-2 ">
                            <p>Cancel</p>
                        </div>
                        <div
                            onClick={disabled ? handleOrganizersubmit : null}
                            className={
                                disabled ?
                                    ` bg-[#8854d0] flex flex-[0.2] h-10
                                 cursor-pointer hover:text-[#8854d0] hover:bg-white hover:border hover:border-solid
                                  hover:border-[#8854d0]  justify-center text-white p-2 ` :
                                    ` disabled:bg-slate-200 flex flex-[0.2] h-10
                                  cursor-pointer text-slate-300 justify-center p-2 border border-slate-200 border-solid`
                            }>
                            <p>Submit</p>
                        </div>
                        <ToastContainer autoClose={2200} style={{ fontWeight: 'normal' }} />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default OrganizerForm