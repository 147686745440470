import React, { useEffect, useState } from "react";
import { Header } from "./header";
import { IoClose } from "react-icons/io5";
import { TextField } from "./formField";
import { apiAddmember } from "api/event";
import { getCookie } from "helper/cookie";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastStyle } from "helper";
import PropTypes from "prop-types";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import { isValidPhoneNumber } from 'react-phone-number-input'
import en from "react-phone-number-input/locale/en";

const CountrySelect = ({ value, name, onChange,labels, ...rest }) => {
  return (
    <>
      <p className="text-[16px] font-medium pb-[0.75rem]">{"Country Code"}</p>
      <select
        {...rest}
        placeholder={"Country Code"}
        className={`max-w-[105px] bg-[#F7F7FA] h-[52px] hover:border-[#3498FF] focus:border-[#3498FF] border border-solid border-slate-200 rounded-lg py-[15px] px-3 flex justify-between gap-2`}
        value={value}
        onChange={(event) => {
          onChange({
            target: {
              name,
              value: event.target.value,
            },
          });
        }}
      >
        <option value="91">IN + {getCountryCallingCode("IN")}</option>
        {getCountries().map((country) => (
          <option
            key={country}
            value={getCountryCallingCode(country)}
            className={`px-3 py-2 cursor-pointer
        hover:bg-[#42C3AA]
        hover:text-white`}
          >
            {labels[country]} +{getCountryCallingCode(country)}
          </option>
        ))}
      </select>
    </>
  );
};

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.objectOf(PropTypes.string).isRequired,
};

function TableForm({
  handleTablechange,
  handleViewexit,
  fetchParticipant,
  handleReset,
  fetchDetails,
  fetchTeam,
  tableViewdata,
  mobileNo,
  countryCode,
}) {
  const [disabled, setDisabled] = useState(true);
  const [toastMessage, setToastMessage] = useState({ message: "", type: "" });
  const { participantName, eventTableid } = tableViewdata;
  useEffect(() => {
    if (toastMessage.type !== "") {
      notify();
    }
  }, [toastMessage]);

  const tableBody = {
    participants: {
      country_code: countryCode,
      phone: mobileNo,
    },
  };
  const handleTablesubmit = () => {
    let validNumber = isValidPhoneNumber(`+${countryCode}${mobileNo}`)
    if(validNumber){
      apiAddmember(getCookie("auth_token"), eventTableid, tableBody)
      .then(({ message }) => {
        setDisabled(false);
        setToastMessage({
          message,
          type: "success",
        });
        setTimeout(() => {
          handleViewexit();
          fetchTeam();
          fetchDetails();
          fetchParticipant();
          handleReset();
        }, 3000);
      })
      .catch((err) => {
        setDisabled(false);
        setToastMessage({
          message: err[1].data.response,
          type: "error",
        });
      });
    }else{
      setDisabled(false);
      setToastMessage({
        message:"Please enter valid phone number",
        type: "error",
      });
    }
  
  };
  const notify = () => {
    if (toastMessage.type === "error") {
      toast.error(toastMessage.message, toastStyle);
    }
    if (toastMessage.type === "success") {
      toast.success(toastMessage.message, toastStyle);
    }
  };

  return (
    <div
      style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
      className="w-full h-full z-[2] absolute top-0 left-0  flex justify-center items-center "
    >
      <div className="flex flex-col  bg-white p-5 justify-center gap-7 flex-[0.2]">
        <div className=" flex justify-between ">
          <Header title={"Add Participants"} />
          <IoClose
            onClick={handleViewexit}
            className="h-10 w-8 cursor-pointer"
          />
        </div>

        <div className="text-slate-500 gap-6 flex-1 flex justify-center text-lg  flex-col">
          <div className="flex flex-1 gap-3">
            <span className="text-black text-xl">Event Name :</span>{" "}
            {participantName}
          </div>

          <div className="flex items-center gap-1 text-black">
            <div className="flex flex-col">
              <CountrySelect
                labels={en}
                value={countryCode}
                onChange={(e) => { handleTablechange(e), setDisabled(true) }}
                name={"countryCode"}
              />
            </div>
            <div className="flex flex-col flex-[1]">
              <TextField
                placeholder={" Mobile number "}
                name="mobileNo"
                onHandleChange={(e) => {
                  handleTablechange(e), setDisabled(true)
                }}
                value={mobileNo?.replace(/[^0-9 ]/g, "")}
              />
            </div>
          </div>
          <div className="flex justify-end ">
            <div
              onClick={disabled ? handleTablesubmit : null}
              className={
                disabled
                  ? ` bg-[#42C3AA] flex  flex-[0.4] h-12
                                 cursor-pointer hover:text-[#42C3AA] hover:bg-white hover:border hover:border-solid
                                  hover:border-[#42C3AA]  justify-center text-white p-2 `
                  : ` disabled:bg-slate-200 flex flex-[0.4]  h-12
                                  cursor-pointer text-slate-300 justify-center p-2 border border-slate-200 border-solid`
              }
            >
              <p>Submit</p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2200} style={{ fontWeight: "normal" }} />
    </div>
  );
}

export default TableForm;
