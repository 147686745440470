import { DateTime, SliceName } from "helper";
import React from "react";

const colorMapper = {
  "Org Admin": "black",
  "Org User": "blueviolet",
  Bronze: "#9155FD",
  Platinum: "#16B1FF",
};

function Admincards({
  name,
  email,
  lastLogin,
  status,
  createdAt,
  adminName,
  edit,
  onclick,
  handleMove,
}) {
  return (
    <div>
      <div
        className={`flex flex-col bg-white
              rounded-[6px] flex-wrap  hover:shadow-lg  cursor-pointer shadow-md w-[21rem] text-black`}
      >
        <div onClick={handleMove}>
          <div
            className={`flex text-md font-["Nunito_Sans"] " text-black"   justify-between p-4`}
          >
            <div>{SliceName(name, 20)}</div>
            <div>
              {status && (
                <div
                  className={`w-[10px] h-[10px] rounded-xl ${
                    status === "active"
                      ? adminName === "null"
                        ? "bg-slate-50"
                        : "bg-[#26de81]"
                      : "bg-red-500"
                  }`}
                ></div>
              )}
            </div>
          </div>
          <div
            className={`flex p-4
              text-black
             font-["Nunito_Sans"] text-md  justify-between`}
          >
            <div>{email ? SliceName(email, 17) : DateTime(createdAt)}</div>
            <div
              className="px-1 rounded"
              style={{
                color: "#fff",
                background: colorMapper[adminName] || "#9155FD",
              }}
            >
              {adminName ? adminName : SliceName(DateTime(lastLogin), 9)}
            </div>
          </div>
        </div>
        {edit && (
          <div
            onClick={onclick}
            className={` font-["Nunito_Sans"] hover:duration-100 rounded bg-white border-t border-solid border-slate-200 flex justify-center p-2`}
          >
            <button
              className={`text-sm `}
              style={{
                color: colorMapper[adminName] || "#9155FD",
              }}
            >
              Edit
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
export default Admincards;
