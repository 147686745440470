import { getCookie } from "helper/cookie";
import React from "react";
import { ImageViewer } from "view/imageViewer";
import {
  DateField,
  DropDown,
  EventCode,
  TextField,
  TinyMceField,
  ToggleButton,
  ToggleField,
} from "./formField";
// import { format } from "date-fns"
export const EventForm = ({
  data,
  handleChange,
  statusOption,
  control,
  design,
  handleModel,
  handleSettingChange,
  handleDesignChange,
  measureOption,
  handleCopy,
  handleDatechange,
  show,
  handleDateshow,
  visible,
  handleDateexit
}) => {
  let today = new Date(data?.start_date)
  let endToday = new Date(data?.end_date)

  // let a = format(today, "p,dd-mm-yyyy")
  // let b = format(endToday, "p,dd-mm-yyyy")
  return (
    <div className="flex flex-col gap-10">
      {control && (
        <div className="bg-white shadow-[4px_4px_20px_#dcdcdc] p-6 flex flex-col gap-6">
          <div className="flex-1 flex justify-between">
            <h1 className="text-[19px] font-bold">Controls</h1>
            {!design && control && (
              <EventCode handleCopy={handleCopy} code={data?.code} />
            )}
          </div>
          <div className="flex justify-between gap-8">
            <div className="flex-1  flex flex-col gap-[50px]">
              {getCookie("product_enable_teams") != "false" &&
                <div className=" flex items-center  justify-between">
                  <p className="font-medium text-[17px]">Allow Team</p>
                  <ToggleButton
                    value={data?.event_setting?.enable_teams}
                    name="enable_teams"
                    onHandleChange={handleSettingChange}
                  />
                </div>
              }
              {getCookie("product_allow_team_create") != 'false' &&
                <div className=" flex items-center  justify-between">
                  <p className="font-medium text-[17px]">Allow Team Create</p>
                  <ToggleButton
                    value={data?.event_setting?.allow_team_create}
                    name="allow_team_create"
                    onHandleChange={handleSettingChange}
                  />
                </div>
              }
              <div className=" flex items-center  justify-between">
                <p className="font-medium text-[17px]">Allow Sharing</p>
                <ToggleButton
                  value={data?.event_setting?.allow_sharing}
                  name="allow_sharing"
                  onHandleChange={handleSettingChange}
                />
              </div>
              <div className="flex gap-4 ">
                <div className="flex-1">
                  <DateField
                    placeholder="Start Date"
                    shown={show}
                    handleDateshow={handleDateshow}
                    handleDateexit={handleDateexit}
                    name="start_date"
                    value={{ startDate: today, endDate: endToday, key: "events" }}
                    onHandleChange={handleDatechange}
                  />
                </div>
                <div className="flex-1">
                  <DateField
                    placeholder="End Date"
                    name="end_date"
                    shown={visible}
                    handleDateshow={handleDateshow}
                    handleDateexit={handleDateexit}
                    value={{ startDate: today, endDate: endToday, key: "events" }}
                    onHandleChange={handleDatechange}
                  />
                </div>
              </div>
            </div>

            <div className="h-[45vh] relative flex-1">
              <div className="flex-1 flex flex-col gap-6 ">
                <div className="flex-1">
                  <DropDown
                    style=""
                    placeholder="Status"
                    option={statusOption}
                    value={data?.status}
                    onHandleChange={handleChange}
                    name="status"
                  />
                </div>
                <div className="flex-1">
                  <DropDown
                    style=""
                    placeholder="Unit Of Measure"
                    option={measureOption}
                    value={data?.event_setting?.unit_of_measure}
                    onHandleChange={handleSettingChange}
                    name="unit_of_measure"
                  />
                </div>
                <div className="flex-1 flex gap-10 justify-between">
                  {/* <div className="flex flex-1 gap-10"> */}
                  <div className="flex flex-1 flex-col gap-3 text-[16px] font-medium">
                    <p>Event type</p>
                    <ToggleField
                      leftValue="Public"
                      rightValue="Private"
                      value={data?.event_type}
                      name="event_type"
                      onHandleChange={handleChange}
                    />
                  </div>
                  <div className="flex flex-1 gap-3 flex-col text-[16px] font-medium">
                    <p>Payment Type</p>
                    <ToggleField
                      leftValue="Free"
                      rightValue="Paid"
                      value={data?.plan_type}
                      name="plan_type"
                      onHandleChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  {data?.plan_type === "paid" && (
                    <div className="flex relative -top-4 flex-1 flex-col text-[16px] font-medium">
                      <TextField
                        placeholder="Amount"
                        name="amount"
                        value={data?.amount}
                        onHandleChange={handleChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {design && (
        <div className="bg-white shadow-[4px_4px_20px_#dcdcdc] p-6 flex flex-col gap-6">
          <div className="flex-1 flex justify-between">
            <h1 className="text-[19px] font-bold">Design</h1>
            {design && !control && (
              <EventCode handleCopy={handleCopy} code={data?.code} />
            )}
          </div>
          <div className="flex flex-col gap-10">
            <div className="flex gap-16 items-center">
              <div className="w-full flex-1">
                <TextField
                  placeholder="Event Title"
                  name="title"
                  value={data?.title}
                  onHandleChange={handleChange}
                />
              </div>
              <div className="w-full flex flex-row flex-1 gap-8 items-center">
                <div className="flex-1">
                  <ImageViewer
                    placeholder="Upload Title Image"
                    onHandleClick={() => {
                      handleModel(false);
                    }}
                    name="title_image_id"
                    value={
                      data?.title_image
                        ? data?.title_image
                        : data.title_image_id
                    }
                  />
                </div>
                <div className="flex-1">
                  <ImageViewer
                    placeholder="Upload Banner Image"
                    onHandleClick={() => {
                      handleModel(true);
                    }}
                    name="banner_image_ids"
                    value={
                      data?.banner_images
                        ? data?.banner_images
                        : data?.banner_image_ids
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-16">
              <div className="flex-1">
                <p className="font-medium text-[16px] pb-2">Description</p>
                <TinyMceField
                  value={data?.event_detail?.description}
                  onChange={(e) => {
                    handleDesignChange({
                      target: {
                        name: "description",
                        value: e,
                      },
                    });
                  }}
                  name="description"
                  height="400px"
                  width="100%"
                />
              </div>
              <div className="w-full flex-1">
                <p className="font-medium text-[16px] pb-2">Award</p>
                <TinyMceField
                  value={data?.event_detail?.awards}
                  onChange={(e) => {
                    handleDesignChange({
                      target: {
                        name: "awards",
                        value: e,
                      },
                    });
                  }}
                  name="awards"
                  height="400px"
                  width="100%"
                />
              </div>
            </div>
            <div className="flex gap-16">
              <div className="flex-1">
                <p className="font-medium text-[16px] pb-2">Rules</p>
                <TinyMceField
                  value={data?.event_detail?.rules}
                  onChange={(e) => {
                    handleDesignChange({
                      target: {
                        name: "rules",
                        value: e,
                      },
                    });
                  }}
                  name="rules"
                  height="400px"
                  width="100%"
                />
              </div>
              <div className="w-full flex-1">
                <p className="font-medium text-[16px] pb-2">Sponsor</p>
                <TinyMceField
                  value={data?.event_detail?.sponsors}
                  onChange={(e) => {
                    handleDesignChange({
                      target: {
                        name: "sponsors",
                        value: e,
                      },
                    });
                  }}
                  name="sponsors"
                  height="400px"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
