import React, { Component } from "react";
import { LoginForm } from "component/loginForm";

class UserLogin extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { loginType } = this.props;

    const loginProps = {
      organizer: loginType,
    };

    return <LoginForm {...loginProps} />;
  }
}

export default UserLogin;
