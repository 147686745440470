import React
, { useEffect, useState }
    from "react";
import {
    //  DateField,
    TextField, ToggleButton
} from "./formField";
import { Header } from "./header";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastStyle } from "helper";
import { getCookie } from "helper/cookie";
// import moment from "moment";
import { IoClose } from "react-icons/io5";
import { apiProductedit, apiSubscriptionedit } from "api/event";


function ProductForm({ handleExit, subscription_id, handleSubscriptionchange,
    fetchProduct,
    fetchSubscription,
    Admin, title,
    //  handleProductexit
}) {
    const [disabled, setDisabled] = useState(true)
    const [toastMessage, setToastMessage] = useState({ message: "", type: "" });
    const { count_limit, participant_limit, media, enable_teams, allow_team_create, id } = Admin;
    useEffect(() => {
        if (toastMessage.type !== "") {
            notify();
        }
    }, [toastMessage]);

    const notify = () => {
        if (toastMessage.type === "error") {
            toast.error(toastMessage.message, toastStyle);
        }
        if (toastMessage.type === "success") {
            toast.success(toastMessage.message, toastStyle);
        }
    };

    const handleProductsubmit = () => {
        let subscriptionBody = {
            subscription: {
                count_limit: count_limit,
                participant_limit: participant_limit,
                media: media,
                enable_teams: enable_teams,
                allow_team_create: allow_team_create
            }
        }
        let productBody = {
            products: {
                count_limit: count_limit,
                participant_limit: participant_limit,
                media: media,
                enable_teams: enable_teams,
                allow_team_create: allow_team_create
            }
        }
        let product = subscription_id ? apiSubscriptionedit : apiProductedit
        product(getCookie("auth_token"), id ? id : subscription_id, id ? productBody : subscriptionBody).then(({ data }) => {
            setDisabled(false)
            setToastMessage({
                message: data,
                type: "success",
            });
            setTimeout(async () => {
                handleExit()
                id ? fetchProduct() : fetchSubscription()
                // handleProductexit()
            }, 3000)
        }).catch((err) => {
            setDisabled(false)
            setTimeout(() => {
                setDisabled(true);
            }, 4000);
            setToastMessage({
                message: err[1].data?.response,
                type: "error"
            })
        })
    }
    return (
        <div>
            <div style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
                className='w-full h-full z-[2] absolute top-0 left-0 flex justify-center items-center '>
                <div className="flex flex-col gap-5 flex-[0.5] bg-white p-7">
                    <div className="flex justify-between">
                        <Header title={title} />
                        <IoClose onClick={handleExit} className="h-10 w-8 cursor-pointer" />
                    </div>
                    <div className="flex gap-10">

                        <div className="flex flex-col gap-10 flex-1">
                            {/* <div>
                                <TextField placeholder={'Product Name'}
                                    onHandleChange={handleSubscriptionchange} value={name} name="name" />
                            </div>
                            <div>
                                <TextField placeholder={'Product Price'}
                                    onHandleChange={handleSubscriptionchange} value={price} name="price" />
                            </div> */}
                            <div>
                                <TextField placeholder={'Event Count'}
                                    onHandleChange={handleSubscriptionchange} value={count_limit} name="count_limit" />
                            </div>
                            <div>
                                <TextField placeholder={'Participants Limit'}
                                    onHandleChange={handleSubscriptionchange} value={participant_limit} name="participant_limit" />
                            </div>
                        </div>

                        <div className="flex flex-col gap-4 flex-1">

                            <div>
                                {/* <DateField
                                    placeholder="Event Duration"
                                    name="duration"
                                    value={duration && moment(duration)._d}
                                    onHandleChange={handleSubscriptionchange} /> */}
                                {/* <TextField placeholder={'Event Duration'}
                                    onHandleChange={handleSubscriptionchange} value={duration}
                                    name="duration" /> */}

                            </div>

                            <div className=" flex items-center  justify-between">
                                <p className="font-medium text-[17px]">Allow Team Create</p>
                                <ToggleButton
                                    value={allow_team_create}
                                    name="allow_team_create"
                                    onHandleChange={handleSubscriptionchange}
                                    tab
                                />
                            </div>

                            <div className=" flex items-center  justify-between">
                                <p className="font-medium text-[17px]">Allow Team</p>
                                <ToggleButton
                                    value={enable_teams}
                                    name="enable_teams"
                                    onHandleChange={handleSubscriptionchange}
                                    tab
                                />
                            </div>

                            <div className=" flex items-center  justify-between">
                                <p className="font-medium text-[17px]">Media</p>
                                <ToggleButton
                                    value={media}
                                    name="media"
                                    onHandleChange={handleSubscriptionchange}
                                    tab
                                />
                            </div>

                        </div>
                    </div>

                    <div className='flex justify-end pt-8 gap-2'>
                        <div onClick={handleExit}
                            className='border  border-solid  hover:bg-[#8854d0] hover:text-white hover:border-solid cursor-pointer
                        border-[#8854d0]  flex flex-[0.3] text-[#8854d0] justify-center p-2'>
                            <p>Cancel</p>
                        </div>
                        <div
                            onClick={disabled ? handleProductsubmit : null}
                            className={
                                disabled ?
                                    ` bg-[#8854d0] flex flex-[0.3]
                                 cursor-pointer hover:text-[#8854d0] hover:bg-white hover:border hover:border-solid
                                  hover:border-[#8854d0]  justify-center text-white p-2`
                                    :
                                    ` disabled:bg-slate-200 flex flex-[0.3]
                                  cursor-pointer text-slate-300 justify-center p-2 border border-slate-200 border-solid`
                            }>
                            <p>Submit</p>
                        </div>
                        <ToastContainer autoClose={2200} style={{ fontWeight: 'normal' }} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProductForm