import React, { useEffect, useState } from "react";
import attachImg from "assets/media/attach.png";
import { apiFetchAdminImage, apiFetchImage } from "api/image";
import { getCookie } from "helper/cookie";
import Pagination from "./pagination";

export const ImageUploader = ({
  handleModel,
  handleImageUploader,
  handleImageSubmit,
  handleImageSelectSubmit,
  multi,
  imageData,
  loginType,
  comId,
  imageUploader
}) => {
  const [upload, setUpload] = useState(false);
  const [attach, setAttach] = useState(true);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageID, setImageID] = useState({
    image_id: imageData?.title_image?.image_id,
  });
  const [multiImage, setMultiImage] = useState({
    multiImageID: imageData?.banner_images?.map((value) => value) || [],
    selectedImage:
      imageData?.banner_images?.map((value) => value?.image_id) || [],
  });
  const [total_entries, setTotalEntries] = useState(0);
  const [pagination, setPagination] = useState({ perPage: 25, page: 1 });
  const row = [];
  const handleUpTab = () => {
    setUpload(true);
    setAttach(false);
  };
  const handleAttachTab = () => {
    setUpload(false);
    setAttach(true);
  };
  useEffect(() => {
    attach && loginType !== "admin" ? fetchImage() : fetchAdminImage();
  }, [attach, pagination]);
  const fetchImage = () => {
    apiFetchImage(getCookie("auth_token"), pagination).then((response) => {
      setData(response.data);
      setTotalEntries(response.total_entries);
      setLoading(false);
    });
  };
  const fetchAdminImage = () => {
    apiFetchAdminImage(getCookie("auth_token"), comId, pagination).then(
      (response) => {
        setData(response.data);
        setTotalEntries(response.total_entries);
        setLoading(false);
      }
    );
  };
  const handleSingleSelect = (image_id, url) => {
    setImageID((preState) => ({ ...preState, image_id, url }));
  };

  const handlePagination = async (page) => {
    await setPagination((preState) => ({
      ...preState,
      page,
    }));
  };

  const handleMultiSelect = (image_id, url) => {
    const { multiImageID, selectedImage } = multiImage;
    if (selectedImage.includes(image_id)) {
      selectedImage.splice(selectedImage.indexOf(image_id), 1);
      multiImageID.splice(
        multiImageID.findIndex((data) => data?.image_id == image_id),
        1
      );
    } else {
      selectedImage.push(image_id);
      multiImageID.push({ image_id, url });
    }
    setMultiImage({ multiImageID, selectedImage });
  };

  for (let i = 0; i < 7; i++) {
    row.push(<div className="h-[130px] rounded w-[10rem] bg-[#F4F4F2]" />);
  }

  const action = multi ? handleMultiSelect : handleSingleSelect;
  const { selectedImage, multiImageID } = multiImage;
  const uploadAction = upload ? handleImageSubmit : handleImageSelectSubmit;
  return (
    <div
      className="w-full h-full z-[2] absolute top-0 left-0 flex justify-center items-center font-['Nunito_Sans']"
      style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
    >
      <div className="w-[70%] h-[70%] min-h-[400px] flex flex-col gap-3  bg-white rounded px-8 pt-9 pb-3">
        <div className="pb-3">
          <h1
            className={`inline-block px-3 text-[16px] cursor-pointer  pb-2 ${
              attach
                ? "border-b-4 border-[#42C3AA] font-semibold text-black"
                : "text-[#737272] font-medium"
            }`}
            onClick={handleAttachTab}
          >
            My Attachments
          </h1>
          {loginType !== "admin" && (
            <h1
              className={`inline-block px-3 text-[16px] cursor-pointer  pb-2 ${
                upload
                  ? "border-b-4 border-[#42C3AA] font-semibold"
                  : "text-[#737272] font-medium"
              }`}
              onClick={handleUpTab}
            >
              Uploader
            </h1>
          )}
        </div>
        <div className="flex flex-col gap-5 w-full h-[79%]">
          {upload && loginType !== "admin" && (
            <div className="flex-1 py-2 overflow-scroll border-2 border-dashed relative hover:bg-[#F2F3F4]">
              <span className="text-2xl text-gray-400 font-light absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                Attach from your computer
              </span>
              <input
                type="file"
                className="w-full h-full cursor-pointer"
                multiple={false}
                onChange={(e) => {
                  handleImageUploader(e);
                }}
              />
            </div>
          )}
          {attach && !loading ? (
            <div className="flex-1 border-2 p-2 overflow-scroll justify-between rounded flex flex-col">
              <div className="flex flex-wrap gap-5">
                {data.map((value, index) => {
                  return (
                    <div
                      key={index}
                      style={{ backgroundImage: `url("${value?.url}")` }}
                      className={`h-[130px] w-[10rem] bg-no-repeat cursor-pointer bg-cover border-4 bg-center ${
                        multi
                          ? selectedImage?.includes(value?.id) &&
                            "border-[#42C3AA]"
                          : imageID?.image_id?.includes(value?.id) &&
                            "border-[#42C3AA]"
                      }`}
                      onClick={() => {
                        action(value?.id, value?.url);
                      }}
                    />
                  );
                })}
              </div>
              <div>
                {
                  <div>
                    <Pagination
                      per_page={pagination.perPage}
                      total_entries={total_entries}
                      handlePagination={handlePagination}
                    />
                  </div>
                }
              </div>
            </div>
          ) : (
            attach && (
              <div className="flex-1 flex flex-wrap gap-5 border-2 p-2 rounded">
                {row}
              </div>
            )
          )}
          {upload && imageUploader.length !== 0 && (
            <div className="flex gap-2 flex-wrap">
              {imageUploader.map((value, index) => {
                return (
                  <div
                    key={index}
                    className="inline-flex px-2 py-1 gap-1 border-[1px] items-center"
                  >
                    <img src={attachImg} className="w-[17px]" />
                    <p>{value?.name}</p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="py-2 flex gap-3 justify-end">
          <button
            className="border-[1px] border-[#ff0000] px-4 py-1 text-[#ff0000] hover:bg-[#ff0000] hover:text-white"
            onClick={handleModel}
          >
            Cancel
          </button>
          <button
            className="border-[1px] border-[#42C3AA] px-4 py-1 text-[#42C3AA] hover:bg-[#42C3AA] hover:text-white"
            onClick={() => {
              uploadAction(multi ? multiImageID : imageID);
            }}
          >
            {attach ? "Select" : "Upload"}
          </button>
        </div>
      </div>
    </div>
  );
};
