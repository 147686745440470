import React from 'react'
import empty from '../assets/media/empty.png'

function Empty({ teams,no_data }) {
    return (
        <div className='flex flex-col relative -left-12 top-40 w-full items-center'>
            <img src={empty} alt='empty' />
            {no_data && <p className='font-normal  text-md relative -top-16'>No data</p>}
            {teams && <p className='font-mono text-slate-400 text-md relative -top-9'>Teams are disabled for this event.</p>}
        </div>
    )
}

export default Empty