import React, { useEffect, useState } from "react";
import { filteredMenu } from "component/menu";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import UserLogin from "view/userLogin";
import MainLayout from "component/layout";
import { getCookie } from "helper/cookie";

const publicPath = [
  { path: "/organizers/login", component: UserLogin, loginType: "organizers" },
  { path: "/admin/login", component: UserLogin },
];

export const CustomRoutes = () => {
  const [routeState, setRouteState] = useState({
    privatePath: [],
    defaultRoute: `/${
      getCookie("path") ? getCookie("path") : "organizers"
    }/login`,
    loading: false,
  });

  const filterMenu = async () => {
    const menu = await filteredMenu();
    setRouteState((preState) => ({
      ...preState,
      privatePath: menu,
      defaultRoute: menu[0]?.url,
      loading: true,
    }));
  };

  const { privatePath, defaultRoute } = routeState;

  useEffect(() => {
    if (getCookie("auth_token")) {
      filterMenu();
    }
  }, []);

  const roles = JSON.parse(window.localStorage.getItem("roles"));
  return (
    <Router>
      <Routes>
        {privatePath?.map((route, index) => {
          let Component = route?.render;
          return (
            <Route
              exact
              key={index}
              path={route?.url}
              element={
                getCookie("auth_token") && roles ? (
                  <MainLayout {...route?.jsonProps}>
                    <Component />
                  </MainLayout>
                ) : (
                  <Navigate to={`/${getCookie("path")}/login`} />
                )
              }
            />
          );
        })}

        {publicPath &&
          publicPath?.map((route, index) => {
            let Component = route?.component;
            return (
              <Route
                exact
                key={index}
                path={route?.path}
                element={
                  getCookie("auth_token") && roles ? (
                    <Navigate to={defaultRoute} />
                  ) : (
                    <Component loginType={route?.loginType} />
                  )
                }
              />
            );
          })}

        <Route exact path="*" element={<Navigate to={defaultRoute} />} />
      </Routes>
    </Router>
  );
};
