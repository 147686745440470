import React, { useEffect, useState } from "react";
import editIcon from "assets/media/edit-icon.svg";
import teamIcon from "assets/media/team-icon.svg";
import moment from "moment";
import { SliceName, titleize } from "helper";
import calories from "../assets/media/kcal.png";
import { RiTeamLine } from "react-icons/ri";
import { MdOutlinePublic } from "react-icons/md";
import { RiChatPrivateFill } from "react-icons/ri";
import { IoFootsteps } from "react-icons/io5";
import { GiPathDistance } from "react-icons/gi";
import { getCookie } from "helper/cookie";
export default function Card({
  title,
  start_date,
  end_date,
  created_by,
  title_image,
  status,
  id,
  plan_type,
  event_type,
  event_setting,
  handleNav,
  // locationId,
}) {
  const [tooltip, setTooltip] = useState({
    teamsTool: false,
    publicTool: false,
    privateTool: false,
    distanceTool: false,
    caloriesTool: false,
    stepsTool: false,
  });
  const [cookie, setCookies] = useState({ count: false, teams: false });
  useEffect(() => {
    setCookies({
      count: getCookie("product_enable_teams"),
      teams: getCookie("product_allow_team_create"),
    });
  }, []);
  const handleTooltip = (e) => {
    e === "teams" && setTooltip({ teamsTool: true });
    e === "public" && setTooltip({ publicTool: true });
    e === "private" && setTooltip({ privateTool: true });
    e === "Distance" && setTooltip({ distanceTool: true });
    e === "steps" && setTooltip({ stepsTool: true });
    e === "calories" && setTooltip({ caloriesTool: true });
  };
  const {
    publicTool,
    teamsTool,
    distanceTool,
    stepsTool,
    caloriesTool,
    privateTool,
  } = tooltip;
  const {
    count,
    // , teams
  } = cookie;
  return (
    <div>
      <div className="relative flex flex-col content  shadow-md w-[21rem] overflow-hidden">
        <div className="cursor-pointer">
          <img
            src={title_image?.url || title_image}
            className="w-[21rem]  h-60 object-cover"
            alt="No image"
          />
        </div>
        <div
          className={` hover:translate-y-[-130px] content1 hover:transition hover:duration-300 bg-white`}
        >
          <div className="flex cursor-pointer items-center hover:transition hover:duration-150 justify-between p-4">
            <h1 className="text-[20px] font-medium">{SliceName(title, 20)}</h1>

            <h1 className="bg-[#ebfaf7] border border-solid border-[#8edbcb] text-[12px] font-semibold text-[#76d7c4] p-1">
              {titleize(status)}
            </h1>
          </div>
          <div className="absolute w-full  bg-white hover:bg-white block">
            <div className="flex p-2 justify-between">
              <div className="px-2 cursor-pointer">
                <p className="text-[14.5px] font-normal text-slate-400 pb-2">
                  {moment(start_date).format("Do MMM")} -{" "}
                  {moment(end_date).format("Do MMM")}
                </p>
                <p className="text-[14.5px] font-normal text-slate-400">
                  Created by{" "}
                  {created_by ? SliceName(created_by?.name, 10) : "-"}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex py-1 gap-2 relative">
                  <RiTeamLine
                    onMouseOver={() => handleTooltip("teams")}
                    onMouseLeave={() => setTooltip({ teamsTool: false })}
                    className={
                      event_setting?.enable_teams
                        ? " cursor-pointer filter contrast-100 text-green-400  text-[17px]"
                        : "text-[17px]cursor-pointer"
                    }
                  />
                  {teamsTool && (
                    <div className="text-[12px] text-slate-500 absolute z-10 -top-6 p-1 -left-3">
                      Teams
                    </div>
                  )}

                  {event_type === "public_event" && (
                    <MdOutlinePublic
                      onMouseOver={() => handleTooltip("public")}
                      onMouseLeave={() => setTooltip({ publicTool: false })}
                      className="cursor-pointer tooltip text-[17px] text-green-400"
                    />
                  )}
                  {publicTool && (
                    <div className="text-[12px] text-slate-500 absolute z-10 -top-6 p-1 left-4">
                      Public
                    </div>
                  )}

                  {event_type === "private_event" && (
                    <RiChatPrivateFill
                      onMouseOver={() => handleTooltip("private")}
                      onMouseLeave={() => setTooltip({ privateTool: false })}
                      className=" cursor-pointer text-[17px] text-green-400"
                    />
                  )}
                  {privateTool && (
                    <div className="text-[12px] text-slate-500 absolute z-10 -top-6 p-1 left-4">
                      Private
                    </div>
                  )}
                  {event_setting?.unit_of_measure === "steps" && (
                    <IoFootsteps
                      onMouseOver={() => handleTooltip("steps")}
                      onMouseLeave={() => setTooltip({ stepsTool: false })}
                      className=" cursor-pointer text-[17px] text-green-400"
                    />
                  )}
                  {stepsTool && (
                    <div className="text-[12px] text-slate-500 absolute z-10 -top-6 p-1 left-9">
                      Steps
                    </div>
                  )}

                  {event_setting?.unit_of_measure === "calories" && (
                    <img
                      src={calories}
                      onMouseOver={() => handleTooltip("calories")}
                      onMouseLeave={() => setTooltip({ caloriesTool: false })}
                      className="h-4 w-4"
                    />
                  )}
                  {caloriesTool && (
                    <div className="text-[12px] text-slate-500 absolute z-10 -top-6 p-1">
                      Calories
                    </div>
                  )}

                  {event_setting?.unit_of_measure === "distance" && (
                    <GiPathDistance
                      onMouseOver={() => handleTooltip("Distance")}
                      onMouseLeave={() => setTooltip({ distanceTool: false })}
                      className=" cursor-pointer text-[17px] text-green-400"
                    />
                  )}
                  {distanceTool && (
                    <div className="text-[12px] text-slate-500 absolute z-10 -top-6 p-1">
                      Distance
                    </div>
                  )}
                </div>
                <div className="w-12 text-center relative">
                  {plan_type === "free" && (
                    <h1 className="bg-[#ebfaf7] border border-solid border-[#8edbcb] text-[12px] font-semibold text-[#76d7c4] p-1">
                      <p>Free</p>
                    </h1>
                  )}
                  {plan_type === "paid" && (
                    <h1 className="bg-[#ebfaf7] border border-solid border-[#8edbcb] text-[12px] font-semibold text-[#76d7c4] p-1">
                      <p>Paid</p>
                    </h1>
                  )}
                </div>
              </div>
            </div>
            {/* {locationId ? null : */}
            <div className="py-5">
              <div className="flex items-center  border-t border-slate-100 border-solid">
                <div
                  className="w-[100%] border-r border-slate-100 font-semibold  border-solid text-sm  cursor-pointer items-center flex gap-2 justify-center hover:bg-slate-100 text-[#42C3AA] p-3"
                  onClick={() => {
                    handleNav("event", id, "", "", title);
                  }}
                >
                  <img src={editIcon} alt="icon" className="h-3" />
                  <p>Edit</p>
                </div>
                {count != "false" && (
                  <div
                    className={`w-[100%] flex gap-2 font-semibold hover:bg-slate-100 justify-center text-sm items-center cursor-pointer text-[#42C3AA] p-3`}
                    onClick={() => {
                      handleNav(
                        "team",
                        id,
                        event_setting?.enable_teams,
                        event_setting?.max_participants,
                        title
                      );
                    }}
                  >
                    <img src={teamIcon} alt="icon" className="h-3 " />
                    <p>Teams</p>
                  </div>
                )}
              </div>
            </div>
            {/* } */}
          </div>
        </div>
      </div>
    </div>
  );
}
