import { apiParticipantsDelete, apiRemoveParticipant } from "api/event";
import { titleize } from "helper";
import { getCookie } from "helper/cookie";
import moment from "moment";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { MdCall, MdEmail } from "react-icons/md";
import { RiFootprintFill } from "react-icons/ri";
import { FaTrophy } from "react-icons/fa";

import { toast, ToastContainer } from "react-toastify";
import { toastStyle } from "helper";
import "react-toastify/dist/ReactToastify.css";
import { Header } from "./header";
import { BsFillInfoCircleFill } from "react-icons/bs";


function ParticepantDets({
  modalClose,
  particepantDet,
  comId,
  eventId,
  fetchParticipant,
  loginType,
}) {
  const [confirmMdl, setConfirmMdl] = useState(false);

  const deleteMdl = () => {
    setConfirmMdl(true);
  };

  const closedeleteMdl = () => {
    setConfirmMdl(false);
  };

  const notify = (message, type) => {
    if (type == "error") {
      toast.error(message, toastStyle);
    }
    if (type == "success") {
      toast.success(message, toastStyle);
    }
  };

  let UrlPath = window.location.pathname.split('/');


  const confirmDelete = () => {
    let params = {
      participant_ids: particepantDet.id,
      status: "removed",
    };

    if (!loginType) {
      apiRemoveParticipant(getCookie("auth_token"), eventId, params)
        .then(({ message }) => {
          notify(message, "success");
          closedeleteMdl();
          modalClose();
          fetchParticipant();
        })
        .catch((err) => {
          notify(
            err[1]?.data?.response ? err[1]?.data?.response : err[1].message,
            "error"
          );
        });
    } else {
      apiParticipantsDelete(getCookie("auth_token"), comId, eventId, params)
        .then(({ message }) => {
          notify(message, "success");
          closedeleteMdl();
          modalClose();
          fetchParticipant();
        })
        .catch((err) => {
          notify(
            err[1]?.data?.response ? err[1]?.data?.response : err[1].message,
            "error"
          );
        });
    }
  };

  return (
    <div>
      <div
        style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
        className="w-full h-full z-[2] absolute top-0 left-0  flex justify-center items-center"
      >
        <div className="bg-white p-6 flex flex-col gap-4 flex-[0.3] ">
          <div className="flex justify-between">
            <div>
              <Header title={"Participant Details"} />
            </div>

            <div>
              <IoClose
                onClick={modalClose}
                className="h-10 w-8 cursor-pointer"
              />
            </div>
          </div>

          <div className="flex  gap-2 justify-between">
            <div className="flex gap-5">
              <img
                src={particepantDet.img_url}
                height="25px"
                width="70px"
                className="rounded"
              />
              <div className="flex flex-col">
                <span className="font-bold text-xl text-[#535c68]">
                  {particepantDet.name ? titleize(particepantDet.name) : "-"}
                </span>
                {UrlPath[1] !== "organizers" && <div className="flex gap-2 text-[#A020F0]">
                  {particepantDet?.phone && <MdCall className="mt-1" />}
                  <span className="text-[#7f8fa6]">
                    {particepantDet?.country_code &&
                      `+${particepantDet?.country_code}`}
                  </span>
                  <span className="text-[#7f8fa6] flex gap-2">
                    {particepantDet?.phone ? particepantDet?.phone.slice(0, 4) + "*".repeat(particepantDet.phone.length - 4) : "-"}
                    {particepantDet?.phone && <BsFillInfoCircleFill
                      data-te-toggle="tooltip"
                      title={`${particepantDet?.country_code} ${particepantDet?.phone}`}
                      data-te-placement="right"
                      data-te-ripple-color="light"
                      className="text-[#A020F0] text-md cursor-pointer" />}
                  </span>
                </div>}
                <div className="flex gap-2">
                  {particepantDet?.email && (
                    <MdEmail className="mt-1 text-[#A020F0]" />
                  )}
                  <span className="text-[#7f8fa6]">
                    {particepantDet?.email ? particepantDet.email : "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex gap-4">
                <FaTrophy className="mt-1 text-[#A020F0]" />
                <span className="text-[#7f8c8d]">
                  {particepantDet?.rank ? particepantDet.rank : "-"}
                </span>
              </div>
              <div className="flex gap-4">
                <RiFootprintFill className="mt-1 text-[#A020F0]" />

                <p className="text-[#7f8c8d]">{particepantDet.total_steps}</p>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex  gap-4">
                  <span className="text-[#535c68]">Join Date:-</span>
                  <span className="text-[#7f8c8d]">
                    {particepantDet?.join_date
                      ? moment(particepantDet.join_date).format("ll")
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-7 flex gap-4 justify-end">
            <div
              onClick={modalClose}
              className=" hover:bg-[#535c68] flex flex-[0.2] 
                         cursor-pointer text-[#535c68] h-10 bg-white  border border-solid
                         border-[#535c68]  justify-center hover:text-white p-2 "
            >
              <p>Cancel</p>
            </div>
            <div
              onClick={deleteMdl}
              className={` bg-[#eb4d4b] flex flex-[0.2]
                         cursor-pointer hover:text-[#eb4d4b] hover:bg-white hover:border hover:border-orange-500
                            justify-center text-white p-2 h-10 `}
            >
              <p>Delete</p>
            </div>
          </div>
        </div>
      </div>
      {confirmMdl && (
        <div>
          <div
            style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
            className="w-full h-full z-[3] absolute top-0 left-0  flex justify-center items-center "
          >
            <div className="bg-white p-6 flex flex-col gap-4 flex-[0.2]">
              <div className="flex justify-between">
                <div>
                  <Header title={"Delete Confirmation"} />
                </div>

                <div>
                  <IoClose
                    onClick={closedeleteMdl}
                    className="h-10 w-8 cursor-pointer"
                  />
                </div>
              </div>
              <div className="text-center">
                <h1 className="text-l">Are your Remove this Participant?</h1>
              </div>
              <div className="pt-7 flex gap-4 justify-end">
                <div
                  onClick={closedeleteMdl}
                  className=" hover:bg-[#535c68] flex flex-[0.2] 
                         cursor-pointer text-[#535c68] h-10 bg-white border border-solid
                          border-[#535c68]  justify-center hover:text-white p-2 rounded-lg"
                >
                  <p>Cancel</p>
                </div>
                <div
                  onClick={confirmDelete}
                  className={` bg-[#eb4d4b] flex flex-[0.2]
                         cursor-pointer hover:text-[#eb4d4b] hover:bg-white hover:border hover:border-orange-500
                            justify-center text-white p-2 h-10 rounded-lg`}
                >
                  <p>Confirm</p>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer autoClose={2200} style={{ fontWeight: "normal" }} />
        </div>
      )}
    </div>
  );
}

export default ParticepantDets;
