import React from 'react'
import '../assets/css/main.css'

function Skeleton({ edit }) {
    return (
        <div className='flex flex-col animate-pulse gap-1 m-3 border-[1px]'>
            <div className='bg-[#dcdde1] h-44 rounded-sm'> </div>
            {edit && <div className='flex gap-1 '>
                <div className='skeleton w-[50%] bg-[#dcdde1] h-[2.6em]'></div>
                <div className='skeleton w-[50%] bg-[#dcdde1] h-[2.6em]'></div>
            </div>}
        </div>
    )
}

export default Skeleton