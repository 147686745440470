import { apiOrganizerlist, apiUserroles, } from 'api/event'
import { getCookie, kickUser } from 'helper/cookie'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Admincards from './adminCards'
import { Header } from './header'
import Userform from './userForm'
// import Pagination from './pagination';
import Skeleton from "./skeleton";
import TableCardview from './tableCardview'
import Empty from './empty'

function AdminUsers({ handleSearchshow, searchName }) {
  const [userAdmin, setUseradmin] = useState([])
  const [visible, setVisible] = useState(false)
  const [show] = useState(true)
  const [organizerId, setOrganizerid] = useState('')
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate()
  const [dropdown, setDropdown] = useState({ dropDown: [] })
  const [userCreate, setUsercreate] = useState({ organizers: { name: '', email: '', role_group_id: '', status: '' } })
  // const [pagination, setPagination] = useState({ per_page: 16, page: 1 });
  const [cardTable, setCardtable] = useState(true);
  const [search, setSearch] = useState({ query: "" });

  const { query } = search;

  useEffect(() => {
    handleSearchshow(show);
    // handleChange(handleSearch);
    fetchOrganizer();
  }, [query]);

  useEffect(() => {
    handleSearch({ target: { value: searchName } })
    return () => {
      handleSearch({ target: { value: '' } })
    }
  }, [searchName])

  const fetchOrganizer = () => {
    apiOrganizerlist(getCookie("auth_token"), query)
      .then(({ data }) => {
        setLoading(false);
        setUseradmin(data);
      })
      .catch((err) => {
        if (err[0] === 403 && err[0] === 401) {
          navigate("/organizers/login");
          kickUser();
        }
      })
    // }).catch((err) => {
    //   if (err[0] === 403 || err[0] === 401) {
    //     navigate("/organizers/login")
    //   }
    // })
    apiUserroles().then(({ data }) => {
      setDropdown({ dropDown: data })
    }).catch((err) => {
      if (err[0] === 403 && err[0] === 401) {
        navigate("/organizers/login");
        kickUser();
      }
    })
  }

  const handleSearch = (e) => {
    const { value } = e.target
    setSearch((preState) => ({ ...preState, query: value }))
  }

  const handleUserchange = (e) => {
    const { name, value } = e.target;
    setUsercreate((prevState) => ({
      ...prevState, organizers: { ...prevState.organizers, [name]: value }
    }))
    setDropdown((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleUser = (value) => {
    setOrganizerid(value?.id)
    setVisible(true)
    setUsercreate({ ...userCreate, organizers: { ...userCreate.organizers, name: value?.name, email: value?.email, role_group_id: value?.role_group?.id, status: value?.status } })
  }

  const handleExit = () => {
    setVisible(false)
  }

  if (loading) {
    const row = [];
    for (let i = 0; i < 8; i++) {
      row.push(
        <div key={i} className="flex-[0_0_25%]">
          <Skeleton />
        </div>
      );
    }
    return <div className="flex flex-wrap py-10">{row}</div>;
  }
  const handleToogleright = () => {
    // const { value } = e.target
    setCardtable(false)
  }
  const handleToogleleft = () => {
    // const { value } = e.target
    setCardtable(true)
  }
  const userProps = {
    handleExit,
    handleUserchange,
    userCreate,
    dropdown,
    fetchOrganizer,
    title: organizerId ? "Update User" : "Create User",
    organizerId,
  }
  const headerProps = {
    handleToogleright,
    handleToogleleft,
    cardTable
  }
  return (
    <div>
      <div>
        <Header
          title={"Users"}
          toogle
          user
          organizerTable
          {...headerProps}
          handleUser={handleUser}
        />
      </div>
      {cardTable ? <div>
        <div className='flex flex-wrap gap-7'>
          {
            userAdmin.length != 0 ?
              userAdmin?.map((value, i) => {
                return <div key={i}>
                  <Admincards name={value?.name}
                    onclick={() => { handleUser(value) }}
                    email={value?.email}
                    productRole
                    status={value?.status}
                    adminName={value?.role_group.name}
                    edit={true} />
                </div>
              })
              :
              <Empty no_data/>
          }
        </div>
      </div> :
        <div>
          <TableCardview
            cardLoop={userAdmin}
            handleUser={handleUser}
          />
        </div>
      }
      {/* {total_entries != 0 && (
          <Pagination
            per_page={pagination?.per_page}
            total_entries={total_entries}
            handlePagination={handlePagination}
          />
        )} */}

      {visible && <Userform {...userProps} />}
    </div>
  )
}

export default AdminUsers