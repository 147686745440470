import { apiHost } from "config";
import { jsonHeaders } from "helper";
import { getCookie } from "helper/cookie";

export default function ({ uri, method = "GET", body, authorization = null }) {
  let auth_token = getCookie("auth_token")
  let otp_token = getCookie("otp_token")
  const token = auth_token ? auth_token : otp_token
  return fetch(apiHost + uri, {
    method,
    body,
    headers: authorization
      ? Object.assign(jsonHeaders, {
        Authorization: `API_KEY ${token}`,
      })
      : jsonHeaders,
  }).then(async (response) => {
    if (response.ok) {
      try {
        return response.json();
      } catch (err) {
        return true;
      }
    }
    const errorMessage = [response.status, await response.json()];
    throw errorMessage;
  });
}
