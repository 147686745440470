import { apiSubscriptionlist } from "api/event";
import { getCookie } from "helper/cookie";
import React, { useEffect, useState } from "react";
import Empty from "./empty";
import { Header } from "./header";
import ProductForm from "./productForm";
import SubscriptionCard from "./subscriptionCard";

function AdminSubscription({ handleSearchshow }) {
  const [show] = useState(false);
  const [visible, setVisible] = useState(false);
  const [subscriptionData, setSubscriptiondata] = useState({
    subscribe: {},
    subscription_id: "",
  });
  // const path = window.location.pathname.split('/')[3]
  const [editVisible, setEditVisible] = useState(false);

  const subscriptionId = getCookie("subscription_id");
  useEffect(() => {
    const roles = JSON.parse(window.localStorage.getItem("roles"));
    fetchSubscription();
    setEditVisible(roles?.["admins/v1/subscriptions"]?.includes("update"));
    handleSearchshow(show);
  }, []);
  const [Admin, setAdmin] = useState({
    name: "",
    count_limit: "",
    participant_limit: "",
    media: true,
    duration: "",
    price: "",
    enable_teams: true,
    allow_team_create: true,
  });
  const fetchSubscription = () => {
    apiSubscriptionlist(getCookie("auth_token"), subscriptionId).then(
      ({ data }) => {
        setSubscriptiondata({ subscribe: data });
      }
    );
  };

  const handleProduct = () => {
    setVisible(true);
    setSubscriptiondata((prevState) => ({
      ...prevState,
      subscription_id: subscribe?.id,
    }));
    setAdmin((prevState) => ({
      ...prevState,
      name: subscribe?.name,
      count_limit: subscribe?.count_limit,
      participant_limit: subscribe?.participant_limit,
      media: subscribe?.media,
      enable_teams: subscribe?.enable_teams,
      allow_team_create: subscribe?.allow_team_create,
    }));
  };

  const handleExit = () => {
    setVisible(false);
  };

  const handleSubscriptionchange = (e) => {
    const { name, value } = e.target;
    setAdmin((prevState) => ({ ...prevState, [name]: value }));
  };

  const { subscription_id } = subscriptionData;

  const productProps = {
    title: "Subscription Edit",
    handleExit,
    subscription_id,
    handleSubscriptionchange,
    Admin,
    fetchSubscription,
  };
  const { subscribe } = subscriptionData;
  return (
    <div>
      <div>
        <Header companyName={getCookie("companyName")} title={"Subscription"} />
      </div>
      <div>
        {subscribe ? (
          <div className="w-[18vw]">
            <SubscriptionCard
              cardNew
              onclick={handleProduct}
              name={subscribe?.name}
              price={subscribe?.price}
              count_limit={subscribe?.count_limit}
              participant_limit={subscribe?.participant_limit}
              // duration={subscribe?.subscription?.product_subscription?.duration}
              media={subscribe?.media}
              enable_teams={subscribe?.enable_teams}
              allow_team_create={subscribe?.allow_team_create}
              start_date={new Date()}
              end_date={new Date()}
              Date
              editVisible={editVisible}
            />
          </div>
        ) : (
          <Empty no_data />
        )}
      </div>
      {visible && <ProductForm {...productProps} />}
    </div>
  );
}

export default AdminSubscription;
